import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Navigation from "../../navigation/navigation";
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';
import Stepper from '../../Stepper';
import CustomSlider from '../../CustomSlider';

class PressionOeso extends CustomComponent {

    constructor(props) {
        super(props);
        this.state = {
            pressionoeso: {
                deltaPaw: "",
                deltaPes: "",
                pesInspiratoire: "",
                pesExpiratoire: "",
                pplat: "",
                pept: "",
                vt: "",
                mvSystResp: false,
                mvPoumon: false,
                mvParoi: false,
                facCorrecteur: "",
                PTPe: "",
                PTPi: "",
                PressionMotriceRs: "",
                ComplianceRs: "",
                ElastanceRs: "",
                PressionMotriceL: "",
                ComplianceL: "",
                ElastanceL: "",
                ComplianceCw: "",
                ElastanceCw: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let facCorrecteur = Number((Math.round(this.state.pressionoeso.deltaPes / this.state.pressionoeso.deltaPaw * 100) / 100).toFixed(2)) || 0;
        let PTPe = Number((Math.round((this.state.pressionoeso.pept - this.state.pressionoeso.pesExpiratoire) * 100) / 100).toFixed(1)) || 0;
        let PressionMotriceRs = Number((Math.round((this.state.pressionoeso.pplat - this.state.pressionoeso.pept) * 100) / 100).toFixed(1)) || 0;
        let ComplianceRs = Number((Math.round(this.state.pressionoeso.vt / (this.state.pressionoeso.pplat - this.state.pressionoeso.pept) * 100) / 100).toFixed(1)) || 0;
        let ElastanceRs = this.getDa3();
        let PressionMotriceL = Number((Math.round((this.state.pressionoeso.pplat - this.state.pressionoeso.pept - (this.state.pressionoeso.pesInspiratoire - this.state.pressionoeso.pesExpiratoire)) * 100) / 100).toFixed(1)) || 0;
        let ComplianceL = Number((Math.round(this.state.pressionoeso.vt / (this.state.pressionoeso.pplat - this.state.pressionoeso.pept - (this.state.pressionoeso.pesInspiratoire - this.state.pressionoeso.pesExpiratoire)) * 100) / 100).toFixed(1)) || 0;
        let PTPi = Number((Math.round(this.state.pressionoeso.pplat * ((1 / ComplianceL) / (1 / ComplianceRs)))).toFixed(1)) || 0;
        let ElastanceL = this.getDb3();
        let ComplianceCw = Number((Math.round(this.state.pressionoeso.vt / (this.state.pressionoeso.pesInspiratoire - this.state.pressionoeso.pesExpiratoire) * 100) / 100).toFixed(1)) || 0;
        let ElastanceCw = Number((Math.round((this.state.pressionoeso.pesInspiratoire - this.state.pressionoeso.pesExpiratoire) / (this.state.pressionoeso.vt / 1000) * 100) / 100).toFixed(1)) || 0;




        this.setState(prevState => ({
            pressionoeso: {
                ...prevState.pressionoeso,
                facCorrecteur,
                PTPe,
                PTPi,
                PressionMotriceRs,
                ComplianceRs,
                ElastanceRs,
                PressionMotriceL,
                ComplianceL,
                ElastanceL,
                ComplianceCw,
                ElastanceCw
            }
        }));
    }


    componentDidMount() {
        let pressionoeso = this.loadStorageValues('pressionoeso', this.state.pressionoeso);

        if (JSON.stringify(pressionoeso) !== JSON.stringify(this.state.pressionoeso))
            this.setState({
                pressionoeso: pressionoeso
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('pressionoeso', JSON.stringify(this.state.pressionoeso));
    }

    getDa3 = () => {
        return Number((Math.round((this.state.pressionoeso.pplat - this.state.pressionoeso.pept) / (this.state.pressionoeso.vt / 1000) * 100) / 100).toFixed(1)) || 0;
    }

    getDb3 = () => {
        return Number((Math.round(((this.state.pressionoeso.pplat -
            this.state.pressionoeso.pept -
            (this.state.pressionoeso.pesInspiratoire - this.state.pressionoeso.pesExpiratoire)) /
            (this.state.pressionoeso.vt / 1000)) * 100) / 100).toFixed(1)) || 0;
    }

    getC2 = () => {
        const Db3 = this.getDb3();
        const Da3 = this.getDa3();
        return Number((Math.round(this.state.pressionoeso.pplat * (Db3 / Da3) * 100) / 100).toFixed(1)) || 0;
    }

    reset() {
        this.setState({
            pressionoeso: {
                deltaPaw: "",
                deltaPes: "",
                pesInspiratoire: "",
                pesExpiratoire: "",
                pplat: "",
                pept: "",
                vt: "",
                mvSystResp: false,
                mvPoumon: false,
                mvParoi: false,
                facCorrecteur: "",
                PTPe: "",
                PTPi: "",
                PressionMotriceRs: "",
                ComplianceRs: "",
                ElastanceRs: "",
                PressionMotriceL: "",
                ComplianceL: "",
                ElastanceL: "",
                ComplianceCw: "",
                ElastanceCw: ""
            }
        },
            () => {
                localStorage.removeItem('pressionoeso');
            });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>
                            <Link to={{ pathname: "/tools" }} className="back" />
                            <span className="title">Pression œsophagienne</span>
                            <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                        </h1>
                    </div>
                    <fieldset>
                        <legend className="center">Position de la sonde</legend>
                        <Stepper
                            title="&Delta;Paw"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="deltaPaw"
                            handle={(e) => this.handleInputChange(e, null, 'pressionoeso')}
                            value={this.state.pressionoeso.deltaPaw}
                            min="1" max="100"
                        />
                        <Stepper
                            title="&Delta;Pes"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="deltaPes"
                            handle={(e) => this.handleInputChange(e, null, 'pressionoeso')}
                            value={this.state.pressionoeso.deltaPes}
                            min="1" max="100"
                        />
                        <div className="form-group">
                            <div className="specific2">
                                <div className="specific2-wrapper">
                                    <div className="specific2-inner">
                                        <div className="col">
                                            <p>&Delta;Paw / &Delta;Pes</p>
                                            <p className="val"><strong>{this.state.pressionoeso.facCorrecteur}</strong></p>
                                        </div>
                                        <div className="col">

                                            {
                                                (this.state.pressionoeso.facCorrecteur >= 0.8 && this.state.pressionoeso.facCorrecteur <= 1.2) ?
                                                    <p className="blue">Sonde en place</p>
                                                    :
                                                    <p>Repositionner la sonde</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="center">Paramètres mesurés</legend>
                        <Stepper
                            title="Pes inspiratoire"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pesInspiratoire"
                            handle={(e) => this.handleInputChange(e, null, 'pressionoeso')}
                            value={this.state.pressionoeso.pesInspiratoire}
                            min="1" max="100"
                        />
                        <Stepper
                            title="Pes expiratoire"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pesExpiratoire"
                            handle={(e) => this.handleInputChange(e, null, 'pressionoeso')}
                            value={this.state.pressionoeso.pesExpiratoire}
                            min="1" max="100"
                        />
                        <Stepper
                            title="Pplat"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pplat"
                            handle={(e) => this.handleInputChange(e, null, 'pressionoeso')}
                            value={this.state.pressionoeso.pplat}
                            min="1" max="50"
                        />
                        <Stepper
                            title="PEPt"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pept"
                            handle={(e) => this.handleInputChange(e, null, 'pressionoeso')}
                            value={this.state.pressionoeso.pept}
                            min="1" max="30"
                        />
                        <CustomSlider
                            title="Vt"
                            unit={<div>mL</div>}
                            name="vt"
                            handle={(e, val) => this.handleInputChange(e, val, 'pressionoeso')}
                            value={this.state.pressionoeso.vt}
                            min="1" max="600"
                        />
                    </fieldset>
                    <fieldset>
                        <div className="form-group">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>Pression transpulmonaire</p>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p>Expiratoire <br />(directe)</p>
                                            <p className="result">
                                                <strong style={(this.state.pressionoeso.PTPe <= 0) ? { color: '#FF7815' } : {}}>
                                                    {this.state.pressionoeso.PTPe > 0 && "+"}{this.state.pressionoeso.PTPe}
                                                </strong>
                                            </p>
                                            <p className="blue" style={(this.state.pressionoeso.PTPe <= 0) ? { color: '#FF7815' } : {}}>
                                                <small>cmH<sub>2</sub>O</small>
                                            </p>
                                        </div>
                                        <div className="col">
                                            <p>Inspiratoire <br />(el-derived)</p>
                                            <p className="result">
                                                <strong style={(this.state.pressionoeso.PTPi >= 26) ? { color: '#FF7815' } : {}}>
                                                    {this.state.pressionoeso.PTPi}
                                                </strong>
                                            </p>
                                            <p className="blue" style={(this.state.pressionoeso.PTPi >= 26) ? { color: '#FF7815' } : {}}>
                                                <small>cmH<sub>2</sub>O</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="form-group">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>Mécanique ventilatoire</p>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p>C<sub>rs</sub></p>
                                            <p className="result"><strong>{this.state.pressionoeso.ComplianceRs}</strong></p>
                                            <p className="blue"><small>mL/cmH<sub>2</sub>O</small></p>
                                        </div>
                                        <div className="col">
                                            <p>C<sub>L</sub></p>
                                            <p className="result"><strong>{this.state.pressionoeso.ComplianceL}</strong></p>
                                            <p className="blue"><small>mL/cmH<sub>2</sub>O</small></p>
                                        </div>
                                        <div className="col">
                                            <p>C<sub>cw</sub></p>
                                            <p className="result"><strong>{this.state.pressionoeso.ComplianceCw}</strong></p>
                                            <p className="blue"><small>mL/cmH<sub>2</sub>O</small></p>
                                        </div>
                                    </div>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p>DP<sub>rs</sub></p>
                                            <p className="result"><strong>{this.state.pressionoeso.PressionMotriceRs}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>O</small></p>
                                        </div>
                                        <div className="col">
                                            <p>DP<sub>L</sub></p>
                                            <p className="result"><strong>{this.state.pressionoeso.PressionMotriceL}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>O</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>


                    <div className="wrap-validation">
                        <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                        <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                    </div>
                </div>
                <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                    <div id="info-bloc-inner">
                        <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                        <h2>Définitions</h2>
                        <Accordion panels={t("panels", { returnObjects: true }).PressionOeso} />
                    </div>
                </div>
                <Navigation />
            </div>
        );
    }
}

export default withTranslation()(PressionOeso);

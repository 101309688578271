import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import Navigation from "../../navigation/navigation";
import CustomComponent from '../../../customComponent';
import CustomSlider from '../../CustomSlider';
import Accordion from '../../Accordion';

class VolumeCourant extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            volcourant: {
                sexe: 'homme',
                taille: "",
                poids: "",
                ppt: "",
                vt4: "",
                vt6: "",
                vt8: ""
            },
            infos: false
        };
    }

    getPpt = taille => {
        if (this.state.volcourant.sexe === "homme") {
            return Math.round(0.91 * (taille - 152.4) + 50);
        } else if (this.state.volcourant.sexe === "femme") {
            return Math.round(0.91 * (taille - 152.4) + 45.5);
        }
    }

    runCalculs() {
        let ppt = this.getPpt(this.state.volcourant.taille);
        let vt4 = this.getPpt(this.state.volcourant.taille) * 4;
        let vt6 = this.getPpt(this.state.volcourant.taille) * 6;
        let vt8 = this.getPpt(this.state.volcourant.taille) * 8;
        this.setState(prevState => ({
            volcourant: {
                ...prevState.volcourant,
                ppt,
                vt4,
                vt6,
                vt8
            }
        }));
    }

    componentDidMount() {
        let volcourant = this.loadStorageValues('volcourant', this.state.volcourant);

        if (JSON.stringify(volcourant) !== JSON.stringify(this.state.volcourant))
            this.setState({
                volcourant: volcourant
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('volcourant', JSON.stringify(this.state.volcourant));
    }

    reset() {
        this.setState({
            volcourant: {
                sexe: 'homme',
                taille: "",
                poids: "",
                ppt: "",
                vt4: "",
                vt6: "",
                vt8: ""
            },
        },
            () => {
                localStorage.removeItem('volcourant');
            });
    }

    render() {
        const { t } = this.props;

        return (<div>
            <div className="content" id="volcourant">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/tools" }} className="back" />
                        <span className="title">Volume courant</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>
                <fieldset>
                    <legend className="center">Information du patient</legend>
                    <div className="form-group form-check-inline">
                        <legend>Sexe</legend>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="sexe"
                                id="gridRadios1"
                                value="homme"
                                onChange={(e) => this.handleInputChange(e, null, 'volcourant')}
                                checked={this.state.volcourant.sexe === 'homme'}
                            />
                            <label className="form-check-label" htmlFor="gridRadios1">
                                Homme
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="sexe"
                                id="gridRadios2"
                                value="femme"
                                onChange={(e) => this.handleInputChange(e, null, 'volcourant')}
                                checked={this.state.volcourant.sexe === 'femme'}
                            />
                            <label className="form-check-label" htmlFor="gridRadios2">
                                Femme
                            </label>
                        </div>
                    </div>
                    <CustomSlider
                        title="Taille"
                        unit={<div>cm</div>}
                        name="taille"
                        handle={(e, val) => this.handleInputChange(e, val, 'volcourant')}
                        value={this.state.volcourant.taille}
                        min="140" max="250"
                    />
                    <CustomSlider
                        title="Poids"
                        unit={<div>kg</div>}
                        name="poids"
                        handle={(e, val) => this.handleInputChange(e, val, 'volcourant')}
                        value={this.state.volcourant.poids}
                        min="40" max="250"
                    />
                </fieldset>
                <fieldset className="italic-style">
                    <div className="form-group type-slider">
                        <div className="specific2">
                            <div className="specific2-wrapper">
                                <div className="specific2-inner">
                                    <div className="col">
                                        <p><small>Poids prédit par la taille</small></p>
                                        <p className="result"><strong>{this.state.volcourant.ppt}</strong></p>
                                        <p className="blue"><small>kg</small></p>
                                    </div>
                                    <div className="col">
                                        <p><small>Volume courant 6 mL/kg (PPT)</small></p>
                                        <p className="result"><strong>{this.state.volcourant.vt6}</strong></p>
                                        <p className="blue"><small>mL</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>
            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={t("panels", { returnObjects: true }).VolumeCourant} />
                </div>
            </div>
            <Navigation />
        </div>);
    }
}

export default withTranslation()(withRouter(VolumeCourant));

import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Navigation from "../navigation/navigation";
import picto_default from "../../css/img/svg/picto-m1.svg";
import picto_diagnostic from "../../css/img/svg/diagnostic.svg";
import picto_volume from "../../css/img/svg/volume-courant.svg";
import picto_meca from "../../css/img/svg/mecanique-ventilatoire.svg";
import picto_niveau from "../../css/img/svg/niveau-pep.svg";
import picto_curatisation from "../../css/img/svg/curatisation.svg";
import picto_decubitus from "../../css/img/svg/decubitus-ventral.svg";
import picto_evalu from "../../css/img/svg/evaluation-procedure.svg";
import picto_pression from "../../css/img/svg/pression-oesophagienne.svg";
import picto_espace from "../../css/img/svg/espace-mort.svg";
import picto_shumt from "../../css/img/svg/shumt-intrpulmonaire.svg";

class Tools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            more: false,
            userType: 0,
            tools: [
                {
                    id: 1,
                    nom: "Diagnostic",
                    path: "/diagnostic",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 2,
                    nom: "Volume courant",
                    path: "/volcourant",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 3,
                    nom: "Mécanique ventilatoire",
                    path: "/mecaventil",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 4,
                    nom: "Niveau de PEP",
                    path: "/pep",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 5,
                    nom: "Curarisation",
                    path: "/curarisation",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 6,
                    nom: "Décubitus ventral",
                    path: "/decubitus",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                // {
                //     id: 7,
                //     nom: "Manoeuvre de recrutement",
                //     picto: picto_evalu,
                //     path: "/shunt",
                //     userType: 1,
                //     visible: true,
                //     class: "mosaic-item",
                // },
                {
                    id: 8,
                    nom: "Pression oesophagienne",
                    path: "/pressionoeso",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 9,
                    nom: "Espace mort",
                    path: "/espmort",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 10,
                    nom: "Shunt intrapulmonaire",
                    path: "/shunt",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                }
            ]
        }
    }

    static getToolsPictoById(id) {
        let picto = picto_default;
        switch (id) {
            case 1:
                picto = picto_diagnostic;
                break;
            case 2:
                picto = picto_volume;
                break;
            case 3:
                picto = picto_meca;
                break;
            case 4:
                picto = picto_niveau;
                break;
            case 5:
                picto = picto_curatisation;
                break;
            case 6:
                picto = picto_decubitus;
                break;
            case 7:
                picto = picto_evalu;
                break;
            case 8:
                picto = picto_pression;
                break;
            case 9:
                picto = picto_espace;
                break;
            case 10:
                picto = picto_shumt;
                break;
            default:
                picto = picto_default;
        }
        return picto;
    }

    updateBadge(tools) {
        tools.forEach(tool => {
            switch (tool.id) {
                case 1:
                    tool.class = (localStorage.getItem("diagnostic")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 2:
                    tool.class = (localStorage.getItem("volcourant")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 3:
                    tool.class = (localStorage.getItem("mecaventil")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 4:
                    tool.class = (localStorage.getItem("courbes") ||
                        localStorage.getItem("fermeture") ||
                        localStorage.getItem("niveauPep") ||
                        localStorage.getItem("peepChallenge") ||
                        localStorage.getItem("riRatio") ||
                        localStorage.getItem("pepIntrinseque") ||
                        localStorage.getItem("stressIndex")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 5:
                    tool.class = (localStorage.getItem("curarisation")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 6:
                    tool.class = (localStorage.getItem("decubitus")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                // case 7:
                //     tool.class = (localStorage.getItem("shunt")) ? "mosaic-item badge" : "mosaic-item";
                //     break;
                case 8:
                    tool.class = (localStorage.getItem("pressionoeso")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 9:
                    tool.class = (localStorage.getItem("capnometrie") ||
                        localStorage.getItem("capnographie") ||
                        localStorage.getItem("ventilatory")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                case 10:
                    tool.class = (localStorage.getItem("shuntSelonRatio") ||
                        localStorage.getItem("shuntSelonO2")) ? "mosaic-item badge" : "mosaic-item";
                    break;
                default:
                    tool.class = "mosaic-item";
            }
        });
        return tools
    }

    componentDidMount() {
        let userType = this.state.userType;
        let tools = this.loadBadge();
        if (localStorage.getItem('userType') && localStorage.getItem('userType') !== "")
            userType = Number(localStorage.getItem('userType'));
        else
            localStorage.setItem('userType', userType)
        this.setState({
            tools: tools,
            userType: userType || 0
        });
    }

    loadBadge() {
        let tools = this.state.tools;
        if (localStorage.getItem('tools') && localStorage.getItem('tools') !== "") {
            try {
                let t = JSON.parse(localStorage.getItem('tools'));
                this.updateBadge(t);
                if (t !== null) {
                    tools = t;
                }
            } catch (error) {
                console.log("tools is undefined");
            }
        }
        else {
            localStorage.setItem('tools', JSON.stringify(this.state.tools));
        }
        return tools;
    }

    reset() {
        const userType = localStorage.getItem('userType');
        const tools = localStorage.getItem('tools');
        const pwa_popup = localStorage.getItem('pwa_popup_display');
        const disc_popup = localStorage.getItem('disclamer_popup');
        const survey_popup = localStorage.getItem('survey_popup');
        localStorage.clear();
        localStorage.setItem('userType', userType);
        localStorage.setItem('tools', tools);
        localStorage.setItem('pwa_popup_display', pwa_popup);
        localStorage.setItem('disclamer_popup', disc_popup);
        localStorage.setItem('survey_popup', survey_popup);
        this.setState({
            tools: this.loadBadge(),
            empty: false,
            more: false
        });
    }

    render() {
        const { t } = this.props;
        const list = this.state.tools.map((tool, index) => {
            if (this.state.userType >= tool.userType && tool.visible)
                return (
                    <div className={tool.class} key={index}>
                        <div className="mosaic-item-inner">
                            <img src={Tools.getToolsPictoById(tool.id)} alt="" width="92" height="92" />
                            <p>{t(tool.nom)}</p>
                            <Link to={{ pathname: tool.path, state: this.state }}></Link>
                        </div>
                    </div>
                )
            else
                return ""
        });

        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>{t('Outils de Calcul')}<span id="more" onClick={() => this.setState({ more: (this.state.more) ? false : true })}></span></h1>
                    </div>
                    <div className="inner-content">
                        <div className="mosaic">
                            <div className="mosaic-inner">
                                {list}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="info-bloc" className={(this.state.more) ? "active" : ""} >
                    <div id="info-bloc-inner">
                        <div onClick={() => this.setState({ more: (this.state.more) ? false : true })} id="info-bloc-close"></div>
                        <Link className="btn secondary" to={{ pathname: "/profile" }}>{t('Ajouter / Supprimer des outils')}</Link>
                        <button className="btn third reload" onClick={() => this.reset()}>{t('Effacer toutes les données')}</button>
                    </div>
                </div>
                <Navigation />
            </div>
        )
    }
}

export default hoistStatics(withTranslation()(withRouter(Tools)), Tools);

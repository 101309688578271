import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import Switch from '../../../components/switch';
import Stepper from '../../Stepper';
import CustomSlider from '../../CustomSlider';
import CustomComponent from '../../../customComponent';

class PeepChallenge extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            peepChallenge: {
                paramPEP5: false,
                paramPEP10: false,
                paramPEP15: false,
                vt: "",
                fio2: "",
                pao2PEP5: "",
                pplatPEP5: "",
                peptPEP5: "",
                pao2PEP10: "",
                pplatPEP10: "",
                peptPEP10: "",
                pao2PEP15: "",
                pplatPEP15: "",
                peptPEP15: "",
                dpPEP5: "",
                dpPEP10: "",
                dpPEP15: "",
                compliancePEP5: "",
                compliancePEP10: "",
                compliancePEP15: "",
                quotientPEP5: "",
                quotientPEP10: "",
                quotientPEP15: "",
                // gainDP: "",
                // gainCompliance: "",
                // gainQuotient: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let dpPEP5 = this.getDPPEP5();
        let dpPEP10 = this.getDPPEP10();
        let dpPEP15 = this.getDPPEP15();
        let compliancePEP5 = this.getCompliancePEP5();
        let compliancePEP10 = this.getCompliancePEP10();
        let compliancePEP15 = this.getCompliancePEP15();
        let quotientPEP5 = this.getQuotientPEP5();
        let quotientPEP10 = this.getQuotientPEP10();
        let quotientPEP15 = this.getQuotientPEP15();
        // let gainDP = this.getGainDP();
        // let gainCompliance = this.getGainCompliance();
        // let gainQuotient = this.getGainQuotient();

        this.setState(prevState => ({
            peepChallenge: {
                ...prevState.peepChallenge,
                dpPEP5,
                dpPEP10,
                dpPEP15,
                compliancePEP5,
                compliancePEP10,
                compliancePEP15,
                quotientPEP5,
                quotientPEP10,
                quotientPEP15,
                // gainDP,
                // gainCompliance,
                // gainQuotient
            }
        }));
    }

    componentDidMount() {
        let peepChallenge = this.loadStorageValues('peepChallenge', this.state.peepChallenge);

        if (JSON.stringify(peepChallenge) !== JSON.stringify(this.state.peepChallenge))
            this.setState({
                peepChallenge: peepChallenge
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('peepChallenge', JSON.stringify(this.state.peepChallenge));
    }

    reset() {
        this.setState({
            peepChallenge: {
                paramPEP5: false,
                paramPEP10: false,
                paramPEP15: false,
                vt: "",
                fio2: "",
                pao2PEP5: "",
                pplatPEP5: "",
                peptPEP5: "",
                pao2PEP10: "",
                pplatPEP10: "",
                peptPEP10: "",
                pao2PEP15: "",
                pplatPEP15: "",
                peptPEP15: "",
                dpPEP5: "",
                dpPEP10: "",
                dpPEP15: "",
                compliancePEP5: "",
                compliancePEP10: "",
                compliancePEP15: "",
                quotientPEP5: "",
                quotientPEP10: "",
                quotientPEP15: "",
                // gainDP: "",
                // gainCompliance: "",
                // gainQuotient: ""
            }
        },
            () => {
                localStorage.removeItem('peepChallenge');
            });
    }

    getDPPEP5 = () => {
        return Math.round(this.state.peepChallenge.pplatPEP5 - this.state.peepChallenge.peptPEP5);
    }

    getDPPEP10 = () => {
        return Math.round(this.state.peepChallenge.pplatPEP10 - this.state.peepChallenge.peptPEP10);
    }

    getDPPEP15 = () => {
        return Math.round(this.state.peepChallenge.pplatPEP15 - this.state.peepChallenge.peptPEP15);
    }

    getCompliancePEP5 = () => {
        return Math.round(this.state.peepChallenge.vt / (this.state.peepChallenge.pplatPEP5 - this.state.peepChallenge.peptPEP5));
    }

    getCompliancePEP10 = () => {
        return Math.round(this.state.peepChallenge.vt / (this.state.peepChallenge.pplatPEP10 - this.state.peepChallenge.peptPEP10));
    }

    getCompliancePEP15 = () => {
        return Math.round(this.state.peepChallenge.vt / (this.state.peepChallenge.pplatPEP15 - this.state.peepChallenge.peptPEP15));
    }

    getQuotientPEP5 = () => {
        return Math.round(this.state.peepChallenge.pao2PEP5 / (this.state.peepChallenge.fio2 / 100));
    }

    getQuotientPEP10 = () => {
        return Math.round(this.state.peepChallenge.pao2PEP10 / (this.state.peepChallenge.fio2 / 100));
    }

    getQuotientPEP15 = () => {
        return Math.round(this.state.peepChallenge.pao2PEP15 / (this.state.peepChallenge.fio2 / 100));
    }

    render() {

        const panels = [
            {
                label: 'Principes',
                content: <div>
                    <p>En pratique courante, le volume pulmonaire recruté lors d’une augmentation de la PEP est rarement mesuré. En effet, la réalisation d’une courbe pression-volume à différents niveaux de PEP est fastidieuse. La mesure des modifications de la mécanique ventilatoire ou des échanges gazeux induites par le changement de PEP peut servir de substitut au volume pulmonaire recruté. </p>
                </div>
            },
            {
                label: 'Méthodes',
                content: <div>
                    <p>Une épreuve de PEP (<i>PEEP challenge</i>) peut être conduite de deux façons : en augmentant progressivement le niveau de PEP (<i>incremental PEEP titration</i>) ou en diminuant progressivement le niveau de PEP après la réalisation d’une manœuvre de recrutement alvéolaire (<i>decremental PEEP titration</i>). La fraction inspirée en oxygène (F<sub>i</sub>O<i>2</i>) et le volume courant doivent être maintenus constants durant l’épreuve.</p>
                    <ul>
                        <li>Pour l’<i>incremental PEEP titration</i>, l’augmentation du niveau de PEP se fait progressivement par palier de 2-5 cmH<sub>2</sub>O (de 5 à 20 cmH<sub>2</sub>O par exemple). Un délai minimum de 10 minutes doit être respecté entre chaque palier pour permettre à la mécanique ventilatoire de s’équilibrer. La PEP optimale correspond au niveau de pression permettant la meilleure C<sub>rs</sub><sup>1,2</sup>.</li>
				
                        <li>Pour la <i>decremental PEEP titration</i>, certains auteurs proposent de commencer par réaliser une manœuvre de recrutement alvéolaire pour permettre la ré-aération maximale du parenchyme pulmonaire. Puis, à partir de 20-25 cmH<sub>2</sub>O, la PEP est progressivement diminuée par paliers de 2 cmH<sub>2</sub>O toutes les 2 minutes environ jusqu’à constater une chute de la C<sub>rs</sub>. Le niveau de PEP ou se produit la dégradation respiratoire correspond au début du dérecrutement alvéolaire c’est pourquoi certains préconisent de rajouter 2 cmH<sub>2</sub>O à cette pression<sup>1</sup>.</li><br /><br />
                    </ul>
                </div>
            },
            {
                label: 'Intérêt au cours du SDRA',
                content: <div>
                    <p>De nombreux essais ont utilisé les modifications de la C<sub>rs</sub> au cours du <i>PEEP challenge</i> pour déterminer la PEP à appliquer<sup>3–9</sup>. En 2013, Pintado et al ont comparé cette stratégie à la sélection de PEP basée sur la table F<sub>i</sub>O<sub>2</sub>/PEP de l’ARDS network. Les patients du groupe <i>PEEP challenge</i> avaient une réduction significative de la durée de ventilation et une tendance à une diminution de la mortalité<sup>10,11</sup>.</p>
                   <p>Plutôt que d’utiliser les variations de la C<sub>rs</sub>, certains auteurs ont utilisé les variations de la P<sub>a</sub>O<sub>2</sub><sup>9</sup>, de la pression motrice<sup>12</sup> ou de l’espace mort<sup>7,13–15</sup> pour déterminer la PEP optimale au cours du <i>PEEP challenge</i>. A contrario, dans une étude associant les variations du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub>, de la P<sub>a</sub>CO<sub>2</sub> et de la C<sub>rs</sub>, Gattinoni et al. ont montré une faible performance du <i>PEEP challenge</i> pour prédire la recrutabilité des patients en SDRA (définie par le scanner thoracique)<sup>16,17</sup>.</p>
                    <p>En conclusion, le <i>PEEP challenge</i> est une méthode facilement utilisable au lit du malade pour aider le clinicien à choisir le niveau de PEP optimale, qui correspond généralement au meilleur compromis entre mécanique respiratoire, hématose et tolérance hémodynamique<sup>16</sup>. Cependant, le délai permettant d’atteindre un nouvel équilibre de l’oxygénation, l’hémodynamique et la mécanique ventilatoire est toujours sujet à débat<sup>18-20</sup>. De plus, les modifications de la C<sub>rs</sub>ne sont pas toujours bien corrélées au volume alvéolaire recruté<sup>16</sup></p><br />
                </div>
            },
            {
                label: 'Références',
                content: <div>
                    <p>
                        1. 	Gattinoni L, Collino F, Maiolo G, et al. Positive end-expiratory pressure: how to set it at the individual level. <i>Ann Transl Med</i> 2017;5(14):288. <br /><br />
						2. 	Chiumello D, Coppola S, Froio S, et al. Time to reach a new steady state after changes of positive end expiratory pressure. <i>Intensive Care Med</i> 2013;39(8):1377–1385. <br /><br />
						3. 	Rodriguez PO, Bonelli I, Setten M, et al. Transpulmonary pressure and gas exchange during decremental PEEP titration in pulmonary ARDS patients. <i>Respir Care</i> 2013;58(5):754–763. <br /><br />
						4. 	Gernoth C, Wagner G, Pelosi P, Luecke T. Respiratory and haemodynamic changes during decremental open lung positive end-expiratory pressure titration in patients with acute respiratory distress syndrome. <i>Crit Care</i> 2009;13(2):R59. <br /><br />
						5. 	Toth I, Leiner T, Mikor A, Szakmany T, Bogar L, Molnar Z. Hemodynamic and respiratory changes during lung recruitment and descending optimal positive end-expiratory pressure titration in patients with acute respiratory distress syndrome. <i>Crit Care Med</i> 2007;35(3):787–793.<br /><br /> 
						6. 	Spadaro S, Grasso S, Karbing DS, et al. Physiological effects of two driving pressure-based methods to set positive end-expiratory pressure during one lung ventilation. <i>J Clin Monit Comput</i> 2020. <br /><br />
						7. 	Fengmei G, Jin C, Songqiao L, Congshan Y, Yi Y. Dead space fraction changes during PEEP titration following lung recruitment in patients with ARDS. <i>Respir Care</i> 2012;57(10):1578–1585.<br /><br /> 
						8. 	Badet M, Bayle F, Richard J-C, Guérin C. Comparison of optimal positive end-expiratory pressure and recruitment maneuvers during lung-protective mechanical ventilation in patients with acute lung injury/acute respiratory distress syndrome. <i>Respir Care</i> 2009;54(7):847–854. <br /><br />
						9. 	Huang Y, Yang Y, Chen Q, et al. Pulmonary acute respiratory distress syndrome: positive end-expiratory pressure titration needs stress index. <i>J Surg Res</i> 2013;185(1):347–352. <br /><br />
						10. 	Pintado M-C, Pablo R de, Trascasa M, et al. Individualized PEEP setting in subjects with ARDS: a randomized controlled pilot study. <i>Respir Care</i> 2013;58(9):1416–1423. <br /><br />
						11. 	Pintado MC, Pablo R de. Individualized positive end-expiratory pressure application in patients with acute respiratory distress syndrome. <i>Med Intensiva</i> 2014;38(8):498–501. <br /><br />
						12. 	Sahetya SK, Hager DN, Stephens RS, Needham DM, Brower RG. PEEP Titration to Minimize Driving Pressure in Subjects With ARDS: A Prospective Physiological Study. <i>Respir Care</i> 2020;65(5):583–589.<br /><br /> 
						13. 	Murray IP, Modell JH, Gallagher TJ, Banner MJ. Titration of PEEP by the arterial minus end-tidal carbon dioxide gradient. <i>Chest</i> 1984;85(1):100–104. <br /><br />
						14. 	Blanch L, Fernández R, Benito S, Mancebo J, Net A. Effect of PEEP on the arterial minus end-tidal carbon dioxide gradient. <i>Chest</i> 1987;92(3):451–454. <br /><br />
						15. 	Jardin F, Genevray B, Pazin M, Margairaz A. Inability to titrate PEEP in patients with acute respiratory failure using end-tidal carbon dioxide measurements. <i>Anesthesiology</i> 1985;62(4):530–533.<br /><br /> 
						16. 	Caironi P, Gattinoni L. How to monitor lung recruitment in patients with acute lung injury. <i>Curr Opin Crit Care</i> 2007;13(3):338–343.<br /><br /> 
						17. 	Gattinoni L, Caironi P, Cressoni M, et al. Lung recruitment in patients with the acute respiratory distress syndrome. <i>N Engl J Med</i> 2006;354(17):1775–1786. <br /><br />
						18. 	Chiumello D, Coppola S, Froio S, et al. Time to reach a new steady state after changes of positive end expiratory pressure. <i>Intensive Care Med</i> 2013;39(8):1377–1385. <br /><br />
						19. 	Brower RG. Time to reach a new equilibrium after changes in PEEP in acute respiratory distress syndrome patients. <i>Intensive Care Med</i> 2013;39(11):2053–2055. <br /><br />
						20. 	Garnero A, Tuxen D, Corno G, Durand-Gasselin J, Hodgson C, Arnal J-M. Dynamics of end expiratory lung volume after changing positive end-expiratory pressure in acute respiratory distress syndrome patients. <i>Crit Care</i> 2015;19:340. <br /><br />
						21. 	Chen L, Del Sorbo L, Grieco DL, et al. Potential for Lung Recruitment Estimated by the Recruitment-to-Inflation Ratio in Acute Respiratory Distress Syndrome. A Clinical Trial. <i>Am J Respir Crit Care Med</i> 2020;201(2):178–187.<br /><br /><br />
                    </p>
                </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/potentiel" }} className="back" />
                        <span className="title">PEEP challenge</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres affichés</legend>
                    <CustomSlider
                        title="Vt"
                        unit={<div>mL</div>}
                        name="vt"
                        handle={(e, val) => this.handleInputChange(e, val, 'peepChallenge')}
                        value={this.state.peepChallenge.vt}
                        min="100" max="600"
                        step="10"
                    />
                    <CustomSlider
                        title="FiO2"
                        unit={<div>%</div>}
                        name="fio2"
                        handle={(e, val) => this.handleInputChange(e, val, 'peepChallenge')}
                        value={this.state.peepChallenge.fio2}
                        min="21" max="100"
                    />
                    <legend className="center">Paramètres mesurés</legend>
                    <div className="form-group">
                        <label>Paramètres à PEP 5 cmH2O</label>
                        <Switch
                            name="paramPEP5"
                            className="switch3"
                            checked={this.state.peepChallenge.paramPEP5}
                            onChange={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                        />
                    </div>

                    {this.state.peepChallenge.paramPEP5 && <div>
                        <CustomSlider
                        title="PaO2"
                        unit={<div>mmHg</div>}
                        name="pao2PEP5"
                        handle={(e, val) => this.handleInputChange(e, val, 'peepChallenge')}
                        value={this.state.peepChallenge.pao2PEP5}
                        min="1" max="300"
                        />
                        <Stepper
                            title="Pplat"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pplatPEP5"
                            handle={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                            value={this.state.peepChallenge.pplatPEP5}
                            min="1" max="50"
                        />
                        <Stepper
                            title="PEPt"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="peptPEP5"
                            handle={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                            value={this.state.peepChallenge.peptPEP5}
                            min="1" max="30"
                        />
                    </div>
                    }

                    <div className="form-group">
                        <label>Paramètres à PEP 10 cmH2O</label>
                        <Switch
                            name="paramPEP10"
                            className="switch3"
                            checked={this.state.peepChallenge.paramPEP10}
                            onChange={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                        />
                    </div>

                    {this.state.peepChallenge.paramPEP10 && <div>
                        <CustomSlider
                        title="PaO2"
                        unit={<div>mmHg</div>}
                        name="pao2PEP10"
                        handle={(e, val) => this.handleInputChange(e, val, 'peepChallenge')}
                        value={this.state.peepChallenge.pao2PEP10}
                        min="1" max="300"
                        />
                        <Stepper
                            title="Pplat"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pplatPEP10"
                            handle={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                            value={this.state.peepChallenge.pplatPEP10}
                            min="1" max="50"
                        />
                        <Stepper
                            title="PEPt"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="peptPEP10"
                            handle={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                            value={this.state.peepChallenge.peptPEP10}
                            min="1" max="30"
                        />
                    </div>
                    }

                    <div className="form-group">
                        <label>Paramètres à PEP 15 cmH2O</label>
                        <Switch
                            name="paramPEP15"
                            className="switch3"
                            checked={this.state.peepChallenge.paramPEP15}
                            onChange={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                        />
                    </div>

                    {this.state.peepChallenge.paramPEP15 && <div>
                        <CustomSlider
                        title="PaO2"
                        unit={<div>mmHg</div>}
                        name="pao2PEP15"
                        handle={(e, val) => this.handleInputChange(e, val, 'peepChallenge')}
                        value={this.state.peepChallenge.pao2PEP15}
                        min="1" max="300"
                        />
                        <Stepper
                            title="Pplat"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pplatPEP15"
                            handle={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                            value={this.state.peepChallenge.pplatPEP15}
                            min="1" max="50"
                        />
                        <Stepper
                            title="PEPt"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="peptPEP15"
                            handle={(e) => this.handleInputChange(e, null, 'peepChallenge')}
                            value={this.state.peepChallenge.peptPEP15}
                            min="1" max="30"
                        />
                    </div>
                    }

                </fieldset>

                <fieldset className="italic-style">
                    <table className="specific-table">
                        <tr>
                            <th></th>
                            <th className="table-label">PEP5</th>
                            <th className="table-label">PEP10</th>
                            <th className="table-label">PEP15</th>
                        </tr>
                        <tr>
                            <td className="table-label">C<sub>rs</sub></td>
                            <td>{!isNaN(this.getCompliancePEP5()) && <div>
                                    {this.getCompliancePEP5() === Math.max(this.getCompliancePEP5(), this.getCompliancePEP10(), this.getCompliancePEP15()) ? <strong>{this.getCompliancePEP5()}</strong> : <span>{this.getCompliancePEP5()}</span>}
                                </div>}
                            </td>
                            <td>{!isNaN(this.getCompliancePEP10()) && <div>
                                    {this.getCompliancePEP10() === Math.max(this.getCompliancePEP5(), this.getCompliancePEP10(), this.getCompliancePEP15()) ? <strong>{this.getCompliancePEP10()}</strong> : <span>{this.getCompliancePEP10()}</span>}
                                </div>}
                            </td>
                            <td>{!isNaN(this.getCompliancePEP15()) && <div>
                                    {this.getCompliancePEP15() === Math.max(this.getCompliancePEP5(), this.getCompliancePEP10(), this.getCompliancePEP15()) ? <strong>{this.getCompliancePEP15()}</strong> : <span>{this.getCompliancePEP15()}</span>}
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td className="table-label">DP</td>
                            <td>{!isNaN(this.getDPPEP5()) && <div>
                                    {this.getDPPEP5() === Math.min(this.getDPPEP5(), this.getDPPEP10(), this.getDPPEP15()) ? <strong>{this.getDPPEP5()}</strong>: <span>{this.getDPPEP5()}</span>}
                                </div>}
                            </td>
                            <td>{!isNaN(this.getDPPEP10()) && <div>
                                    {this.getDPPEP10() === Math.min(this.getDPPEP5(), this.getDPPEP10(), this.getDPPEP15()) ? <strong>{this.getDPPEP10()}</strong>: <span>{this.getDPPEP10()}</span>}
                                </div>}
                            </td>
                            <td>{!isNaN(this.getDPPEP15()) && <div>
                                    {this.getDPPEP15() === Math.min(this.getDPPEP5(), this.getDPPEP10(), this.getDPPEP15()) ? <strong>{this.getDPPEP15()}</strong>: <span>{this.getDPPEP15()}</span>}
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td className="table-label">P/F</td>
                            <td>{!isNaN(this.getQuotientPEP5()) && <div>
                                    {this.getQuotientPEP5() === Math.max(this.getQuotientPEP5(), this.getQuotientPEP10(), this.getQuotientPEP15())? <strong>{this.getQuotientPEP5()}</strong> : <span>{this.getQuotientPEP5()}</span>}
                                </div>}
                            </td>
                            <td>{!isNaN(this.getQuotientPEP10()) && <div>
                                    {this.getQuotientPEP10() === Math.max(this.getQuotientPEP5(), this.getQuotientPEP10(), this.getQuotientPEP15())? <strong>{this.getQuotientPEP10()}</strong> : <span>{this.getQuotientPEP10()}</span>}
                                </div>}
                            </td>
                            <td>{!isNaN(this.getQuotientPEP15()) && <div>
                                    {this.getQuotientPEP15() === Math.max(this.getQuotientPEP5(), this.getQuotientPEP10(), this.getQuotientPEP15())? <strong>{this.getQuotientPEP15()}</strong> : <span>{this.getQuotientPEP15()}</span>}
                                </div>}
                            </td>
                        </tr>
                    </table>
                    {/* <div className="form-group">
                        <p>Gain de DP:</p>
                        <p className="val">{this.getGainDP()}</p>
                    </div>
                    <div className="form-group">
                        <p>Gain de Compliance:</p>
                        <p className="val">{this.getGainCompliance()}</p>
                    </div> */}
                </fieldset>
                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/potentiel" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(PeepChallenge);
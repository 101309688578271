import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Navigation from "../../navigation/navigation";
//import Switch from '../../../components/switch';
import CustomSlider from '../../CustomSlider';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';

class Decubitus extends CustomComponent {

    constructor(props) {
        super(props);
        this.state = {
            decubitus: {
                pao2: "",
                fio2: "",
                pao2Fio2: "",
                //avantDV: false,
                fio2AvantDV: "",
                pao2AvantDV: "",
                vtAvantDV: "",
                pplatAvantDV: "",
                peptAvantDV: "",
                //pendantDV: false,
                fio2PendantDV: "",
                pao2PendantDV: "",
                vtPendantDV: "",
                pplatPendantDV: "",
                peptPendantDV: "",
                DPAvantDV: "",
                DPPendantDV: "",
                complianceAvantDV: "",
                compliancePendantDV: "",
                quotientAvantDV: "",
                quotientPendantDV: "",
                gainEnDP: "",
                gainEnCompliance: "",
                gainEnPao2Fio2: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let pao2Fio2 = Math.round(this.state.decubitus.pao2 / (this.state.decubitus.fio2 / 100));
        let DPAvantDV = this.getDPAvantDV();
        let DPPendantDV = this.getDPPendantDV();
        let complianceAvantDV = this.getComplianceAvantDV();
        let compliancePendantDV = this.getCompliancePendantDV();
        let quotientAvantDV = this.getQuotientAvantDV();
        let quotientPendantDV = this.getQuotientPendantDV();
        let gainEnDP = this.getDPPendantDV() - this.getDPAvantDV();
        let gainEnCompliance = this.getCompliancePendantDV() - this.getComplianceAvantDV();
        let gainEnPao2Fio2 = this.getQuotientPendantDV() - this.getQuotientAvantDV();

        this.setState(prevState => ({
            decubitus: {
                ...prevState.decubitus,
                pao2Fio2,
                DPAvantDV,
                DPPendantDV,
                complianceAvantDV,
                compliancePendantDV,
                quotientAvantDV,
                quotientPendantDV,
                gainEnDP,
                gainEnCompliance,
                gainEnPao2Fio2
            }
        }));
    }


    componentDidMount() {
        let decubitus = this.loadStorageValues('decubitus', this.state.decubitus);

        if (JSON.stringify(decubitus) !== JSON.stringify(this.state.decubitus))
            this.setState({
                decubitus: decubitus
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('decubitus', JSON.stringify(this.state.decubitus));
    }

    getDPAvantDV = () => {
        return Math.round(this.state.decubitus.pplatAvantDV - this.state.decubitus.peptAvantDV);
    }

    getDPPendantDV = () => {
        return Math.round(this.state.decubitus.pplatPendantDV - this.state.decubitus.peptPendantDV);
    }

    getComplianceAvantDV = () => {
        return Math.round(this.state.decubitus.vtAvantDV / (this.state.decubitus.pplatAvantDV - this.state.decubitus.peptAvantDV));
    }

    getCompliancePendantDV = () => {
        return Math.round(this.state.decubitus.vtPendantDV / (this.state.decubitus.pplatPendantDV - this.state.decubitus.peptPendantDV));
    }

    getQuotientAvantDV = () => {
        return Math.round(this.state.decubitus.pao2AvantDV / (this.state.decubitus.fio2AvantDV / 100));
    }

    getQuotientPendantDV = () => {
        return Math.round(this.state.decubitus.pao2PendantDV / (this.state.decubitus.fio2PendantDV / 100));
    }

    reset() {
        this.setState({
            decubitus: {
                pao2: "",
                fio2: "",
                pao2Fio2: "",
                //avantDV: false,
                fio2AvantDV: "",
                pao2AvantDV: "",
                vtAvantDV: "",
                pplatAvantDV: "",
                peptAvantDV: "",
                //pendantDV: false,
                fio2PendantDV: "",
                pao2PendantDV: "",
                vtPendantDV: "",
                pplatPendantDV: "",
                peptPendantDV: "",
                DPAvantDV: "",
                DPPendantDV: "",
                complianceAvantDV: "",
                compliancePendantDV: "",
                quotientAvantDV: "",
                quotientPendantDV: "",
                gainEnDP: "",
                gainEnCompliance: "",
                gainEnPao2Fio2: ""
            }
        },
            () => {
                localStorage.removeItem('decubitus');
            });
    }


    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>
                            <Link to={{ pathname: "/tools" }} className="back" />
                            <span className="title">Decubitus</span>
                            <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                        </h1>
                    </div>
                    <fieldset>
                        <legend className="center">Indication</legend>
                        <CustomSlider
                            title="PaO2"
                            unit={<div>mmHg</div>}
                            name="pao2"
                            handle={(e, val) => this.handleInputChange(e, val, 'decubitus')}
                            value={this.state.decubitus.pao2}
                            min="1" max="300"
                        />
                        <CustomSlider
                            title="FiO2"
                            unit={<div>%</div>}
                            name="fio2"
                            handle={(e, val) => this.handleInputChange(e, val, 'decubitus')}
                            value={this.state.decubitus.fio2}
                            min="21" max="100"
                        />
                        <div className="form-group">
                            <div className="specific2">
                                <div className="specific2-wrapper">
                                    <div className="specific2-inner">
                                        <div className="col">
                                            <label>PaO2/FiO2</label>
                                            <p className="blue result"><strong>{this.state.decubitus.pao2Fio2}</strong></p>
                                        </div>
                                        <div className="col">
                                            <p className="blue">
                                                {
                                                    (this.state.decubitus.pao2Fio2 <= 150) ?
                                                        "Indication du décubitus ventral"
                                                        :
                                                        "Pas d'indication au décubitus ventral"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="center">Avant décubitus ventral</legend>
                        <div className="form-group specific-row">
                            <div className="form-item">
                                <label>PaO2</label>
                                <input
                                    name="pao2AvantDV"
                                    min="1" max="300"
                                    className="tiny"
                                    type="number"
                                    value={this.state.decubitus.pao2AvantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>mmHg</p>
                            </div>
                            <div className="form-item">
                                <label>FiO2</label>
                                <input
                                    name="fio2AvantDV"
                                    min="1" max="100"
                                    className="tiny"
                                    type="number"
                                    value={this.state.decubitus.fio2AvantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>%</p>
                            </div>
                            <div className="form-item">
                                <label>Vt</label>
                                <input
                                    name="vtAvantDV"
                                    min="1" max="600"
                                    className="tiny"
                                    type="number"
                                    value={this.state.decubitus.vtAvantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>mL</p>
                            </div>
                            <div className="form-item">
                                <label>Pplat</label>
                                <input
                                    name="pplatAvantDV"
                                    min="1" max="50"
                                    className="tiny"
                                    type="number"
                                    value={this.state.decubitus.pplatAvantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                            <div className="form-item">
                                <label>PEPt</label>
                                <input
                                    name="peptAvantDV"
                                    min="1" max="30"
                                    type="number"
                                    className="tiny"
                                    value={this.state.decubitus.peptAvantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="center">Après décubitus ventral</legend>
                        <div className="form-group specific-row">
                            <div className="form-item">
                                <label>PaO2</label>
                                <input
                                    name="pao2PendantDV"
                                    min="1" max="300"
                                    type="number"
                                    className="tiny"
                                    value={this.state.decubitus.pao2PendantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>mmHg</p>
                            </div>
                            <div className="form-item">
                                <label>FiO2</label>
                                <input
                                    name="fio2PendantDV"
                                    min="1" max="100"
                                    className="tiny"
                                    type="number"
                                    value={this.state.decubitus.fio2PendantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>%</p>
                            </div>
                            <div className="form-item">
                                <label>Vt</label>
                                <input
                                    name="vtPendantDV"
                                    min="1" max="600"
                                    className="tiny"
                                    type="number"
                                    value={this.state.decubitus.vtPendantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>mL</p>
                            </div>
                            <div className="form-item">
                                <label>Pplat</label>
                                <input
                                    name="pplatPendantDV"
                                    min="1" max="50"
                                    type="number"
                                    className="tiny"
                                    value={this.state.decubitus.pplatPendantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                            <div className="form-item">
                                <label>PEPt</label>
                                <input
                                    name="peptPendantDV"
                                    min="1" max="30"
                                    type="number"
                                    className="tiny"
                                    value={this.state.decubitus.peptPendantDV}
                                    onChange={(e) => this.handleInputChange(e, null, 'decubitus')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="form-group type-slider">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>Modifications avant/après</p>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p><small>PaO2/FiO2</small></p>
                                            <p className="result"><strong>{(this.state.decubitus.gainEnPao2Fio2 > 0) ? "+" : ""}{this.state.decubitus.gainEnPao2Fio2}</strong></p>
                                        </div>
                                        <div className="col">
                                            <p><small>DP</small></p>
                                            <p className="result"><strong>{(this.state.decubitus.gainEnDP > 0) ? "+" : ""}{this.state.decubitus.gainEnDP}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>0</small></p>
                                        </div>
                                        <div className="col">
                                            <p><small>Crs</small></p>
                                            <p className="result"><strong>{(this.state.decubitus.gainEnCompliance > 0) ? "+" : ""}{this.state.decubitus.gainEnCompliance}</strong></p>
                                            <p className="blue"><small>cmL/cmH<sub>2</sub>0</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div className="wrap-validation">
                        <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                        <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                    </div>
                </div>
                <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                    <div id="info-bloc-inner">
                        <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                        <h2>Définitions</h2>
                        <Accordion panels={t("panels", { returnObjects: true }).Decubitus} />
                    </div>
                </div>
                <Navigation />
            </div>
        );
    }
}

export default withTranslation()(Decubitus);

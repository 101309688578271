import moment from 'moment';
import Navigation from "../navigation/navigation";
import CustomComponent from '../../customComponent';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
// import { ResponsiveRadar } from '@nivo/radar'
import { DoubleBarChart, SingleChartBar } from './Barcharts'
// import { ResponsiveBar } from '@nivo/bar'
// import { ResponsiveLine } from '@nivo/line'
import carreful from '../../css/img/svg/carreful.svg';
import * as html2canvas from "html2canvas";
import logo from "../../img/logo_horizontal.svg";

class Export extends CustomComponent {

  constructor(props) {
    super(props);
    this.state = {
      empty: false,
      medecin: "",
      date: (new Date()).getTime(),
      export_data: {}
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    if(name === "date")
      value = this.parseDate(value).getTime();

    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // save medecin & date
    if (prevState.infos === this.state.infos)
      localStorage.setItem('export', JSON.stringify({ medecin: this.state.medecin }));
  }

  componentDidMount() {
    let exp = {};
    let export_data = {};
    let empty = false;

    let value = 4; // tools, disclamer_popup, userType, survey_popup
    if (localStorage.getItem("pwa_popup_display") !== null)
      value++;
    if (localStorage.getItem("export") !== null)
      value++;
    if (localStorage.getItem("i18nextLng") !== null)
      value++;

    if (localStorage.length > value)
      empty = true;

    if (localStorage.length > (value - 1)) {
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i) === "tools" ||
          localStorage.key(i) === "userType" ||
          localStorage.key(i) === "pwa_popup_display" ||
          localStorage.key(i) === "disclamer_popup" ||
          localStorage.key(i) === "survey_popup")
          continue;
        if (localStorage.key(i) === "export") {
          let l = localStorage.getItem(localStorage.key(i));
          if (this.checkIsJSON(l))
            exp = JSON.parse(l);
          continue;
        }
        let l = localStorage.getItem(localStorage.key(i));
        if (this.checkIsJSON(l)) {
          export_data[localStorage.key(i)] = JSON.parse(l);
        }
      }
    }

    this.setState({
      medecin: (exp.medecin) ? exp.medecin : "",
      // date: (exp.date) ? exp.date : moment(new Date()).format('YYYY-MM-DD'),
      export_data: export_data,
      empty: empty
    });

    document.getElementsByClassName('wrapper-app')[0].style.overflow = "hidden";
    document.getElementsByClassName('wrapper-pdf')[0].style.opacity = 0;
  }

  parseDate(s) {
    let b = s.split(/\D/);
    return new Date(b[0], --b[1], b[2]);
  }

  exportCsv() {
    let csvData = [];
    let { export_data } = this.state;
    for (let page in export_data) {
      for (let data in export_data[page]) {
        let tool = {
          page: page,
          name: data,
          data: export_data[page][data]
        };
        csvData.push(tool);
      }
    }
    return csvData;
  }

  async savePDF() {
    let wrapper_app = document.getElementsByClassName('wrapper-app')[0];
    let pdf = new jsPDF('p', 'pt', 'letter');
    let quotes = document.getElementsByClassName('wrapper-pdf')[0];
    quotes.style.opacity = 1; // make visible to create pdf
    wrapper_app.scrollTo(0, 0); // scroll to create canvas with full page
    for (let i = 0; i < quotes.clientHeight / 1250; i++) {
      await html2canvas(quotes, {
        scale: 2, // quality
        useCORS: true,
        y: 1250 * i,
        height: 1250,
        width: 1140
      }).then((canvas) => {
        let dWidth = 980;
        let dataURL = canvas.toDataURL("image/jpeg", 1.0)

        if (i > 0) {
          pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
        }

        pdf.setPage(i + 1);

        pdf.addImage(dataURL, 'JPEG', 5, 40, (dWidth * .62), 0);


      });
    }
    quotes.style.opacity = 0; // make invisible after create pdf
    pdf.save('exportfile.pdf');
  }


  reset() {
    const userType = localStorage.getItem('userType');
    const tools = localStorage.getItem('tools');
    const pwa_popup = localStorage.getItem('pwa_popup_display');
    const disc_popup = localStorage.getItem('disclamer_popup');
    const survey_popup = localStorage.getItem('survey_popup');
    localStorage.clear();
    this.setState({
      empty: false
    });
    localStorage.setItem('userType', userType);
    localStorage.setItem('tools', tools);
    localStorage.setItem('pwa_popup_display', pwa_popup);
    localStorage.setItem('disclamer_popup', disc_popup);
    localStorage.setItem('survey_popup', survey_popup);
  }

  render() {

    const headers = [
      { label: "Page", key: "page" },
      { label: "Nom", key: "name" },
      { label: "Valeur", key: "data" }
    ];

    // const
    //   data_radar = [
    //     {
    //       indice: "PaO2/FiO2",
    //       valeur: ((this.state.export_data.diagnostic) ? this.state.export_data.diagnostic.pao2Fio2 : "")
    //     },
    //     {
    //       indice: "Pplat",
    //       valeur: ((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.pressionPlateau : "")
    //     },
    //     {
    //       indice: "DP",
    //       valeur: ((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.pressionMotrice : "")
    //     },
    //     {
    //       indice: "Rrs",
    //       valeur: ((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.resistance : "")
    //     },
    //     {
    //       indice: "Crs",
    //       valeur: ((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.compliance : "")
    //     }
    //   ];

    // Non disponible dans v1 car pas de suivi sur plusieurs jours

    // const data_line = [
    //   {
    //     "id": "Crs",
    //     "data": [
    //       {
    //         "x": "#1",
    //         "y": 203
    //       },
    //       {
    //         "x": "#2",
    //         "y": 9
    //       },
    //       {
    //         "x": "#3",
    //         "y": 161
    //       }
    //     ]
    //   },
    //   {
    //     "id": "DP",
    //     "data": [
    //       {
    //         "x": "#1",
    //         "y": 225
    //       },
    //       {
    //         "x": "#2",
    //         "y": 275
    //       },
    //       {
    //         "x": "#3",
    //         "y": 265
    //       }
    //     ]
    //   },
    //   {
    //     "id": "PaO2FiO2",
    //     "data": [
    //       {
    //         "x": "#1",
    //         "y": 96
    //       },
    //       {
    //         "x": "#2",
    //         "y": 104
    //       },
    //       {
    //         "x": "#3",
    //         "y": 247
    //       }
    //     ]
    //   }
    // ];

    // // const keys = ['valeur'];

    // const Symbol = ({ color }) => {
    //   let polygon = <polygon fill={color} points="-10 -10, 10 -10, 10 10, -10 10" />;

    //   if (color === '#00E2FF') polygon = <polygon fill={color} points="0 -15, 15 12, -15 12" />
    //   else if (color === '#606060') polygon = <circle r={12} fill={color} />

    //   return (
    //     <g>
    //       {polygon}
    //     </g>
    //   )
    // };

    // const styleById = {
    //   Crs: {
    //     strokeDasharray: '4,4',
    //     strokeWidth: 2,
    //   },
    //   DP: {
    //     strokeDasharray: '4,4',
    //     strokeWidth: 2,
    //   },
    //   PaO2FiO2: {
    //     strokeDasharray: '4,4',
    //     strokeWidth: 2,
    //   }
    // };

    // const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
    //   return series.map(({ id, data, color }) => (
    //     <path
    //       key={id}
    //       d={lineGenerator(
    //         data.map(d => ({
    //           x: xScale(d.data.x),
    //           y: yScale(d.data.y),
    //         }))
    //       )}
    //       fill="none"
    //       stroke={color}
    //       style={styleById[id] || styleById.default}
    //     />
    //   ))
    // };


    return (
      <div>
        <div className="overlay-pdf">
          <div className="overlay-content">
            <div className="content">
              <div className="sticky-top">
                <h1>Export</h1>
              </div>
              {(this.state.empty) ?
                <div className="inner-content">
                  <fieldset>
                    <div className="form-group full-width no-border pb-0">
                      <label htmlFor="name-med">Nom du médecin</label>
                      <input type="text" id="name-med" name="medecin" value={this.state.medecin}
                        onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group border-bottom">
                      <label htmlFor="date">Date</label>
                      <input type="date" id="date" name="date" value={moment(this.state.date).format('YYYY-MM-DD')}
                        onChange={this.handleInputChange} />
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className="form-group no-border pt-20">
                      <button className="btn dll-link pdf" onClick={() => this.savePDF()}>Exporter la synthèse
                          </button>
                    </div>
                    <div className="form-group full-width border-bottom pb-20">
                      <CSVLink
                        className="btn data-link data"
                        separator={";"}
                        data={this.exportCsv()}
                        headers={headers}
                        filename={"exportfile.csv"}
                      >Exporter les données</CSVLink>
                    </div>
                  </fieldset>
                  <div className="wrap-validation center mt-30">
                    <button className="btn reload" onClick={() => this.reset()}>Effacer toutes les données</button>
                  </div>
                </div>
                :
                <div className="content-center">
                  <img src={carreful} alt="" width="94" height="84" />
                  <p><strong>Aucune données<br /> à exporter actuellement</strong></p>
                </div>
              }
            </div>
            <Navigation />
          </div>
        </div>

        <div className="wrapper-pdf">
          <div className="pdf-page">
            <div className="background"></div>

            <div className="wrap-title" style={{ height: "95px" }}>
              <img src={logo} alt="logo sdrapps" />
            </div>

            <h2>Évaluation multimodale de la mécanique ventilatoire au cours du SDRA</h2>

            <section>
              <form>
                <div className="form-2col">
                  <div className="col-left">
                    <div className="form-row">
                      <label>Prise en charge le</label>
                      <input type="date" value={moment(this.state.date).format('YYYY-MM-DD')} onChange={(e) => { }} />

                      <label>par</label>
                      <input type="text" value={this.state.medecin} onChange={(e) => { }} />
                    </div>

                    <div className="form-row">
                      <label>Le patient présente un SDRA</label>
                      <input type="text" style={{ width: "250px" }} value={(this.state.export_data.diagnostic) ? this.state.export_data.diagnostic.sdra : ""} onChange={(e) => { }} />
                    </div>
                    <div className="form-row">
                      <label>Il s'agit d'un SDRA d'origine</label>
                      <input type="text" style={{ width: "250px" }} value={(this.state.export_data.diagnostic) ? this.state.export_data.diagnostic.etiologie : ""} onChange={(e) => { }} />
                    </div>
                    <div className="form-row">
                      <label>L'aspect scannograhique est</label>
                      <input type="text" style={{ width: "250px" }} value={(this.state.export_data.diagnostic) ? this.state.export_data.diagnostic.aspect : ""} onChange={(e) => { }} />
                    </div>
                  </div>

                  <div className="col-right">
                    <div className="etiquette-patient">
                      <p>Etiquette patient</p>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label>Sédaté</label>
                    <input type="checkbox" value="Sédaté" {...(this.state.export_data.mecaventil && { checked: this.state.export_data.mecaventil.sedation })} onChange={(e) => { }} />
                  </div>
                  <div className="form-group">
                    <label>Curatisé</label>
                    <input type="checkbox" value="Curatisé" {...(this.state.export_data.mecaventil && { checked: this.state.export_data.mecaventil.curarisation })} onChange={(e) => { }} />
                  </div>
                  <div className="form-group">
                    <label>Poids (PIT)</label>
                    <input type="text" style={{ width: "75px", textAlign: "center" }} value={((this.state.export_data.volcourant) ? this.state.export_data.volcourant.ppt + " kg" : "")} onChange={(e) => { }} />
                  </div>
                  <div className="form-group">
                    <label>Vt 6 mL/kg PIT</label>
                    <input type="text" style={{ width: "75px", textAlign: "center" }} value={((this.state.export_data.volcourant) ? this.state.export_data.volcourant.vt6 + " mL" : "")} onChange={(e) => { }} />
                  </div>
                </div>
              </form>
            </section>

            <section>
              <div className="wrap-title">
                <h3 style={{ color: "#FFF", textAlign: "center" }}>Paramètres initiaux</h3>
              </div>
              <table id="parametres_initiaux" className="table table-striped">
                <tbody>
                  <tr>
                    <td>Mode ventilatoire</td>
                    <td>
                      <label htmlFor="vt">Vt</label>
                      <input type="text" id="vt" value={((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.volcourant : "")} onChange={(e) => { }} />
                      <p>ml</p>
                    </td>
                    <td>
                      <label htmlFor="debit">Débit</label>
                      <input type="text" id="debit" value={((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.debit : "")} onChange={(e) => { }} />
                      <p>L/min</p>
                    </td>
                    <td>
                      <label htmlFor="FR">FR</label>
                      <input type="text" id="FR" value={((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.frequence : "")} onChange={(e) => { }} />
                      <p>/min</p>
                    </td>
                    <td>
                      <label htmlFor="FiO2">FiO2</label>
                      <input type="text" id="FiO2" value={((this.state.export_data.diagnostic) ? this.state.export_data.diagnostic.fio2 : "")} onChange={(e) => { }} />
                      <p>%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <section>
              <div className="wrap-title" style={{ backgroundColor: "#0633F2", width: "100%", height: "40px" }}>
                <h3 style={{ color: "#FFF", textAlign: "center" }}>Mécanique respiratoire</h3>
              </div>

              <table id="mecanique_respiratoire" className="table table-striped">
                <thead>
                  <tr>
                    <th>Variable</th>
                    <th>Valeur</th>
                    <th>Normes</th>
                    <th>Description</th>
                    <th>Commentaire</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PEEP totale</td>
                    <td>{((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.pep : "")}</td>
                    <td>na</td>
                    <td>Pause télé-expiratoire</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>PEEP intrasèque</td>
                    <td></td>
                    <td>&lt;2</td>
                    <td>PEEP totale - PEEP extr</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>P plateau</td>
                    <td>{((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.pressionPlateau : "")}</td>
                    <td>&lt;30</td>
                    <td>Pause télé-expiratoire</td>
                    <td>
                      {
                        ((this.state.export_data.mecaventil) ?
                          (Number(this.state.export_data.mecaventil.pressionPlateau) >= 30) ?
                            "Risque de surdistention élevé"
                            :
                            "Risque de surdistention faible"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>P motrice</td>
                    <td>{((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.pressionMotrice : "")}</td>
                    <td>&lt;16</td>
                    <td>Pplat - PEEP</td>
                    <td>
                      {
                        ((this.state.export_data.mecaventil) ?
                          (Number(this.state.export_data.mecaventil.pressionMotrice) > 14) ?
                            "Risque de surdistention élevé"
                            :
                            "Risque de surdistention faible"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>P transpulmonaire i</td>
                    <td>{((this.state.export_data.pressionoeso) ? this.state.export_data.pressionoeso.PTPi : "")}</td>
                    <td>&lt;25</td>
                    <td>Pplat - Poes insp</td>
                    <td>
                      {
                        ((this.state.export_data.pressionoeso) ?
                          (Number(this.state.export_data.pressionoeso.PTPi) >= 25) ?
                            "Risque de surdistention élevé"
                            :
                            "Risque de surdistention faible"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>P transpulmonaire e</td>
                    <td>{((this.state.export_data.pressionoeso) ? this.state.export_data.pressionoeso.PTPe : "")}</td>
                    <td>&gt;0</td>
                    <td>PEEP - Poes exp</td>
                    <td>
                      {
                        ((this.state.export_data.pressionoeso) ?
                          (Number(this.state.export_data.pressionoeso.PTPe) >= 0) ?
                            "Risque de dérecrutement faible"
                            :
                            "Risque de dérecrutement élevé"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Elastance, rs</td>
                    <td>{((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.elastance : "")}</td>
                    <td>&lt;25</td>
                    <td>(Pplat - PEEP) / Vt</td>
                    <td>
                      {
                        ((this.state.export_data.mecaventil) ?
                          (Number(this.state.export_data.mecaventil.elastance) >= 25) ?
                            "Elastance du système respiratoire basse"
                            :
                            "Elastance du système respiratoire préservée"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Compliance, rs</td>
                    <td>{((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.compliance : "")}</td>
                    <td>&gt;40</td>
                    <td>Vt/ (Pplat - PEEP)</td>
                    <td>
                      {
                        ((this.state.export_data.mecaventil) ?
                          (Number(this.state.export_data.mecaventil.compliance) >= 40) ?
                            "Compliance du système respiratoire préservée"
                            :
                            "Compliance du système respiratoire basse"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Résistance, rs</td>
                    <td>{((this.state.export_data.mecaventil) ? this.state.export_data.mecaventil.resistance : "")}</td>
                    <td>&gt;10</td>
                    <td>(Pplat - PEEP) / Q</td>
                    <td>
                      {
                        ((this.state.export_data.mecaventil) ?
                          (Number(this.state.export_data.mecaventil.resistance) >= 10) ?
                            "Résistance du système respiratoire élevée"
                            :
                            "Résistance du système respiratoire basse"
                          :
                          ""
                        )
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            <div className="wrap-title page-pdf">
              <h3 style={{ color: "#FFF", textAlign: "center" }}>Page 1/2</h3>
            </div>
          </div>
          <div className="pdf-page">
            <div className="wrap-title" style={{ height: "95px" }}>
              <img src={logo} alt="logo sdrapps" />
            </div>

            <h2>Vue d'ensemble des échanges gazeux et la mécanique ventilatoire
                le {moment(this.state.date).format('DD/MM/YYYY à HH:mm')}</h2>

            <div className="background"></div>

            <section>
              <div className="grid-charts">
                {/* <div className="radar_chart">
                  <ResponsiveRadar
                    data={data_radar}
                    keys={['valeur']}
                    theme={{
                      fontSize: 18,
                      axis: {
                        textColor: '#eee',
                      },
                      grid: {
                        stroke: '#888',
                        strokeWidth: 1,
                        strokeOpacity: 0.3,
                      },
                      dots: {
                        text: {
                          fontSize: 12,
                          fontColor: '#B6B6B6',
                          opacity: 0.6,
                        },
                      }
                    }}
                    indexBy="indice"
                    maxValue="auto"
                    margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                    curve="linearClosed"
                    borderWidth={0}
                    borderColor={{ from: 'color' }}
                    gridLevels={3}
                    gridShape="linear"
                    gridLabelOffset={28}
                    enableDots={true}
                    dotSize={0}
                    dotColor={{ theme: 'background' }}
                    dotBorderWidth={2}
                    dotBorderColor={{ from: 'color' }}
                    enableDotLabel={true}
                    dotLabel="value"
                    dotLabelYOffset={-12}
                    colors={["#00E2FF"]}
                    fillOpacity={0.2}
                    isInteractive={false}
                  />
                </div> */}

                {/* TOP STATS */}
                <div className="wrapper-charts_stats">
                  <form>
                    <div className="form-row">
                      <label>PTPi (cmH2O)</label>
                      <input type="text" value={((this.state.export_data.pressionoeso) ? this.state.export_data.pressionoeso.PTPi : "")} onChange={(e) => { }} />
                    </div>
                    <div className="form-row">
                      <label>PTPe (cmH2O)</label>
                      <input type="text" value={((this.state.export_data.pressionoeso) ? this.state.export_data.pressionoeso.PTPe : "")} onChange={(e) => { }} />
                    </div>
                    <div className="form-row">
                      <label>VR</label>
                      <input type="text" value={((this.state.export_data.ventilatory) ? this.state.export_data.ventilatory.vr : "")} onChange={(e) => { }} />
                    </div>
                    <div className="form-row">
                      <label>Qs / Qt (%)</label>
                      <input type="text" value={((this.state.export_data.shuntSelonO2) ? this.state.export_data.shuntSelonO2.shunt : "")} onChange={(e) => { }} />
                    </div>
                  </form>
                </div>

                {/* TOP STATS */}
                <div className="wrapper-singlebar-chart">
                  <SingleChartBar
                    label="P<sub>a</sub>O<sub>2</sub> / F<sub>i</sub>O<sub>2</sub>"
                    maxValue = {300}
                    value={this.state.export_data.diagnostic ? this.state.export_data.diagnostic.pao2Fio2 : ""}
                    color="#606060"
                  />
                  <SingleChartBar
                    label="C<sub>rs</sub>"
                    maxValue = {80}
                    value={this.state.export_data.mecaventil ? this.state.export_data.mecaventil.compliance : ""}
                    color="#0028e6"
                  />
                  <SingleChartBar
                    label="DP"
                    maxValue = {20}
                    value={this.state.export_data.mecaventil ? this.state.export_data.mecaventil.pressionMotrice : ""}
                    color="#03d8ff"
                  />
                  <SingleChartBar
                    label="P<sub>plat</sub>"
                    maxValue = {30}
                    value={this.state.export_data.mecaventil ? this.state.export_data.mecaventil.pressionPlateau : ""}
                    color="#9badf9"
                  />
                  <SingleChartBar
                    label="R<sub>rs</sub>"
                    maxValue = {30}
                    value={this.state.export_data.mecaventil ? this.state.export_data.mecaventil.resistance : ""}
                    color="#cdd6fc"
                  />
                </div>

                {/* BEFORE / AFTER STATS (LEFT) */}
                <div className="wrapper-bar_chart">
                  <h2>Réponse au décubitus ventral</h2>
                  <DoubleBarChart
                    barclass="bar_chart1"
                    legend="C<sub>rs</sub>"
                    color="#0B5AD1"
                    before={((this.state.export_data.decubitus) ? this.state.export_data.decubitus.vtAvantDV : 0) /
                      (((this.state.export_data.decubitus) ? this.state.export_data.decubitus.pplatAvantDV : 0) -
                        ((this.state.export_data.decubitus) ? this.state.export_data.decubitus.peptAvantDV : 0))}
                    after={((this.state.export_data.decubitus) ? this.state.export_data.decubitus.vtPendantDV : 0) /
                      (((this.state.export_data.decubitus) ? this.state.export_data.decubitus.pplatPendantDV : 0) -
                        ((this.state.export_data.decubitus) ? this.state.export_data.decubitus.peptPendantDV : 0))}
                  />
                  <DoubleBarChart
                    barclass="bar_chart2"
                    legend="DP"
                    color="#00E2FF"
                    before={((this.state.export_data.decubitus) ? this.state.export_data.decubitus.pplatAvantDV : 0) -
                      ((this.state.export_data.decubitus) ? this.state.export_data.decubitus.peptAvantDV : 0)}
                    after={((this.state.export_data.decubitus) ? this.state.export_data.decubitus.pplatPendantDV : 0) -
                      ((this.state.export_data.decubitus) ? this.state.export_data.decubitus.peptPendantDV : 0)}
                  />
                  <DoubleBarChart
                    barclass="bar_chart3"
                    legend="P<sub>a</sub>O<sub>2</sub> / F<sub>i</sub>o<sub>2</sub>"
                    color="#606060"
                    before={((this.state.export_data.decubitus) ? this.state.export_data.decubitus.pao2AvantDV : 0) /
                      (((this.state.export_data.decubitus) ? this.state.export_data.decubitus.fio2AvantDV : 0) / 100)}
                    after={((this.state.export_data.decubitus) ? this.state.export_data.decubitus.pao2PendantDV : 0) /
                      (((this.state.export_data.decubitus) ? this.state.export_data.decubitus.fio2PendantDV : 0) / 100)}
                  />
                </div>

                {/* BEFORE / AFTER STATS (RIGHT) */}
                <div className="wrapper-bar_chart">
                  <h2>Réponse à la curarisation</h2>
                  <DoubleBarChart
                    barclass="bar_chart4"
                    legend="C<sub>rs</sub>"
                    color="#0B5AD1"
                    before={((this.state.export_data.curarisation) ? this.state.export_data.curarisation.vtAvantCurare : 0) /
                      (((this.state.export_data.curarisation) ? this.state.export_data.curarisation.pplatAvantCurare : 0) -
                        ((this.state.export_data.curarisation) ? this.state.export_data.curarisation.peptAvantCurare : 0))}
                    after={((this.state.export_data.curarisation) ? this.state.export_data.curarisation.vtPendantCurare : 0) /
                      (((this.state.export_data.curarisation) ? this.state.export_data.curarisation.pplatPendantCurare : 0) -
                        ((this.state.export_data.curarisation) ? this.state.export_data.curarisation.peptPendantCurare : 0))}
                  />
                  <DoubleBarChart
                    barclass="bar_chart5"
                    legend="DP"
                    color="#00E2FF"
                    before={((this.state.export_data.curarisation) ? this.state.export_data.curarisation.pplatAvantCurare : 0) -
                      ((this.state.export_data.curarisation) ? this.state.export_data.curarisation.peptAvantCurare : 0)}
                    after={((this.state.export_data.curarisation) ? this.state.export_data.curarisation.pplatPendantCurare : 0) -
                      ((this.state.export_data.curarisation) ? this.state.export_data.curarisation.peptPendantCurare : 0)}
                  />
                  <DoubleBarChart
                    barclass="bar_chart6"
                    legend="P<sub>a</sub>O<sub>2</sub> / F<sub>i</sub>o<sub>2</sub>"
                    color="#606060"
                    before={((this.state.export_data.curarisation) ? this.state.export_data.curarisation.pao2AvantCurare : 0) /
                      (((this.state.export_data.curarisation) ? this.state.export_data.curarisation.fio2AvantCurare : 0) / 100)}
                    after={((this.state.export_data.curarisation) ? this.state.export_data.curarisation.pao2PendantCurare : 0) /
                      (((this.state.export_data.curarisation) ? this.state.export_data.curarisation.fio2PendantCurare : 0) / 100)}
                  />
                </div>

                {/* Non disponible dans v1 car pas de suivi sur plusieurs jours */}

                {/* <div className="wrapper-line_chart">
                <h2>Évolution de la mécanique ventilatoirs au cours du temps</h2>
                <div className="line_chart">
                  <ResponsiveLine
                    data={data_line}
                    theme={{
                      fontFamily: 'robotobold',
                      fontSize: 16,
                      fontColor: '#666'
                    }}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    layers={['grid', 'markers', 'areas', DashedLine, 'slices', 'points', 'axes', 'legends']}
                    enableGridX={false}
                    enableGridY={false}
                    colors={['#0B5AD1', '#00E2FF', '#606060']}
                    pointSize={18}
                    pointBorderWidth={8}
                    enablePointLabel={true}
                    pointLabelYOffset={-20}
                    pointSymbol={Symbol}
                    isInteractive={false}
                    useMesh={true}
                    legends={[
                      {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 110,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 90,
                        itemHeight: 50,
                        itemOpacity: 0.75,
                        symbolSize: 0,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      }
                    ]}
                  />
                </div>

                <div className="line_chart-times">
                  <div className="time1">
                    <p>#1</p>
                    <p>15/01/2020 - 15:21</p>
                  </div>
                  <div className="time2">
                    <p>#2</p>
                    <p>15/01/2020 - 22:08</p>
                  </div>
                  <div className="time3">
                    <p>#3</p>
                    <p>16/01/2020 - 09:57</p>
                  </div>
                </div>
              </div> */}

              </div>
            </section>

            <div className="wrap-title page-pdf">
              <h3 style={{ color: "#FFF", textAlign: "center" }}>Page 2/2</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Export;


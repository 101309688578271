import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';
import stressindexlevel1 from '../../../img/stress_index_type1.svg';
import stressindexlevel2 from '../../../img/stress_index_type2.svg';
import stressindexlevel3 from '../../../img/stress_index_type3.svg';

class StressIndex extends CustomComponent {

    constructor(props) {
        super(props);
        this.state = {
            stressIndex: {
                typeSI: "",
                recommandationSI: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let recommandationSI = "";
        switch (Number(this.state.stressIndex.typeSI)) {
            case 1:
                recommandationSI = "Probable potentiel de recrutement !";
                break;
            case 2:
                recommandationSI = "Recrutement optimal !";
                break;
            case 3:
                recommandationSI = <div>Pas de potentiel de recrutement !<br /> Risque de surdistention !</div>;
                break;
            default:
                recommandationSI = "";
        }

        this.setState(prevState => ({
            stressIndex: {
                ...prevState.stressIndex,
                recommandationSI
            }
        }));
    }

    componentDidMount() {
        let stressIndex = this.loadStorageValues('stressIndex', this.state.stressIndex);

        if (JSON.stringify(stressIndex) !== JSON.stringify(this.state.stressIndex))
            this.setState({
                stressIndex: stressIndex
            }, () => {
                this.runCalculs();
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('stressIndex', JSON.stringify(this.state.stressIndex));
    }

    reset() {
        this.setState({
            stressIndex: {
                typeSI: "",
                recommandationSI: ""
            }
        },
            () => {
                localStorage.removeItem('stressIndex');
            });
    }

    render() {

        const panels = [
            {
                label: 'Rationnel',
                content: <div>
                    <p>Le stress index (SI) repose sur l'analyse de l’aspect de la pente de la courbe de pression des voies aériennes au cours de l'insufflation. Cette pente est proportionnelle à l'élastance du système respiratoire à condition que le patient soit passif, ventilé en mode volume contrôlé et avec un débit d’insufflation constant<sup>1,2</sup>. La valeur du SI correspond au coefficient b dans la modélisation suivante de l’aspect de la courbe de pression des voies aériennes (P<sub>aw</sub>) en fonction du temps au cours de l’insufflation (T<sub>i</sub>) :
				<p align="center">
                            <i>Paw</i> = <i>a.Ti<sup>b</sup></i> + <i>c</i>
                        </p>
                        <p>
                            En pratique on distingue un SI inférieur à 1, égale à 1 ou supérieur à 1.
				</p>
                    </p></div>
            },
            {
                label: 'Méthode',
                content: <div><p>Certains ventilateurs fournissent directement la valeur du SI. Sinon il est toujours possible d’analyser l’aspect de la courbe de pression en fonction du temps (fournie par tous les ventilateurs) pour extrapoler la valeur du SI<sup>1</sup>. Le principal avantage de cet outil est d’être facilement utilisable au lit du patient (sous réserve d’un patient bien adapté au respirateur) pour aider le clinicien à choisir la PEP optimale à appliquer<sup>3</sup>.</p>
                    <ul>
                        <li>Un aspect convexe vers le haut (SI de type 1) de la courbe de pression au cours de l’insufflation à débit constant correspond à un SI inférieur à 1. Cet aspect, qui correspond à une diminution progressive de l’élastance en cours d’insufflation, suggère un potentiel de recrutement alvéolaire et incite à une augmentation de la PEP. </li>
                        <li>Un SI égal à 1 (SI de type 2) correspond à un aspect linéaire de la courbe de pression. Ce profil est compatible avec une ventilation protectrice, sans potentiel de recrutement ni surdistention alvéolaire. </li>
                        <li>Un aspect concave vers le haut (SI de type 3) de la courbe de pression au cours de l’insufflation à débit constant correspond à un SI supérieur à 1. Cet aspect, qui correspond à une augmentation de l’élastance au cours de l’insufflation, est corrélé avec une surdistention alvéolaire et incite à une réduction de la PEP.</li>
                    </ul>
                    <p>A noter, l’analyse de l’aspect de la courbe de pression est plus facile pour des débits d’insufflation modérés (40-50 L/min). La concordance entre l’évaluation visuelle de la courbe pression-temps par le clinicien et la valeur mesurée du SI est bonne<sup>4</sup>.</p>
                    <p>L’interprétation du SI peut être difficile en cas d’épanchement pleural, d’hyperpression intra-abdominale ou lorsque l’atteinte pulmonaire est hétérogène<sup>1</sup>.</p>
                </div>
            },
            {
                label: 'Intérêt au cours du SDRA',
                content: <div>
                    <p>Dans la littérature, le SI est souvent utilisé pour évaluer le risque de surdistention à un niveau de PEP donné<sup>5,6</sup>. Terragni et al. ont montré que la présence de zones surdistendues au scanner était bien corrélée à un SI supérieur à 1,05<sup>7</sup>. Le SI pourrait donc être un outil facilement utilisable au lit du malade pour aider le clinicien à diminuer le risque de lésions induites par la ventilation mécanique (<i>ventilator-induiced lung injury</i>, VILI)<sup>8</sup>.</p>
                    <p>Plus rarement, le SI est directement utilisé pour déterminer la PEP à appliquer au patient. Dans cette utilisation, le niveau de PEP déterminé par le SI était similaire à celui déterminé par les autres techniques (imagerie pulmonaire, courbe pression-volume, compliance pulmonaire…)<sup>9,10</sup>.</p></div>
            },
            {
                label: 'Références',
                content: <div>
                    <p>
                        1. 	Henderson WR, Chen L, Amato MBP, Brochard LJ. Fifty Years of Research in ARDS.Respiratory Mechanics in Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2017;196(7):822–833. <br /><br />
						2. 	Kallet RH. Should PEEP Titration Be Based on Chest Mechanics in Patients With ARDS? <i>Respir Care</i> 2016;61(6):876–890. <br /><br />
						3. 	Wongsurakiat P, Yuangtrakul N. Performance and applications of bedside visual inspection of airway pressure–time curve profiles for estimating stress index in patients with acute respiratory distress syndrome. <i>J Clin Monit Comput</i> 2019;33(2):281–290. <br /><br />
						4. 	Sun X-M, Chen G-Q, Chen K, et al. Stress Index Can Be Accurately and Reliably Assessed by Visually Inspecting Ventilator Waveforms. <i>Respiratory Care</i> 2018;63(9):1094–1101. <br /><br />
						5. 	Valentini R, Aquino-Esperanza J, Bonelli I, et al. Gas exchange and lung mechanics in patients with acute respiratory distress syndrome: comparison of three different strategies of positive end expiratory pressure selection. <i>J Crit Care</i> 2015;30(2):334–340. <br /><br />
						6. 	Grasso S, Stripoli T, De Michele M, et al. ARDSnet ventilatory protocol and alveolar hyperinflation: role of positive end-expiratory pressure. <i>Am J Respir Crit Care Med</i> 2007;176(8):761–767. <br /><br />
						7. 	Terragni PP, Filippini C, Slutsky AS, et al. Accuracy of Plateau Pressure and Stress Index to Identify Injurious Ventilation in Patients with Acute Respiratory Distress Syndrome. <i>Anesthesiology</i> 2013;119(4):880–889. <br /><br />
						8. 	Terragni PP, Rosboch GL, Lisi A, Viale AG, Ranieri VM. How respiratory system mechanics may help in minimising ventilator-induced lung injury in ARDS patients. <i>European Respiratory Journal</i> 2003;22(42 suppl):15s–21s. <br /><br />
						9. 	Pan C, Tang R, Xie J, et al. Stress index for positive end-expiratory pressure titration in prone position: a piglet study. <i>Acta Anaesthesiol Scand</i> 2015;59(9):1170–1178. <br /><br />
						10. 	Huang Y, Yang Y, Chen Q, et al. Pulmonary acute respiratory distress syndrome: positive end-expiratory pressure titration needs stress index. <i>J Surg Res</i> 2013;185(1):347–352.<br /><br /><br />

                    </p></div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/potentiel" }} className="back" />
                        <span className="title">Stress index</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>
                <fieldset>
                    <div className="form-group form-check-inline form-stress-index">
                        <div className="form-check stress-level">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="typeSI"
                                id="typeSI1"
                                value="1"
                                onChange={(e) => this.handleInputChange(e, null, 'stressIndex')}
                                checked={Number(this.state.stressIndex.typeSI) === 1}
                            />
                            <label className="form-check-label" htmlFor="typeSI1">
                                <p>Type 1</p>
                                <img src={stressindexlevel1} alt="courbe niveau stress 1" />
                                <p>SI &lt; 0.9</p>
                            </label>
                        </div>
                        <div className="form-check stress-level">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="typeSI"
                                id="typeSI2"
                                value="2"
                                onChange={(e) => this.handleInputChange(e, null, 'stressIndex')}
                                checked={Number(this.state.stressIndex.typeSI) === 2}
                            />
                            <label className="form-check-label" htmlFor="typeSI2">
                                <p>Type 2</p>
                                <img src={stressindexlevel2} alt="courbe niveau stress 2" />
                                <p>0.9 &lt; SI &gt; 1.1</p>
                            </label>
                        </div>
                        <div className="form-check stress-level">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="typeSI"
                                id="typeSI3"
                                value="3"
                                onChange={(e) => this.handleInputChange(e, null, 'stressIndex')}
                                checked={Number(this.state.stressIndex.typeSI) === 3}
                            />
                            <label className="form-check-label" htmlFor="typeSI3">
                                <p>Type 3</p>
                                <img src={stressindexlevel3} alt="courbe niveau stress 3" />
                                <p>SI &gt; 1.1</p>
                            </label>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <div className="form-group">
                        <div className="specific3">
                            <div className="specific3-wrapper">
                                {/* <p className="result">Résultats</p> */}
                                <div className="specific4-inner">
                                    <div className="col">
                                        <p className="blue">{this.state.stressIndex.recommandationSI}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>
            </div>

            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(StressIndex);
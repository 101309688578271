import React from "react";
import Navigation from "../navigation/navigation";
import NavAbout from "../navigation/navAbout";
import svgInfos from '../../css/img/svg/info-tiny.svg'
import svgVersion from '../../css/img/svg/clear-tiny.svg'

function About() {
    return (
        <div>
            <div className="content">

                <NavAbout />
                <div className="tab-item open" style={{textAlign: "justify"}}>
                    <h2 className="center">Evaluation de la mécanique ventilatoire</h2>
                    <p className="chapo">SDRApps est une application pour smartphone et tablette tactile,
                    d’aide à la prise en charge du syndrome de détresse respiratoire aiguë (SDRA).
                    L’application permet l’exploration de la mécanique ventilatoire à travers différents calculs automatisés.</p>
                    <p>SDRApps vous aidera à une prise en charge personnalisée de vos patients en SDRA :</p>
                    <ul>
                        <li>Quel niveau de pression expiratoire positive appliquer ?</li>
                        <li>Faut-il le curariser ou réaliser une séance de décubitus ventral ?</li>
                        <li>Y a-t-il un potentiel de recrutement alvéolaire ?</li>
                        <li>Comment évaluer l'espace mort ?</li>
                        <li>Comment évaluer le shunt intrapulmonaire ?</li>
                    </ul>
                    <br />
                    <h2 className="center">Fiches pédagogiques</h2>
                    <p>
                        SDRApps est aussi un outil pédagogique grâce à de nombreuses fiches explicatives incluses.
                        Des vidéos explicatives permettent de reproduire les manœuvres utilisées dans l’application.
                        Les calculs et les manœuvres ont été mis à jour à la lumière des dernières publications.
                        </p>
                    <p>Pour accéder à ces informations, appuyez sur le bouton <img src={svgInfos} className="inline-img" width="17" height="17" alt="" /> dans la barre des tâches de chaque outil. Vous pouvez ensuite revenir à la page de calcul en appuyant sur le bouton retour.</p>
                    <br />
                    <h2 className="center">Mise en garde</h2>
                    <p>
                        Les informations contenues dans cette application sont à usage des professionnels de santé.
                        Son contenu ne se substitue pas à la responsabilité d'un médecin.
                        La plupart des outils et informations sont basées sur les recommandations des sociétés savantes
                        françaises ou internationales mais peuvent aussi reposer sur l'analyse de publications plus récentes.
                        Ils seront mis à jour au fur et à mesure de la parution d'autres documents de référence.
                    </p>
                    <p>
                        Dans le cadre d'un traitement personnalisé d'une pathologie aussi complexe que le SDRA,
                        chaque médecin est libre d'utiliser d'autres choix que ceux proposés par l'application.
                    </p>
                    <p><strong>Version</strong><br /><img src={svgVersion} className="inline-img" width="17" height="17" alt="" />  v1.0 (mise à jour le 24/02/2021)</p>
                </div>
            </div>
            <Navigation />
        </div>

    )
}

export default About;

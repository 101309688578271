import React, { Component } from 'react';
import Navigation from "../../navigation/navigation";
import {withRouter, Link} from 'react-router-dom';
import Accordion from '../../../components/Accordion';

class Maintien extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            infos: false
        }
        
    }

    render() { 

        const panels = [
            {
                label: 'Maintenir une PEP suffisante',
                content:
                    <div>
                        <p>Plusieurs outils existent pour aider le clinicien à régler un niveau de pression expiratoire positive (PEP) suffisant afin de limiter le risque de dérecrutement. Chacun des outils ci-dessous est décrit en détails dans la fiche pédagogique qui lui est consacrée. On peut rappeler brièvement :</p>
                        <br/>
                        <ul>
                            <li>Stress index (SI) : l’objectif est de titrer la PEP pour maintenir un SI compris entre 0,9 et 1,1. De nombreux ventilateurs ont automatisé le calcul du SI. Sinon, une analyse de la forme de la courbe de pression des voies aériennes (P<sub>aw</sub>) en fonction du temps peut aider à détecter un dérecrutement au cours de l’expiration avec recrutement pendant l’insufflation (aspect convexe vers le haut de la courbe). </li>
                            <li>Pression œsophagienne : l’objectif est de titrer la PEP afin de maintenir une pression transpulmonaire expiratoire (P<sub>L,e</sub>) supérieure à 0 cmH<sub>2</sub>O. La P<sub>L,e</sub> correspond à la différence entre la PEP totale et la pression œsophagienne recueillie au cours d’une pause télé-expiratoire.</li>
                        </ul>
                    </div>
            },
            {
                label: 'Préférer la position assise',
                content:
                    <div>
                        <p>En position allongée, l’expansion de la cage thoracique est limitée en raison de la pression exercée par l’abdomen. Maintenir le patient en position proclive limite l’effet de la pression abdominale sur le thorax et permet une redistribution de la ventilation vers les zones postéro-basales, au contact du diaphragme<sup>1,2</sup>.</p>
                        <br/>
                        <p>Richard <i>et al</i>. ont évalué l’impact d’un positionnement assis (tronc surélevé à 45° et jambes pendantes à 45°) chez 16 patients en SDRA. Chez ces patients, on constatait une augmentation significative du volume de fin d’expiration traduisant un recrutement alvéolaire<sup>1,3</sup>. Une amélioration de l’oxygénation était constatée chez 68% des patients.</p>
                        <br/>
                        <p>A noter, la position demi-assise (tronc à 45° et jambe à 0°) pourrait contribuer à une diminution de la compliance pulmonaire en raison d’une augmentation de la pression abdominale, et devrait donc être évitée<sup>4</sup>.</p>
                    </div>
            },
            {
                label: 'Diminuer la fraction inspirée en oxygène',
                content:
                    <div>
                        <p>L’azote est le composant indispensable à la stabilité du volume alvéolaire. Ainsi, la ventilation sous oxygène pure (F<sub>i</sub>O<sub>2</sub> 1) entraine un risque de collapsus alvéolaire à l’origine d’atélectasies de dénitrogénation (ou d’absorption). Celles-ci surviennent préférentiellement dans les territoires à bas rapport ventilation/perfusion et peuvent aggraver le shunt intrapulmonaire<sup>5,6</sup>.</p>
                        <br/>
                        <p>Même si le niveau de preuve est faible, on peut éviter les F<sub>i</sub>O<sub>2</sub> élevées et tolérer une saturation artérielle en oxygène comprise entre 88 et 92%<sup>7</sup>.</p>
                    </div>
            },
            {
                label: 'Clamper la sonde d\'intubation',
                content:
                    <div>
                        <p>A l’arrivée du patient dans le service de réanimation ou lors de son transfert au scanner, il est nécessaire de déconnecter le patient pour effectuer un changement de ventilateur. Ceci entraine une chute brutale de la P<sub>aw</sub> pour atteindre la pression atmosphérique, aboutissant au dérecrutement alvéolaire<sup>8</sup>. La déconnection a aussi des conséquences sur la perfusion pulmonaire avec un risque d’insuffisance ventriculaire gauche (en raison de l’augmentation brutale de la précharge du ventricule gauche)<sup>9</sup>.</p>
                        <br/>
                        <p>Turbil <i>et al</i>. ont récemment montré que le clampage de la sonde d’intubation à l’aide de clamps métalliques utilisés pour les circuits d’ECMO permettait de limiter la baisse de P<sub>aw</sub>. L’utilisation de clamps métalliques simples ou en plastique était moins efficace<sup>10</sup>.</p>
                        <br/>
                        <p>La chute de la P<sub>aw</sub> est aussi proportionnelle à la durée de déconnexion. Aussi, les auteurs insistent sur l’importance de réaliser des déconnexions les plus courtes possibles (idéalement inférieur à 5 secondes)<sup>10</sup>.</p>
                    </div>
            },
            {
                label: 'Utiliser un système clos',
                content:
                    <div>
                        <p>Les aspirations endo-trachéales à l’aide d’un système clos ne nécessitent pas l’ouverture du circuit. En comparaison avec les aspirations classiques (avec ouverture du circuit), la P<sub>aw</sub> est donc maintenue au cours de l’aspiration ce qui limite le risque de dérecrutement<sup>11-16</sup>. </p>
                        <br/>
                        <p>L’augmentation transitoire de la P<sub>aw</sub> au cours de l’aspiration (en mode barométrique, régler la pression inspiratoire maximale à 40 cmH<sub>2</sub>O) permet de minimiser le risque de dérecrutement<sup>17</sup>.</p>
                    </div>
            },
            {
                label: 'Limiter les nébulisations et les fibroscopies bronchiques',
                content:
                    <div>
                        <p>En favorisant la survenue de fuites sur le circuit, la réalisation d’aérosols pourrait augmenter le risque de dérecrutement. De même, la réalisation d’une fibroscopie bronchique avec lavage broncho-alvéolaire induit une perte d’aération, partiellement réversible après la ré-aspiration du liquide de lavage<sup>18</sup>. Ainsi, il convient d’évaluer le rapport bénéfice/risque de chaque procédure nécessitant l’ouverture du circuit respiratoire.</p>
                    </div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Richard J-CM, Maggiore SM, Mancebo J, Lemaire F, Jonson B, Brochard L. Effects of vertical positioning on gas exchange and lung volumes in acute respiratory distress syndrome. <i>Intensive Care Med</i> 2006;32(10):1623–1626. <br/><br/>
                            2. Hoste EAJ, Roosens CDVK, Bracke S, et al. Acute effects of upright position on gas exchange in patients with acute respiratory distress syndrome. <i>J Intensive Care Med</i> 2005;20(1):43–49. <br/><br/>
                            3. Mezidi M, Guérin C. Effects of patient positioning on respiratory mechanics in mechanically ventilated ICU patients. <i>Annals of Translational Medicine</i> 2018;6(19):384. <br/><br/>
                            4. Dellamonica J, Lerolle N, Sargentini C, et al. Effect of different seated positions on lung volume and oxygenation in acute respiratory distress syndrome. <i>Intensive Care Med</i> 2013;39(6):1121–1127.  <br/><br/>
                            5. Lumb AB. Just a little oxygen to breathe as you go off to sleep…is it always a good idea? <i>BJA: British Journal of Anaesthesia</i> 2007;99(6):769–771. <br/><br/>
                            6. Koo C-H, Park EY, Lee SY, Ryu J-H. The Effects of Intraoperative Inspired Oxygen Fraction on Postoperative Pulmonary Parameters in Patients with General Anesthesia: A Systemic Review and Meta-Analysis. <i>J Clin Med</i> 2019;8(5):583.<br/><br/>
                            7. Aboab J, Jonson B, Kouatchet A, Taille S, Niklason L, Brochard L. Effect of inspired oxygen fraction on alveolar derecruitment in acute respiratory distress syndrome. <i>Intensive Care Med</i> 2006;32(12):1979–1986. <br/><br/>
                            8. Suh GY, Koh Y, Chung MP, et al. Repeated derecruitments accentuate lung injury during mechanical ventilation. <i>Crit Care Med</i> 2002;30(8):1848–1853. <br/><br/>
                            9. Katira BH, Engelberts D, Otulakowski G, et al. Abrupt Deflation after Sustained Inflation Causes Lung Injury. <i>Am J Respir Crit Care Med</i> 2018;198(9):1165–1176. <br/><br/>
                            10. Turbil E, Terzi N, Schwebel C, Cour M, Argaud L, Guérin C. Does endo-tracheal tube clamping prevent air leaks and maintain positive end-expiratory pressure during the switching of a ventilator in a patient in an intensive care unit? A bench study. <i>PLoS One</i> 2020;15(3):e0230147.<br/><br/>
                            11. Pogson D, Shirley P, Connolly E, Johnston S. Closed system endotracheal suctioning maintains lung volume during volume controlled mechanical ventilation. <i>Intensive Care Med</i> 2002;28(2):222–222. <br/><br/>
                            12. Caramez MP, Schettino G, Suchodolski K, et al. The Impact of Endotracheal Suctioning on Gas Exchange and Hemodynamics During Lung-Protective Ventilation in Acute Respiratory Distress Syndrome. <i>Respir Care</i> 2006;51(5):497–502. <br/><br/>
                            13. Cereda M, Villa F, Colombo E, Greco G, Nacoti M, Pesenti A. Closed system endotracheal suctioning maintains lung volume during volume-controlled mechanical ventilation. <i>Intensive Care Med</i> 2001;27(4):648–654. <br/><br/>
                            14. Reissmann H, Böhm SH, Suárez-Sipmann F, et al. Suctioning through a double-lumen endotracheal tube helps to prevent alveolar collapse and to preserve ventilation. <i>Intensive Care Med</i> 2005;31(3):431–440. <br/><br/>
                            15. Lindgren S, Odenstedt H, Olegård C, Söndergaard S, Lundin S, Stenqvist O. Regional lung derecruitment after endotracheal suction during volume- or pressure-controlled ventilation: a study using electric impedance tomography. <i>Intensive Care Med</i> 2007;33(1):172–180.<br/><br/>
                            16. Maggiore SM, Iacobone E, Zito G, Conti C, Antonelli M, Proietti R. Closed versus open suctioning techniques. <i>Minerva Anestesiol</i> 2002;68(5):360–364. <br/><br/>
                            17. Maggiore SM, Lellouche F, Pigeot J, et al. Prevention of endotracheal suctioning-induced alveolar derecruitment in acute lung injury. <i>Am J Respir Crit Care Med</i> 2003;167(9):1215–1224.<br/><br/>
                            18. Frerichs I, Dargaville PA, Rimensberger PC. Regional pulmonary effects of bronchoalveolar lavage procedure determined by electrical impedance tomography. <i>Intensive Care Med Exp</i> 2019;7(1):11.<br /><br />
                        </p>
                </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{pathname: "/pep"}} className="back" />
                        <span className="title">Maintien du recrutement alvéolaire</span>
                        {/* <span onClick={() => this.setState({ infos: (this.state.infos)? false : true})} id="more" className="info">Informations</span> */}
                    </h1>
                </div>
                <Accordion panels={ panels } />
            </div>
            {/* <div id="info-bloc" className={(this.state.infos)? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos)? false : true})} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={ panels } />
                </div>
            </div> */}
            <Navigation state={this.state} />
        </div>);
    }
}
 
export default withRouter(Maintien);
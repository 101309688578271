import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import picto_tools from '../../css/img/svg/outils.svg';
import picto_export from '../../css/img/svg/exporter.svg';
import picto_about from '../../css/img/svg/a-propos.svg';
import picto_profil from '../../css/img/svg/profil.svg';

function getType(path) {
    let type = "";
    switch (path) {
        case "/tools":
            type = "tools";
            break;
        case "/export":
            type = "export";
            break;
        case "/about":
        case "/team":
        case "/formules":
            type = "about";
            break;
        case "/profile":
            type = "profile";
            break;
        default:
            type = "tools";
    }
    return type;
}

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            badge: false
        }
    }

    displayExportBadge() {
        let value = 4; // tools, disclamer_popup, userType, survey_popup
        if (localStorage.getItem("pwa_popup_display") !== null)
            value++;
        if (localStorage.getItem("export") !== null)
            value++;
        if (localStorage.getItem("i18nextLng") !== null)
            value++;

        if (localStorage.length > value && !this.state.badge)
            this.setState({ badge: true });
        else if (localStorage.length <= value && this.state.badge)
            this.setState({ badge: false });
    }
    
    componentDidMount() {
        this.displayExportBadge();
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.displayExportBadge();
        }, 100);
    }

    onClick() {
        if(window.location.pathname === "/export")
            document.getElementsByClassName('wrapper-app')[0].style.overflow = "auto";
    }

    render() {
        return (
            <div className="sticky-bottom">
                <nav>
                    <ul>
                        <li>
                            <Link to={{ pathname: "/tools", state: this.state }} onClick={() => {this.onClick();}} className={(getType(window.location.pathname) === "tools") ? 'actif' : ''}>
                                <img src={picto_tools} alt="" width="27" height="27" />
                                <span>Outils</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={{ pathname: "/export", state: this.state }} onClick={() => {this.onClick();}} className={`${this.state.badge ? 'badge' : ''} + ${getType(window.location.pathname) === "export" ? 'actif' : ''}`}>
                                <img src={picto_export} alt="" width="27" height="27" />
                                <span>Exporter</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={{ pathname: "/about", state: this.state }} onClick={() => {this.onClick();}} className={(getType(window.location.pathname) === "about") ? 'actif' : ''}>
                                <img src={picto_about} alt="" width="27" height="27" />
                                <span>A propos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={{ pathname: "/profile", state: this.state }} onClick={() => {this.onClick();}} className={(getType(window.location.pathname) === "profile") ? 'actif' : ''}>
                                <img src={picto_profil} alt="" width="27" height="27" />
                                <span>Profil</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default withRouter(Navigation);

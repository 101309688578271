import React, { Component } from 'react';
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';

class Pep extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onSubmit(path) {
        this.props.history.push(path);
    }

    render() {
        return (<div>

            <div className="content">

                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/tools" }} className="back" />
                        <span className="title">Niveau de PEP</span>
                        {/* <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span> */}
                    </h1>
                </div>
                <div className="inner-content">
                    <nav id="menu">
                        <ul>
                            <li><Link to={{ pathname: "/potentiel" }}>Potentiel de recrutement</Link></li>
                            <li><Link to={{ pathname: "/niveauPep" }}>Quel niveau de PEP ?</Link></li>
                            <li><Link to={{ pathname: "/pepIntrinseque" }}>Gestion de la PEP intrinsèque</Link></li>
                            <li><Link to={{ pathname: "/fermeture" }}>Occlusion des voies aériennes</Link></li>
                            <li><Link to={{ pathname: "/maintien" }}>Maintien du recrutement</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <Navigation />
        </div>
        );
    }
}

export default withRouter(Pep);
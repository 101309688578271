import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Switch from '../../../components/switch';
import Navigation from "../../navigation/navigation";
import Accordion from '../../Accordion';
import Stepper from '../../Stepper';
import CustomSlider from '../../CustomSlider';
import CustomComponent from '../../../customComponent';

class MecaVentil extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            mecaventil: {
                sedation: false,
                curarisation: false,
                volcourant: "",
                debit: "",
                frequence: "",
                pressionCrete: "",
                pep: "",
                pressionPlateau: "",
                pressionMotrice: "",
                compliance: "",
                elastance: "",
                resistance: "",
            },
            infos: false
        };
    }

    runCalculs() {
        let pressionMotrice = Number(Math.round(this.state.mecaventil.pressionPlateau - this.state.mecaventil.pep)) || 0;
        let compliance = Number(Math.round(this.state.mecaventil.volcourant / (this.state.mecaventil.pressionPlateau - this.state.mecaventil.pep))) || 0;
        let elastance = Number(Math.round((this.state.mecaventil.pressionPlateau - this.state.mecaventil.pep) / (this.state.mecaventil.volcourant / 1000))) || 0;
        let resistance = Number(Math.round((this.state.mecaventil.pressionCrete - this.state.mecaventil.pressionPlateau) / (this.state.mecaventil.debit / 60))) || 0;

        this.setState(prevState => ({
            mecaventil: {
                ...prevState.mecaventil,
                pressionMotrice,
                compliance,
                elastance,
                resistance
            }
        }));
    }


    componentDidMount() {
        let mecaventil = this.loadStorageValues('mecaventil', this.state.mecaventil);

        if (JSON.stringify(mecaventil) !== JSON.stringify(this.state.mecaventil))
            this.setState({
                mecaventil: mecaventil
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('mecaventil', JSON.stringify(this.state.mecaventil));
    }

    reset() {
        this.setState({
            mecaventil: {
                sedation: false,
                curarisation: false,
                volcourant: "",
                debit: "",
                frequence: "",
                pressionCrete: "",
                pep: "",
                pressionPlateau: "",
                pressionMotrice: "",
                compliance: "",
                elastance: "",
                resistance: "",
            }
        },
            () => {
                localStorage.removeItem('mecaventil');
            });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>
                            <Link to={{ pathname: "/tools" }} className="back" />
                            <span className="title">Mécanique ventilatoire</span>
                            <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                        </h1>
                    </div>
                    <fieldset>
                        <div className="form-group">
                            <label>Sédation</label>
                            <Switch
                                name="sedation"
                                className="switch3"
                                checked={this.state.mecaventil.sedation}
                                onChange={(e) => this.handleInputChange(e, null, 'mecaventil')}
                            />
                        </div>
                        <div className="form-group">
                            <label>Curarisation</label>
                            <Switch
                                name="curarisation"
                                className="switch3"
                                checked={this.state.mecaventil.curarisation}
                                onChange={(e) => this.handleInputChange(e, null, 'mecaventil')}
                            />
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="center">Paramètres affichés</legend>
                        <CustomSlider
                            title="Volume courant"
                            unit={<div>mL</div>}
                            name="volcourant"
                            handle={(e, val) => this.handleInputChange(e, val, 'mecaventil')}
                            value={this.state.mecaventil.volcourant}
                            min="1" max="800"
                        />
                        <CustomSlider
                            title="Débit inspiratoire"
                            unit={<div>L/min</div>}
                            name="debit"
                            handle={(e, val) => this.handleInputChange(e, val, 'mecaventil')}
                            value={this.state.mecaventil.debit}
                            min="1" max="100"
                        />
                        <Stepper
                            title="Fréquence respiratoire"
                            unit={<div>c/min</div>}
                            name="frequence"
                            handle={(e) => this.handleInputChange(e, null, 'mecaventil')}
                            value={this.state.mecaventil.frequence}
                            min="1" max="50"
                        />
                    </fieldset>
                    <fieldset>
                        <legend className="center">Paramètres mesurés</legend>
                        <Stepper
                            title="Pression crête"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pressionCrete"
                            handle={(e) => this.handleInputChange(e, null, 'mecaventil')}
                            value={this.state.mecaventil.pressionCrete}
                            min="1" max="100"
                        />
                        <Stepper
                            title="PEP totale"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pep"
                            handle={(e) => this.handleInputChange(e, null, 'mecaventil')}
                            value={this.state.mecaventil.pep}
                            min="1" max="30"
                        />
                        <Stepper
                            title="Pression plateau"
                            unit={<div>cmH<sub>2</sub>O</div>}
                            name="pressionPlateau"
                            handle={(e) => this.handleInputChange(e, null, 'mecaventil')}
                            value={this.state.mecaventil.pressionPlateau}
                            min="1" max="60"
                        />
                    </fieldset>

                    <fieldset>
                        {/* <legend className="center">Résultats</legend> */}
                        <div className="form-group">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p>Pression motrice</p>
                                            <p className="result">
                                                <strong
                                                    style={(this.state.mecaventil.pressionMotrice >= 15) ? { color: '#FF7815' } : {}}
                                                >
                                                    {this.state.mecaventil.pressionMotrice}
                                                </strong>
                                            </p>
                                            <p className="blue" style={(this.state.mecaventil.pressionMotrice >= 15) ? { color: '#FF7815' } : {}}>
                                                <small>cmH<sub>2</sub>O</small>
                                            </p>
                                        </div>
                                        <div className="col">
                                            <p>Compliance, rs</p>
                                            <p className="result"><strong>{this.state.mecaventil.compliance}</strong></p>
                                            <p className="blue"><small>mL/cmH<sub>2</sub>O</small></p>
                                        </div>
                                    </div>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p>Elastance, rs</p>
                                            <p className="result"><strong>{this.state.mecaventil.elastance}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>O/L</small></p>
                                        </div>
                                        <div className="col">
                                            <p>Résistance, rs</p>
                                            <p className="result"><strong>{this.state.mecaventil.resistance}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>O/L/s</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div className="wrap-validation">
                        <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                        <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                    </div>
                </div>
                <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                    <div id="info-bloc-inner">
                        <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                        <h2>Définitions</h2>
                        <Accordion panels={t("panels", { returnObjects: true }).mecaventil} />
                    </div>
                </div>
                <Navigation />
            </div>);
    }
}

export default withTranslation()(withRouter(MecaVentil));
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { withTranslation } from 'react-i18next';
import logo from '../../css/img/svg/logo.svg'


class Home extends Component {

    onSubmit(type) {
        localStorage.setItem('userType', type);
    }

    render() {
        const { t } = this.props;
        return (
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{ duration: 1 }}
            >
                <div className="content">
                    <div className="content-inner home">
                        <img src={logo} alt="" width="195" height="169" />
                        <h1>{t('Choisissez votre profil')}</h1>
                        <Link to={{ pathname: "/tools" }} onClick={() => this.onSubmit(0)} className="btn primary">{t('Standard')}</Link>
                        <Link to={{ pathname: "/tools" }} onClick={() => this.onSubmit(1)} className="btn secondary">{t('Avancé')}</Link>
                    </div>
                </div>
            </motion.div>
        )
    }
}
export default withTranslation()(Home);


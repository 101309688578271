import React, { Component } from 'react';
import Navigation from "../navigation/navigation";
import NavAbout from "../navigation/navAbout";
import Accordion from '../../components/Accordion';

class Formules extends Component {
  render() {
    const panels = [
      {
        label: 'Paramètres de base',
        content: <div><h3>Pression des voies aériennes (<i>airway pressure</i>, P<sub>aw</sub>)</h3><p>P<sub>aw</sub> = P<sub>pl</sub> + P<sub>alv</sub></p>
          <h3>Pression alvéolaire (<i>alveolar pressure</i>P<sub>alv</sub>)</h3><p>P<sub>alv,i</sub> = P<sub>plat</sub> <br/>P<sub>alv,e</sub> = PEP<sub>t</sub></p>
          <h3>Pression pleurale (<i>pleural pressure</i>, P<sub>pl</sub>)<br/>Pression œsophagienne (<i>esophageal pressure</i>, P<sub>es</sub>)</h3><p>P<sub>pl,i</sub> ≈ P<sub>es,i</sub> <br/>P<sub>pl,e</sub> = P<sub>es,e</sub></p>
          <h3>Pression expiratoire positive totale (PEP<sub>t</sub>)</h3>
          <p>PEP<sub>t</sub> = PEP<sub>extrinsèque</sub> + PEP<sub>intrinsèque</sub><br/>Où PEP<sub>e</sub> = PEP extrinsèque et PEP<sub>i</sub> = PEP intrinsèque</p>
          </div>
      },
      {
        label: 'Paramètres complexes',
        content: <div><h3>Pression motrice du système respiratoire (<i>driving pressure</i>, DP)</h3><p>DP = P<sub>plat</sub> - PEP<sub>t</sub><br/>DP = V<sub>t</sub> / C<sub>rs</sub></p>
          <h3>Pression transpulmonaire expiratoire directe/absolue (P<sub>L,e</sub> direct)</h3><p>P<sub>L,e</sub> direct = PEP<sub>t</sub> - P<sub>es,e</sub></p>
          <h3>Pression transpulmonaire inspiratoire dérivée du ratio des élastances (P<sub>L,i</sub> el-related)</h3><p>P<sub>L,i</sub> el-related = P<sub>plat</sub> - [P<sub>plat</sub> x (E<sub>cw</sub>/E<sub>rs</sub>)] = P<sub>plat</sub> x (E<sub>L</sub>/E<sub>rs</sub></p>
          <h3>Pression motrice transpulmonaire (<i>transpulmonary pressure</i>, DP<sub>L</sub>)</h3><p>DP<sub>L</sub> = DP - (P<sub>es,i</sub> - P<sub>es,e</sub>) = (P<sub>plat</sub> - PEP<sub>t</sub>) - (P<sub>es,i</sub> - P<sub>es,e</sub>)</p>
        </div>
      },
      {
        label: 'Compliance',
        content: <div><p>Les mesures du système respiratoire peuvent être statiques (mesurées au cours d'un 
          débit nul, en effectuant une pause respiratoire ou expiratoire) ou dynamiques (mesurées sans faire de pause). 
          La compliance statique du système respiratoire (C<sub>rs</sub>) est fortement corrélé avec le volume du baby-lung</p>
          <h3>Les compliances du système respiratoire ne s’additionnent pas, au contraire des élastances (cf. infra). 
          Elles s'apparentent à un montage en parallèle. On peut donc écrire: </h3><p>C<sub>rs</sub> = [(Cl x Cw) / (Cl + Cw)]</p>
          <h3>Compliance du système respiratoire (C<sub>rs</sub>)</h3><p>C<sub>rs</sub> = V<sub>t</sub> / (P<sub>plat</sub> - PEP<sub>t</sub>)<br/>C<sub>rs</sub> = V<sub>t</sub> / DP<br/> 
          Chez le sujet sain C<sub>rs</sub>= 70-80 mL/cmH<sub>2</sub>O</p>
          <h3>Compliance du poumon (<i>lung compliance</i>, C<sub>L</sub>)</h3><p>C<sub>L</sub> = V<sub>t</sub> / [(P<sub>plat</sub> - PEP<sub>t</sub>) - (P<sub>es,i</sub> - P<sub>es,e</sub>)]<br/>
          C<sub>L</sub> = V<sub>t</sub> / DP<sub>L</sub><br/>Chez le sujet sain C<sub>L</sub> = 110-130 mL/cmH<sub>2</sub>O</p>
          <h3>Compliance de la paroi thoracique (<i>chest wall compliance</i>, C<sub>cw</sub>)</h3><p>C<sub>cw</sub> = V<sub>t</sub> / (P<sub>es,i</sub> - P<sub>es,e</sub>)<br/>
          Chez le sujet sain C<sub>cw</sub> = 180-210 mL/cmH<sub>2</sub>O</p></div>
      },
      {
        label: 'Elastance',
        content: <div><h3>Elastance du système respiratoire (E<sub>rs</sub>)</h3><p>
          E<sub>rs</sub> = E<sub>L</sub> + E<sub>cw</sub><br/>
          E<sub>rs</sub> = (P<sub>plat</sub> - PEP<sub>t</sub>) / V<sub>t</sub><br/>E<sub>rs</sub> = DP / V<sub>t</sub><br/>Chez le sujet sain E<sub>rs</sub> = 10-15 cmH<sub>2</sub>O/L
          </p>
          <h3>Elastance du poumon (E<sub>L</sub>)</h3>
          <p>E<sub>L</sub> = E<sub>rs</sub> - E<sub>cw</sub><br/>
          E<sub>L</sub> = [(P<sub>plat</sub> - PEP<sub>t</sub>) - (P<sub>es,i</sub> - P<sub>es,e</sub>)] / V<sub>t</sub><br/>Chez le sujet sain E<sub>L</sub> = 8-9 cmH<sub>2</sub>O/L</p>
          <h3>Elastance de la paroi thoracique (E<sub>cw</sub>)</h3>
          <p>E<sub>cw</sub> = E<sub>rs</sub> - E<sub>L</sub><br/>
          E<sub>cw</sub> = (P<sub>es,i</sub> - P<sub>es,e</sub>) / V<sub>t</sub><br/>Chez le sujet sain E<sub>cw</sub> = 4-5 cmH<sub>2</sub>O/L</p></div>
      },
      {
        label: 'Espace mort',
        content: <div><p>Le détail de chacune des équations ci-dessous est disponible dans 
          la fiche explicative des différents outils de la section "espace mort".</p>
          <h3>V<sub>d</sub>/V<sub>t</sub> Bohr</h3>
          <p>V<sub>d</sub>/V<sub>t</sub> Bohr = (P<sub>A</sub>CO<sub>2</sub> - P<sub>E</sub>CO<sub>2</sub>) / P<sub>A</sub>CO<sub>2</sub><br/>Qui peut aussi s'écrire:
          <br/>V<sub>d</sub>/V<sub>t</sub> Bohr = 1 - (P<sub>E</sub>CO<sub>2</sub> / P<sub>A</sub>CO<sub>2</sub>)<br/>où P<sub>A</sub>CO<sub>2</sub> = pression partielle alvéolaire en CO<sub>2</sub> <br/>
          où P<sub>E</sub>CO<sub>2</sub> = pression partielle moyenne expirée en CO<sub>2</sub></p>
          <h3>V<sub>d</sub>/V<sub>t</sub> phys</h3>
          <p>V<sub>d</sub>/V<sub>t</sub> phys= (P<sub>a</sub>CO<sub>2</sub> - P<sub>E</sub>CO<sub>2</sub>) / P<sub>a</sub>CO<sub>2</sub>
          <br/>Qui peut aussi sécrire:<br/>V<sub>d</sub>/V<sub>t</sub> phys= 1 - (P<sub>E</sub>CO<sub>2</sub> / P<sub>a</sub>CO<sub>2</sub>)
          <br/>où P<sub>a</sub>CO<sub>2</sub> = pression partielle artérielle en CO<sub>2</sub> <br/>où P<sub>E</sub>CO<sub>2</sub> = pression partielle moyenne expirée en CO<sub>2</sub></p>
          <h3>Alveolar dead-space fraction</h3><p>AVDSf = (P<sub>a</sub>CO<sub>2</sub> - EtCO<sub>2</sub>) / P<sub>a</sub>CO<sub>2</sub> <br/>Qui peut aussi s'écrire:
          AVDSf = 1 - (EtCO<sub>2</sub> / P<sub>a</sub>CO<sub>2</sub>)<br/>Où P<sub>a</sub>CO<sub>2</sub> = pression partielle artérielle en CO<sub>2</sub> <br/>Où EtCO<sub>2</sub> = pression partielle en CO<sub>2</sub> en fin d'expiration</p>
          <h3>Ventilatory ratio</h3><p>VR = (VM x P<sub>a</sub>CO<sub>2</sub>) / (VM prédite x P<sub>a</sub>CO<sub>2</sub> idéale)
          Où VM = ventilation minute<br/>Où P<sub>a</sub>CO<sub>2</sub> = pression partielle artérielle en CO<sub>2</sub><br/>Où VM prédite = poids prédit par la taille x 100
          <br/>Où P<sub>a</sub>CO<sub>2</sub> idéale = 37,5 mmHg</p></div>
      },
      {
        label: 'Shunt intrapulmonaire',
        content: <div><p>Le détail de chacune des équations ci-dessous est disponible dans la fiche 
          explicative des différents outils de la section "shunt intrapulmonaire".</p>
          <h3>Shunt intrapulmonaire (Q<sub>s</sub>/Q<sub>t</sub>)</h3><p>Q<sub>s</sub>/Q<sub>t</sub> = (C<sub>C</sub>O<sub>2</sub> - C<sub>a</sub>O<sub>2</sub>) / (C<sub>C</sub>O<sub>2</sub> - C<sub>v</sub>O<sub>2</sub>)<br/>Où C<sub>a</sub>O<sub>2</sub> = concentration artérielle en oxygène<br/>
          Où C<sub>v</sub>O<sub>2</sub> = concentration veineuse mêlée en oxygène<br/>Où C<sub>C</sub>O<sub>2</sub> = concentration capillaire en oxygène</p>
          <h3>Concentration en oxygène</h3><p>C<sub>a</sub>O<sub>2</sub> = [(Hb x 1,34) x (S<sub>a</sub>O<sub>2</sub>)] + (P<sub>a</sub>O<sub>2</sub> x 0,0031)<br/>
          C<sub>v</sub>O<sub>2</sub> = [(Hb x 1,34) x (S<sub>v</sub>O<sub>2</sub>)] + (P<sub>v</sub>O<sub>2</sub> x 0,0031)<br/>C<sub>C</sub>O<sub>2</sub> = [(Hb x 1,34) x (S<sub>C</sub>O<sub>2</sub>)] + (P<sub>A</sub>O<sub>2</sub> x 0,0031)<br/>
          Où Hb = hémoglobine en g/dL<br/>Où S<sub>x</sub>O<sub>2</sub> = saturation en oxygène en %<br/>Où P<sub>x</sub>O<sub>2</sub> = pression en oxygène en mmHg</p></div>
      },
    ];
    return (
      <div>
        <div className="content">
          <NavAbout />
          <div id="wrap-tabs">
            <Accordion panels={panels} />
          </div>
        </div>
        <Navigation />
      </div>
    )
  }
}

export default Formules;


import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Navigation from "../navigation/navigation";
import Switch from '../../components/switch';
import Tools from '../dashboardTools/dashboardTools';


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: 0,
            tools: [
                {
                    id: 1,
                    nom: "Diagnostic",
                    path: "/diagnostic",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 2,
                    nom: "Volume courant",
                    path: "/volcourant",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 3,
                    nom: "Mécanique ventilatoire",
                    path: "/mecaventil",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 4,
                    nom: "Niveau de PEP",
                    path: "/pep",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 5,
                    nom: "Curarisation",
                    path: "/curarisation",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 6,
                    nom: "Décubitus ventral",
                    path: "/decubitus",
                    userType: 0,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 7,
                    nom: "Manoeuvre de recrutement",
                    path: "/shunt",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 8,
                    nom: "Pression oesophagienne",
                    path: "/pressionoeso",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 9,
                    nom: "Espace mort",
                    path: "/espmort",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                },
                {
                    id: 10,
                    nom: "Shunt intrapulmonaire",
                    path: "/shunt",
                    userType: 1,
                    visible: true,
                    class: "mosaic-item",
                }
            ]
        }
    }


    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let tools = [...this.state.tools];
        let tool = { ...tools[name] };
        tool.visible = value;
        tools[name] = tool;

        this.setState({ tools });
    }

    handleChangeUserType = (event) => {
        const target = event.target;
        const value = target.checked;

        if (value)
        {
            localStorage.setItem("userType", 1);
            this.setState({ userType: 1});
        }
        else {
            localStorage.setItem("userType", 0);
            this.setState({ userType: 0});
        }
    }

    componentDidMount() {
        try {
            let tools = JSON.parse(localStorage.getItem('tools'));
            if(tools !== null)
                this.setState({
                    tools
                });
        } catch (error) {
            console.log("tools is undefined");
        }
        
        this.setState({
            userType:  Number(localStorage.getItem("userType")) || 0
        });
    }

    componentDidUpdate() {
        localStorage.setItem('tools', JSON.stringify(this.state.tools));
    }

    render() {
        const list = this.state.tools.map((tool, index) => {
            if (this.state.userType >= tool.userType)
                return (
                    <div className="switch-bloc-item" key={index} style={(tool.visible)? {} : {"opacity": 0.6}}>
                        <img src={Tools.getToolsPictoById(tool.id)} width="40" height="40" alt="" />
                        <label>{tool.nom}</label>
                        <Switch
                            className="switch2"
                            name={index}
                            checked={tool.visible}
                            onChange={this.handleInputChange}
                        />
                    </div>
                )
            else
                return ""
        });

        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>Profil</h1>
                    </div>
                    <div className="switch-wrapper">
                        <button className={(!this.state.userType)? "active" : ""}>Standard</button>
                        <Switch
                            className="mx-15 switch3"
                            checked={this.state.userType}
                            onChange={this.handleChangeUserType}
                        />
                        <button className={(this.state.userType)? "active" : ""}>Avancé</button>
                    </div>
                    <h2>Outils disponibles</h2>
                    <div className="inner-content">
                        <div className="switch-bloc">
                            {list}
                        </div>
                    </div>
                </div>
                <Navigation />
            </div>
        )
    }
}

export default withTranslation()(Profile);


import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import Switch from '../../../components/switch';
import Stepper from '../../Stepper';
import CustomSlider from '../../CustomSlider';
import CustomComponent from '../../../customComponent';


class Capnographie extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            capnographie: {
                avecPeco2: false,
                avecVco2: false,
                paco2AvecPeco2Connue: "",
                peco2Valeur: "",
                paco2AvecVco2: "",
                vco2: "",
                vm: "",
                pression: "760",
                espMortAvecPeco2Connue: "",
                espMortAvecVco2: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let espMortAvecPeco2Connue = this.getB1();
        let espMortAvecVco2 = this.getB4();
        this.setState(prevState => ({
            capnographie: {
                ...prevState.capnographie,
                espMortAvecPeco2Connue,
                espMortAvecVco2
            }
        }));
    }

    componentDidMount() {
        let capnographie = this.loadStorageValues('capnographie', this.state.capnographie);

        if (JSON.stringify(capnographie) !== JSON.stringify(this.state.capnographie))
            this.setState({
                capnographie: capnographie
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('capnographie', JSON.stringify(this.state.capnographie));
    }

    reset() {
        this.setState({
            capnographie: {
                avecPeco2: false,
                avecVco2: false,
                paco2AvecPeco2Connue: "",
                peco2Valeur: "",
                paco2AvecVco2: "",
                vco2: "",
                vm: "",
                pression: "760",
                espMortAvecPeco2Connue: "",
                espMortAvecVco2: ""
            }
        },
            () => {
                localStorage.removeItem('capnographie');
            });
    }

    getB1 = () => {
        const { paco2AvecPeco2Connue, peco2Valeur } = this.state.capnographie;
        return Math.round((paco2AvecPeco2Connue - peco2Valeur) / paco2AvecPeco2Connue * 100);
    }

    getB4 = () => {
        const { paco2AvecVco2, vco2, vm, pression } = this.state.capnographie;
        return Math.round(((paco2AvecVco2 - ((vco2 / (vm * 1000)) * (pression - 47))) / paco2AvecVco2) * 100);
    }

    render() {

        const panels = [
            {
                label: <div>Calcul du V<sub>d</sub>/V<sub>t</sub> avec la P<sub>E</sub>CO<sub>2</sub></div>,
                content:
                    <div>
                        <p>
                            L'équation de Bohr (1891) permet une estimation l’espace mort (V<sub>d</sub>/V<sub>t</sub>). La principale difficulté est d'obtenir la pression alvéolaire en CO<sub>2</sub> (P<sub>A</sub>CO<sub>2</sub>). C'est pourquoi on lui préfère souvent l'équation d'Enghoff (1938) où la pression artérielle en CO<sub>2</sub> (P<sub>a</sub>CO<sub>2</sub>) est utilisée comme substitut à la P<sub>A</sub>CO<sub>2</sub><sup>1</sup>. En effet, dans un poumon idéal, la P<sub>a</sub>CO<sub>2</sub> est équivalente à la P<sub>A</sub>CO<sub>2</sub>. En réalité, la P<sub>A</sub>CO<sub>2</sub> est toujours inférieure à la P<sub>a</sub>CO<sub>2</sub>.
                        </p>
                            <p>V<sub>d</sub>/V<sub>t</sub> Bohr= (P<sub>A</sub>CO<sub>2</sub> - P<sub>E</sub>CO<sub>2</sub>) / P<sub>A</sub>CO<sub>2</sub><br /><br />
                            Qui peut aussi s'écrire:<br />
                            V<sub>d</sub>/V<sub>t</sub> Bohr = 1 - (P<sub>E</sub>CO<sub>2</sub> / P<sub>A</sub>CO<sub>2</sub>)
                        </p>
                        <p>V<sub>d</sub>/V<sub>t</sub> Engh = (P<sub>a</sub>CO<sub>2</sub> - P<sub>E</sub>CO<sub>2</sub>) /         P<sub>a</sub>CO<sub>2</sub><br /><br />
                            Qui peut aussi s'écrire:<br />
                            V<sub>d</sub>/V<sub>t</sub> Engh = 1 - (P<sub>E</sub>CO<sub>2</sub> / P<sub>a</sub>CO<sub>2</sub>)
                        </p>
                        <p>
                            La P<sub>E</sub>CO<sub>2</sub> correspond à la pression partielle moyenne expirée en CO<sub>2</sub>. Elle est mesurée à l'aide d'une capnographie volumétrique. D'autres techniques plus fastidieuses existent comme l'analyse des gaz à l'aide d'un sac collecteur de Douglas ou d'une calorimétrie indirecte<sup>2</sup>.
                        </p>
                    </div>
            },
            {
                label: 'Capnographie volumétrique',
                content:
                    <div>
                        <p>
                            La capnographie volumétrique mesure le CO<sub>2</sub> expiré en fonction du volume expiré. La courbe de capnographie volumétrique se décompose en 3 phases<sup>3</sup>:
                        </p>
                        <ul>
                            <li>
                                La phase 1 représente le gaz contenu dans les voies aériennes, où le CO<sub>2</sub> expiré est quasi-nul.
                            </li>
                            <li>La phase 2 représente le gaz contenu dans les alvéoles les plus proches des voies aériennes.</li>
                            <li>
                                La phase 3 correspond à l'expiration de gaz alvéolaire pur. La P<sub>E</sub>CO<sub>2</sub> correspond à la pression partielle moyenne expirée en CO<sub>2</sub> (ou pression partielle du CO<sub>2</sub> expiratoire mêlé).
                            </li>
                        </ul>
                    </div>
            },
            {
                label: 'Bibliographie',
                content:
                    <div>
                        <p>
                            1. Sinha P, Flower O, Soni N. Deadspace ventilation: a waste of breath! <i>Intensive Care Med</i> 2011;37(5):735–746. <br /><br />
                            2. Kallet RH, Daniel BM, Garcia O, Matthay MA. Accuracy of physiologic dead space measurements in patients with acute respiratory distress syndrome using volumetric capnography: comparison with the metabolic monitor method. <i>Respir Care</i> 2005;50(4):462–467. <br /><br />
                            3. 	Verscheure S, Massion PB, Verschuren F, Damas P, Magder S. Volumetric capnography: lessons from the past and current clinical applications. <i>Crit Care</i> 2016;20(1):184.
                    </p>
                    </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/espmort" }} className="back" />
                        <span className="title">Capnographie volumétrique</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres</legend>

                    <div className="form-group">
                        <label>Avec P<sub>E</sub>CO<sub>2</sub></label>
                        <Switch
                            name="avecPeco2"
                            className="switch3"
                            checked={this.state.capnographie.avecPeco2}
                            onChange={(e) => this.handleInputChange(e, null, 'capnographie')}
                        />
                    </div>

                    {this.state.capnographie.avecPeco2 && <div>

                        <Stepper
                            title="PaCO2"
                            unit={<div>mmHg</div>}
                            name="paco2AvecPeco2Connue"
                            handle={(e) => this.handleInputChange(e, null, 'capnographie')}
                            value={this.state.capnographie.paco2AvecPeco2Connue}
                            min="1" max="300"
                        />
                        <Stepper
                            title="PECO2"
                            unit={<div>mmHg</div>}
                            name="peco2Valeur"
                            handle={(e) => this.handleInputChange(e, null, 'capnographie')}
                            value={this.state.capnographie.peco2Valeur}
                            min="1" max="300"
                        />

                        <div className="form-group type-slider">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>V<sub>d</sub>/V<sub>t</sub></p>
                                    <div className="specific4-inner">
                                        <div className="col">
                                            {!isNaN(this.getB1()) ? <div>
                                                <p className="result"><strong>{this.getB1()}%</strong></p>
                                                <p className="blue">{this.getB1() > 30 ?
                                                    "Augmentation de l'espace mort !"
                                                    :
                                                    "Pas d'augmentation de l'espace mort !"
                                                }</p>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className="form-group">
                        <label>Avec VCO2</label>
                        <Switch
                            name="avecVco2"
                            className="switch3"
                            checked={this.state.capnographie.avecVco2}
                            onChange={(e) => this.handleInputChange(e, null, 'capnographie')}
                        />
                    </div>

                    {this.state.capnographie.avecVco2 && <div>
                        <Stepper
                            title="PaCO2"
                            unit={<div>mmHg</div>}
                            name="paco2AvecVco2"
                            handle={(e) => this.handleInputChange(e, null, 'capnographie')}
                            value={this.state.capnographie.paco2AvecVco2}
                            min="1" max="150"
                        />
                        <Stepper
                            title="VCO2"
                            unit={<div>mL/min</div>}
                            name="vco2"
                            handle={(e) => this.handleInputChange(e, null, 'capnographie')}
                            value={this.state.capnographie.vco2}
                            min="1" max="300"
                        />
                        <Stepper
                            title="VM"
                            unit={<div>L/min</div>}
                            name="vm"
                            handle={(e) => this.handleInputChange(e, null, 'capnographie')}
                            value={this.state.capnographie.vm}
                            min="1" max="20"
                            step="0.1"
                        />
                        <CustomSlider
                            title="Pression barométrique"
                            unit={<div>mmHg</div>}
                            name="pression"
                            handle={(e, val) => this.handleInputChange(e, val, 'capnographie')}
                            value={this.state.capnographie.pression}
                            min="760" max="1000"
                            step="10"
                        />
                        <div className="form-group type-slider">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>V<sub>d</sub>/V<sub>t</sub></p>
                                    <div className="specific4-inner">
                                        <div className="col">
                                            {!isNaN(this.getB4()) ? <div>
                                                <p className="result"><strong>{this.getB4()}%</strong></p>
                                                <p className="blue">{this.getB4() > 30 ?
                                                    "Augmentation de l'espace mort !"
                                                    :
                                                    "Pas d'augmentation de l'espace mort !"
                                                }</p>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(Capnographie);

import React, { Component } from 'react';
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';

class Shunt extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onSubmit(path) {
        this.props.history.push(path);
    }

    render() {

        const panels = [
            {
                label: 'Concept de ventilation/perfusion mismatch',
                content:
                    <div>
                        <p>Sous le terme <i>ventilation/perfusion</i> (V<sub>A</sub>/Q) <i>mismatch</i> est regroupé de nombreuses situations où il existe une non-concordance entre la ventilation et la perfusion pulmonaire. On distingue deux situations opposées<sup>1</sup>:</p>
                        <br/>
                        <ul>
                            <li>Une zone pulmonaire est ventilée mais n'est pas perfusée (le rapport V<sub>A</sub>/Q tend vers l’infini): c'est l'espace mort (V<sub>d</sub>/V<sub>t</sub>), principalement responsable de l'hypercapnie.</li>
                            <li>Une zone pulmonaire est perfusée mais n'est pas ventilée (le rapport V<sub>A</sub>/Q est égal à 0): c'est le shunt intrapulmonaire (Q<sub>s</sub>/Q<sub>t</sub>), principalement responsable de l'hypoxémie.</li>
                        </ul>
                        <br/>
                        <p>Entre ces deux états caricaturaux, il existe une multitude de situations dans lesquelles la ventilation excède la perfusion (on parle de rapport V<sub>A</sub>/Q augmenté) ou dans lesquelles la perfusion excède la ventilation (on parle de rapport V<sub>A</sub>/Q diminué).</p>
                    </div>
            },
            {
                label: 'Shunt intrapulmonaire au cours du SDRA',
                content:
                    <div>
                        <p>Chez les patients présentant une hypoxémie ne se corrigeant pas complétement après une augmentation de la F<sub>i</sub>O<sub>2</sub>, il convient de suspecter un shunt. Celui-ci est responsable d’une grande partie de l’hypoxémie constatée au cours du SDRA<sup>1</sup>. Il s’agit le plus fréquemment d’un shunt d’origine intrapulmonaire plutôt qu’intracardiaque.</p>
                        <br/>
                        <p>Le Q<sub>s</sub>/Q<sub>t</sub> représente la proportion du débit cardiaque distribué aux zones pulmonaires non-aérées. On y distingue deux composantes : le shunt anatomique (qui correspond principalement au drainage du sang artériel bronchique collecté dans les veines pulmonaires - sa valeur physiologique est d'environ 3-5%) et le shunt alvéolaire (aussi appelé physiologique, qui correspond à la perfusion de zones non ou mal ventilées). Ainsi en cas de shunt: (1) plus le Q<sub>s</sub>/Q<sub>t</sub> est important plus l’hypoxémie est profonde ; (2) l’augmentation de la F<sub>i</sub>O<sub>2</sub> a peu d’impact sur l’oxygénation du patient<sup>1</sup>.</p>
                    </div>
            },
            {
                label: 'Traitement non pharmacologique du shunt intrapulmonaire',
                content:
                    <div>
                        <p>La pression expiratoire positive (PEP) influence le Q<sub>s</sub>/Q<sub>t</sub>. Karbing <i>et al</i>. ont montré des réponses opposées à l’augmentation de la PEP dans le SDRA. Chez la majorité des patients, l’augmentation de la PEP est responsable d’une diminution du Q<sub>s</sub>/Q<sub>t</sub> en raison de la ré-aération du parenchyme pulmonaire (recrutement alvéolaire)<sup>2</sup>. La PEP peut également entrainer une diminution du débit cardiaque, elle-même associée à une diminution du Q<sub>s</sub>/Q<sub>t</sub> (relation shunt-débit)<sup>1</sup>.</p>
                        <br/>
                        <p>Parfois, l’augmentation de la PEP peut être délétère et entrainer une aggravation du Q<sub>s</sub>/Q<sub>t</sub>. Ceci peut être expliqué par une redistribution préférentielle du flux sanguin vers les zones dépendantes du poumon en réponse à la surdistention des territoires ventilés et l’augmentation des pressions intra-thoraciques<sup>2</sup>.</p>
                        <br/>
                        <p>La mise en décubitus ventral des patients les plus graves permet aussi de diminuer le Q<sub>s</sub>/Q<sub>t</sub>. Cette diminution s’explique majoritairement par le recrutement alvéolaire des zones postérieures dépendantes<sup>3</sup>.</p>
                    </div>

            },
            {
                label: 'Traitement pharmacologique du shunt intrapulmonaire',
                content:
                    <div>
                        <p>Le monoxyde d’azote inhalé (NO<sub>i</sub>) permet la dilatation des vaisseaux des alvéoles ventilées. Ceci a pour effet de redistribuer la perfusion pulmonaire vers les zones ventilées. En conséquence, il existe une meilleure adéquation entre la perfusion pulmonaire et la ventilation, donc une diminution du Q<sub>s</sub>/Q<sub>t</sub><sup>4</sup>. Son utilisation chez les patients souffrant de SDRA permet d’améliorer l’oxygénation mais n’a pas montré son efficacité sur la survie des patients<sup>5</sup>.</p>
                        <br/>
                        <p>La vasoconstriction pulmonaire hypoxique (VPH) est un mécanisme physiologique permettant de diminuer le Q<sub>s</sub>/Q<sub>t</sub>. Il s’agit d’un mécanisme complexe où la baisse de la pression alvéolaire en oxygène (P<sub>A</sub>O<sub>2</sub>) entraine une vasoconstriction locale permettant de rediriger la perfusion sanguine vers des zones pulmonaires mieux ventilées<sup>4,6</sup>.</p>
                        <br/>
                        <p>L’administration de bysmésylate d’almitrine a pour effet d’augmenter l’effet de la VPH et permet de diminuer le Q<sub>s</sub>/Q<sub>t</sub><sup>7</sup>. L’effet sur le devenir des patients en SDRA n’a cependant pu être définitivement établi<sup>8</sup>. En effet, chez les patients présentant une atteinte pulmonaire étendue, la VPH est généralisée ce qui entraine une augmentation de la pression artérielle pulmonaire sans pour autant améliorer le V<sub>A</sub>/Q <i>mismatch</i><sup>9</sup>.</p>
                    </div>

            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Radermacher P, Maggiore SM, Mercat A. Fifty Years of Research in ARDS. Gas Exchange in Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2017;196(8):964–984.<br/><br/> 
                            2. Karbing DS, Panigada M, Bottino N, et al. Changes in shunt, ventilation/perfusion mismatch, and lung aeration with PEEP in patients with ARDS: a prospective single-arm interventional study. <i>Critical Care</i> 2020;24(1):111. <br/><br/> 
                            3. Setten M, Plotnikow GA, Accoce M. Prone position in patients with acute respiratory distress syndrome. <i>Rev Bras Ter Intensiva</i> 2016;28(4):452–462. <br/><br/>  
                            4. Benzing A, Mols G, Brieschal T, Geiger K. Hypoxic Pulmonary Vasoconstriction in Nonventilated Lung Areas Contributes to Differences in Hemodynamic and Gas Exchange Responses to Inhalation of Nitric Oxide. <i>Anesthesiology</i> 1997;86(6):1254–1261.<br/><br/>  
                            5. Gebistorf F, Karam O, Wetterslev J, Afshari A. Inhaled nitric oxide for acute respiratory distress syndrome (ARDS) in children and adults. <i>Cochrane Database Syst Rev</i> 2016;(6):CD002787. <br/><br/>  
                            6. Naeije R, Brimioulle S. Physiology in medicine: importance of hypoxic pulmonary vasoconstriction in maintaining arterial oxygenation during acute respiratory failure. <i>Crit Care</i> 2001;5(2):67–71. <br/><br/>  
                            7. Reyes A, Roca J, Rodriguez-Roisin R, Torres A, Ussetti P, Wagner PD. Effect of almitrine on ventilation-perfusion distribution in adult respiratory distress syndrome. <i>Am Rev Respir Dis</i> 1988;137(5):1062–1067. <br/><br/>  
                            8. Payen D, Muret J, Beloucif S, et al. Inhaled Nitric Oxide, Almitrine Infusion, or Their Coadministration as a Treatment of Severe Hypoxemic Focal Lung Lesions. <i>Anesthesiology</i> 1998;89(5):1157–1165. <br/><br/>
                            9. Petersson J, Glenny RW. Gas exchange and ventilation–perfusion relationships in the lung. <i>European Respiratory Journal</i> 2014;44(4):1023–1041.
                        </p>
                    </div>
            }
        ];

        return (<div>

            <div className="content">

                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/tools" }} className="back" />
                        <span className="title">Shunt intrapulmonaire</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>
                <div className="inner-content">
                    <nav id="menu">
                        <ul>
                            <li><Link to={{ pathname: "/shuntSelonO2" }}>Qs/Qt selon le contenu en O2</Link></li>
                            <li><Link to={{ pathname: "/shuntSelonRatio" }}>Qs/Qt selon le ratio PaO2/FiO2</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>

        );
    }
}

export default withRouter(Shunt);

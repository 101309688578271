import { connect } from 'react-redux';
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import Popup from 'reactjs-popup';
import CustomComponent from './customComponent';
import Routes from './routes'
import Alert from './components/Alert';
//import { SW_INIT, SW_UPDATE } from './types';
import { SW_UPDATE } from './types';
import 'reactjs-popup/dist/index.css';
import './css/index.css';
import './css/libs.min.css';
import './css/styles.css';
import './css/App.css';
import icon from './img/logo512.png';

class App extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            firstDisclamerCheck: false,
            disclamerPopup: false,
            disclamerDoNotDisplay: false,
            surveyPopup: false,
            surveyDoNotDisplay: false
        }
    }

    checkDisclamerPopupDate() {
        let open = true;
        let last_date = localStorage.getItem('disclamer_popup');
        if (last_date && !isNaN(last_date)) {
            let date = new Date(Number(last_date));
            if (date > Date.now())
                open = false;
        }

        this.setState({ disclamerPopup: open, firstDisclamerCheck: true });
    }

    checkSurveyPopup() {
        let open = true;
        let survey_popup = localStorage.getItem('survey_popup');
        if (survey_popup && survey_popup === "false") {
                open = false;
        }

        this.setState({ surveyPopup: open });
    }

    componentDidMount() {
        // check at startup (after 3,2 sec, for animate splashscreen)
        setTimeout(() => {
            this.checkDisclamerPopupDate();
        }, 3200);
        // check every 10 sec
        setInterval(() => {
            this.checkDisclamerPopupDate();
            this.checkSurveyPopup();
        }, 10000);
    }

    render() {
        const updateServiceWorker = () => {
            const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

            if (registrationWaiting) {
                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

                registrationWaiting.addEventListener('statechange', e => {
                    if (e.target.state === 'activated') {
                        // save userType
                        const userType = localStorage.getItem('userType');
                        // update app
                        window.location.reload();
                        localStorage.clear();
                        // restore userType
                        localStorage.setItem('userType', userType);
                    }
                });
            }
        };

        return (
            <div className="wrapper-app">
                {/* header */}
                {/* {this.props.isServiceWorkerInitialized && (
                    <Alert text="Service Worker is initialized for the first time" type={SW_INIT} />
                )} */}
                {this.props.isServiceWorkerUpdated && (
                    <Alert
                        text="There is a new version available."
                        buttonText="Update"
                        type={SW_UPDATE}
                        onClick={updateServiceWorker}
                    />
                )}
                <Popup open={this.state.disclamerPopup} closeOnEscape={false} closeOnDocumentClick={false} modal>
                    {close => (
                        <div className="modal">
                            {/* <div className="header"> Modal Title </div> */}
                            <div className="content">
                                La collecte des données à caractère personnel
                                des patients est sous la seule responsabilité
                                du personnel médical hospitalier qui les intègre
                                à la présente application. Une fois que les données
                                sont extraites et supprimées de l’application,
                                le personnel médical hospitalier est également
                                seul responsable de l’intégrité, de la sécurité
                                et de la durée de conservation des données. Talan,
                                développeur de la présente application, ne peut
                                être tenu responsable en cas de non-respect par le
                                personnel médical hospitalier de la réglementation
                                en vigueur applicable au traitement de ces données,
                                et notamment du règlement (UE) 2016/679 du 27 avril
                                2016, de la loi n°78-17 du 6 janvier 1978 modifiée et
                                de la loi n° 2016-1321 du 7 octobre 2016 pour
                                une République numérique.
                            </div>

                            <div className="check">
                                <input
                                    type="checkbox"
                                    id="always_removed"
                                    name="disclamerDoNotDisplay"
                                    value={this.state.disclamerDoNotDisplay}
                                    onChange={(e) => { this.setState({ disclamerDoNotDisplay: e.target.checked }); }}
                                />
                                <label htmlFor="always_removed">Ne plus afficher ce message</label>
                            </div>

                            <div className="actions">
                                <button
                                    className="button"
                                    onClick={() => {
                                        let date = new Date();
                                        if (this.state.disclamerDoNotDisplay)
                                            date.setMonth(date.getMonth() + 1);
                                        else
                                            date.setDate(date.getDate() + 1);
                                        localStorage.setItem('disclamer_popup', date.getTime());
                                        close();
                                        this.setState({ disclamerPopup: false });
                                    }}
                                >
                                    J’ai compris
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
                <Popup open={!this.state.disclamerPopup && this.state.surveyPopup} closeOnEscape={false} closeOnDocumentClick={false} modal>
                    {close => (
                        <div className="modal">
                            <div className="content">
                                <center>
                                    Afin d'améliorer l'application, nous souhaiterions recueillir des informations sur votre utilisation de celle-ci.
                                </center>
                            </div>

                            <div className="actions">
                                <button
                                    style={{
                                        width: '100%',
                                    }}
                                    className="button"
                                    onClick={() => {
                                        window.open('https://form.sdrapps.fr/', '_blank');
                                        localStorage.setItem('survey_popup', "false");
                                        close();
                                        this.setState({ surveyPopup: false });
                                    }}
                                >
                                    Accéder au sondage
                                </button>
                                <br />
                                <button
                                    style={{
                                        padding: '5px 5px',
                                        width: '100%',
                                        fontSize: '1.2rem',
                                        minHeight: '15px',
                                        fontStyle: 'italic',
                                        background: 'none'
                                    }}
                                    className="button"
                                    onClick={() => {
                                        localStorage.setItem('survey_popup', "false");
                                        close();
                                        this.setState({ surveyPopup: false });
                                    }}
                                >
                                    Non merci, continuer sur l'application
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
                {/* show PWA installer window only when disclaimer is not displayed */}
                {
                    this.state.firstDisclamerCheck &&
                    !this.state.disclamerPopup && 
                    !this.state.surveyPopup &&
                    <PwaInstallPopupIOS
                        delay={1}
                        lang="fr"
                        appIcon={icon}>
                    </PwaInstallPopupIOS>
                }

                <Routes />
                {/* footer */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isServiceWorkerInitialized: state.serviceWorkerInitialized,
        isServiceWorkerUpdated: state.serviceWorkerUpdated,
        serviceWorkerRegistration: state.serviceWorkerRegistration
    }
}

export default connect(mapStateToProps)(App);

import React from 'react';
import { Slider } from '@material-ui/core';

const CustomSlider = ({ title, unit, name, handle, value, min, max, step }) => {


    return (
        <div className="form-group type-slider">
            <label htmlFor={name}>{title}</label>
            <div className="wrap-slider">
                <Slider
                    classes={{
                        thumbColorSecondary: 'sliderThumbColor',
                        colorSecondary: 'sliderColor',
                        thumb: 'sliderThumb',
                        rail: 'sliderRail',
                        track: 'sliderTrack',
                        root: 'sliderRoot',
                        active: 'sliderActive',
                        focusVisible: 'sliderFocusVisible'
                    }}
                    color="secondary"
                    name={name}
                    value={Number(value)}
                    {...(min && { min: Number(min) })}
                    {...(max && { max: Number(max) })}
                    {...(step && { step: Number(step) })}
                    onChange={handle}
                />
                <div className="info-slider">
                    <input
                        id={name}
                        name={name}
                        {...(min && { min: min })}
                        {...(max && { max: max })}
                        {...(step && { step: step })}
                        type="number"
                        className="tiny"
                        value={value}
                        onChange={handle} />
                    <div className="info">{unit}</div>
                </div>
            </div>
        </div>
    );

}

export default CustomSlider;
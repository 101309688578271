import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import CustomSlider from '../../CustomSlider';
import CustomComponent from '../../../customComponent';

class ShuntSelonRatio extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            shuntSelonRatio: {
                ratio: 40,
                shuntValue: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let shuntValue = this.getRatio();
        this.setState(prevState => ({
            shuntSelonRatio: {
                ...prevState.shuntSelonRatio,
                shuntValue
            }
        }));
    }

    componentDidMount() {
        let shuntSelonRatio = this.loadStorageValues('shuntSelonRatio', this.state.shuntSelonRatio);

        if (JSON.stringify(shuntSelonRatio) !== JSON.stringify(this.state.shuntSelonRatio))
            this.setState({
                shuntSelonRatio: shuntSelonRatio
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('shuntSelonRatio', JSON.stringify(this.state.shuntSelonRatio));
    }

    reset() {
        this.setState({
            shuntSelonRatio: {
                ratio: 40,
                shuntValue: ""
            }
        },
            () => {
                localStorage.removeItem('shuntSelonRatio');
            });
    }

    getRatio() {
        if (this.state.shuntSelonRatio.ratio === 40) { return "62,1% [46,5-75,5]" };
        if (this.state.shuntSelonRatio.ratio === 50) { return "60,3% [44,7-74,1]" };
        if (this.state.shuntSelonRatio.ratio === 60) { return "58,5% [42,9-72,6]" };
        if (this.state.shuntSelonRatio.ratio === 70) { return "56,7% [41,2-71,1]" };
        if (this.state.shuntSelonRatio.ratio === 80) { return "54,9% [39,4-69,6]" };
        if (this.state.shuntSelonRatio.ratio === 90) { return "53,1% [36,0-66,3]" };
        if (this.state.shuntSelonRatio.ratio === 100 || this.state.shuntSelonRatio.ratio === 110) { return "51,3% [36,0-66,3]" };
        if (this.state.shuntSelonRatio.ratio === 120 || this.state.shuntSelonRatio.ratio === 130) { return "47,6% [32,7-62,9]" };
        if (this.state.shuntSelonRatio.ratio === 140 || this.state.shuntSelonRatio.ratio === 150) { return "44,0% [29,6-59,4]" };
        if (this.state.shuntSelonRatio.ratio === 160 || this.state.shuntSelonRatio.ratio === 170) { return "40,4% [26,6-55,8]" };
        if (this.state.shuntSelonRatio.ratio === 180 || this.state.shuntSelonRatio.ratio === 190) { return "36,9% [23,9-52,1]" };
        if (this.state.shuntSelonRatio.ratio === 200 || this.state.shuntSelonRatio.ratio === 210) { return "33,5% [21,3-48,4]" };
        if (this.state.shuntSelonRatio.ratio === 220 || this.state.shuntSelonRatio.ratio === 230) { return "30,3% [19,0-44,8]" };
        if (this.state.shuntSelonRatio.ratio === 240 || this.state.shuntSelonRatio.ratio === 250) { return "27,3% [16,8-41,2]" };
        if (this.state.shuntSelonRatio.ratio === 260 || this.state.shuntSelonRatio.ratio === 270) { return "24,5% [14,9-37,6]" };
        if (this.state.shuntSelonRatio.ratio === 280 || this.state.shuntSelonRatio.ratio === 290) { return "21,9% [13,1-34,3]" };
        if (this.state.shuntSelonRatio.ratio === 300 || this.state.shuntSelonRatio.ratio === 310) { return "19,5% [11,5-31,0]" };
        if (this.state.shuntSelonRatio.ratio === 320 || this.state.shuntSelonRatio.ratio === 330) { return "17,3% [10,1-28,0]" };
        if (this.state.shuntSelonRatio.ratio === 340 || this.state.shuntSelonRatio.ratio === 350) { return "15,3% [8,9-25,1]" };
        if (this.state.shuntSelonRatio.ratio === 360 || this.state.shuntSelonRatio.ratio === 370) { return "13,5% [7,7-22,4]" };
        if (this.state.shuntSelonRatio.ratio === 380 || this.state.shuntSelonRatio.ratio === 390) { return "11,9% [6,7-20,0]" };
        if (this.state.shuntSelonRatio.ratio === 400 || this.state.shuntSelonRatio.ratio === 410) { return "10,4% [5,9-17,7]" };
        if (this.state.shuntSelonRatio.ratio === 420 || this.state.shuntSelonRatio.ratio === 430) { return "9,1% [5,1-15,7]" };
        if (this.state.shuntSelonRatio.ratio === 440 || this.state.shuntSelonRatio.ratio === 450) { return "8,0% [4,4-13,9]" };
        if (this.state.shuntSelonRatio.ratio === 460 || this.state.shuntSelonRatio.ratio === 470) { return "6,9% [3,9-12,2]" };
        if (this.state.shuntSelonRatio.ratio === 480 || this.state.shuntSelonRatio.ratio === 490) { return "6,1% [3,3-10,7]" };
        if (this.state.shuntSelonRatio.ratio === 500 || this.state.shuntSelonRatio.ratio === 510) { return "5,3% [2,9-9,4]" };
        if (this.state.shuntSelonRatio.ratio === 520 || this.state.shuntSelonRatio.ratio === 530) { return "4,6% [2,5-8,2]" };
        if (this.state.shuntSelonRatio.ratio === 540 || this.state.shuntSelonRatio.ratio === 550) { return "4,0% [2,2-7,2]" };
        if (this.state.shuntSelonRatio.ratio === 560 || this.state.shuntSelonRatio.ratio === 570) { return "3,5% [1,9-6,3]" };
        if (this.state.shuntSelonRatio.ratio === 580 || this.state.shuntSelonRatio.ratio === 590) { return "3,0% [1,6-5,5]" };
        if (this.state.shuntSelonRatio.ratio === 600) { return "2,6% [1,4-4,8]" };
    }

    render() {

        const panels = [
            {
                label: <div>Estimation du « shunt scannographique »</div>,
                content:
                    <div>
                        <p>Dans une étude publiée en 2013, Reske <i>et al</i>. ont proposé une méthode facilement réalisable au lit du malade pour estimer le volume de parenchyme pulmonaire non-aéré (là où le shunt pulmonaire est censé se produire). En effet, les auteurs ont rapporté une corrélation linéaire entre le rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> mesuré sous oxygène pur et la quantité de poumon non-aéré au scanner (parenchyme pulmonaire allant de -200 à +100 HU par rapport au poumon total)<sup>1</sup>. La ventilation sous F<sub>i</sub>O<sub>2</sub> 1 permet de limiter les effets de la vasoconstriction pulmonaire hypoxique et il est nécessaire de réaliser une transformation logarithmique du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub><sup>2</sup>.</p>
                    </div>
            },
            {
                label: 'Références',
                content:
                    <p>
                        1. Reske AW, Costa ELV, Reske AP, et al. Bedside estimation of nonaerated lung tissue using blood gas analysis. <i>Crit Care Med</i> 2013;41(3):732–743. <br /><br />
                        2. Carvalho AR. Simple Tool for Bedside Stratification: Recruitment Maneuvers and High Positive End-Expiratory Pressure Only for Those Who Need Them*. <i>Critical Care Medicine</i> 2013;41(3):912–913.
                    </p>

            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/shunt" }} className="back" />
                        <span className="title">Qs/Qt selon le PaO2/FiO2</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres</legend>
                    <CustomSlider
                        title="PaO2/FiO2 à FiO2 100%"
                        unit={<div></div>}
                        name="ratio"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonRatio')}
                        value={this.state.shuntSelonRatio.ratio}
                        min="40" max="600"
                        step="10"
                    />
                </fieldset>

                <fieldset>
                    <div className="form-group">
                        <div className="specific3">
                            <div className="specific3-wrapper">
                                <p>Prédiction<br />shunt scannographique</p>
                                <div className="specific4-inner">
                                    <div className="col">
                                        <p className="blue result">{this.getRatio()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(ShuntSelonRatio);
import React, { Component } from 'react';
import Navigation from "../../navigation/navigation";
import {withRouter, Link} from 'react-router-dom';

class Potentiel extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{pathname: "/pep"}} className="back" />
                        <span className="title">Potentiel de recrutement</span>
                        {/* <span onClick={() => this.setState({ infos: (this.state.infos)? false : true})} id="more" className="info">Informations</span> */}
                    </h1>
                </div>

                <div className="inner-content">
                    <nav id="menu">
                        <ul>
                            <li><Link to={{ pathname: "/riRatio" }}>Ratio recrutement/inflation</Link></li>
                            <li><Link to={{ pathname: "/peepChallenge" }}>Epreuve de PEP</Link></li>
                            <li><Link to={{ pathname: "/stressIndex" }}>Indice de stress</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>

            <Navigation />
        </div>);
    }
}
 
export default withRouter(Potentiel);
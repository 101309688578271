import React, { Component } from 'react';

class Panel extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			height: 0
		};
		this.ref = React.createRef();
	}

	componentDidMount() {
		window.setTimeout(() => {
			const el = this.ref.current;
			if (el !== null)
			{
				let height = el.querySelector('.panel__inner').scrollHeight;
			
				if (navigator.userAgent.indexOf("Firefox") !== -1) {
					let e = el.querySelector('.panel__inner');
					e.style.overflow = "scroll";
					height = e.scrollHeight;
					e.style.overflow = "hidden";
				}

				this.setState({
					height
				});
			}
		}, 333);
	}

	render () {
		const { label, content, activeTab, index, activateTab } = this.props;
		const { height } = this.state;
		const isActive = activeTab === index;
		const innerStyle = {
			height:  `${isActive ? height : 0}px`
		}

		return (
			<li ref={this.ref} className={ isActive? "active" : "" }>
				<div className={(isActive)? 'tab open-tab' : 'tab'}
					onClick={ activateTab }>
					{ label }
				</div>
				<div className='panel__inner panel'
					style={ innerStyle }
					aria-hidden={ !isActive }>
					<div className='panel__content' >{content}</div>
				</div>
			</li>
		);
	}
}

class Accordion extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			activeTab: -1
		};
		
		this.activateTab = this.activateTab.bind(this);
	}
	
	activateTab(index) {
		this.setState(prev => ({
			activeTab: prev.activeTab === index ? -1 : index
		}));
	}
	
	render() {
		const { panels } = this.props;
		const { activeTab } = this.state;
		return (
            <ul className="accordion">
				{panels.map((panel, index) =>
					<Panel
						key={ index }
						activeTab={ activeTab }
						index={ index }
						{ ...panel } 
						activateTab={ this.activateTab.bind(null, index) }
					/>
				)}
            </ul>
		);
	}
}

export default Accordion;
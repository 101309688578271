import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import Stepper from '../../Stepper';
import CustomSlider from '../../CustomSlider';
import CustomComponent from '../../../customComponent';

class RiRatio extends CustomComponent {

    constructor(props) {
        super(props);
        this.state = {
            riRatio: {
                vt: "",
                pepHaute: "",
                pepBasse: "",
                vtAHautePep: "",
                vtABassePep: "",
                pplatABassePep: "",
                riRatioValue: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let riRatioValue = 0;
        riRatioValue = this.getRiRatio();
        this.setState(prevState => ({
            riRatio: {
                ...prevState.riRatio,
                riRatioValue
            }
        }));
    }

    componentDidMount() {
        let riRatio = this.loadStorageValues('riRatio', this.state.riRatio);

        if (JSON.stringify(riRatio) !== JSON.stringify(this.state.riRatio))
            this.setState({
                riRatio: riRatio
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('riRatio', JSON.stringify(this.state.riRatio));
    }

    reset() {
        this.setState({
            riRatio: {
                vt: "",
                pepHaute: "",
                pepBasse: "",
                vtAHautePep: "",
                vtABassePep: "",
                pplatABassePep: "",
                riRatioValue: ""
            }
        },
            () => {
                localStorage.removeItem('riRatio');
            });
    }

    getRiRatio() {
        const { vt, vtABassePep, vtAHautePep, pplatABassePep, pepBasse, pepHaute } = this.state.riRatio;
        if (vt && vtABassePep && vtAHautePep && pplatABassePep && pepBasse && pepHaute) {
            const c1 = vt / (pplatABassePep - pepBasse);
            const c2 = c1 * (pepHaute - pepBasse);
            const c3 = vtABassePep - vtAHautePep;
            const c4 = c3 - c2;
            const c5 = c4 / (pepHaute - pepBasse);
            const result = Number((Math.round(c5 / c1 * 100) / 100).toFixed(2)) || 0;
            return result;
        }
        return "";
    }

    render() {

        const panels = [
            {
                label: 'Principe',
                content:
                    <div>
                        <p>Le principe du <i>recruitment-to-inflation</i> (R/I) ratio est basé sur la mesure du volume expiré entre un niveau de PEP haute (PEP<sub>haute</sub>) et un niveau de PEP basse (PEP<sub>basse</sub>) au cours d’une seule expiration<sup>1</sup>. La fréquence respiratoire au cours de la manœuvre est diminuée de telle sorte que la PEP totale corresponde à la PEP réglée. Le changement de PEP (∆PEP) est habituellement de 10 cmH<sub>2</sub>O, par exemple de 15 à 5 cmH<sub>2</sub>O<sup>1</sup>.

				Préalablement, il convient de s'assurer que:</p>
                        <ul>
                            <li>Le niveau de PEP<sub>basse</sub> soit toujours supérieur à la pression d'ouverture des voies aériennes (<i>airway opening pressure</i>, AOP).
						Attention, chez les patients avec une AOP élevée ({'>'} 10 cmH<sub>2</sub>O), le risque de barotraumatisme est important, surtout à PEP<sub>haute</sub>. Nous suggérons de ne pas augmenter la PEP<sub>haute</sub> au-delà de 20 cmH<sub>2</sub>O quitte à diminuer le ∆PEP. L’application pourra toujours calculer le R/I ratio mais le résultat doit être interprété avec prudence.</li>
                            <li>La fréquence respiratoire est suffisamment basse (5 à 12 cycles/min) pour obtenir un temps expiratoire suffisamment long afin de s’assurer d’une expiration complète.</li>
                        </ul>
                    </div>
            },
            {
                label: 'Méthode de calcul',
                content: <div><p>On définit le volume recrutable à haute PEP (∆V<sub>rec</sub>) comme la différence entre le volume expiré (VE) mesuré et prédit.</p>
                    <p>En l’absence de recrutement et en admettant une relation linéaire de la courbe pression volume à PEP<sub>basse</sub>, le VE<sub>prédit</sub> correspond au volume courant auquel s’additionne le volume distendu par le ∆PEP. Ce dernier correspond au produit de la compliance du <i>baby-lung</i> (C<sub>baby-lung</sub> qui correspond à la C<sub>RS</sub> à PEP<sub>basse</sub>) et du ∆PEP soit :
				</p>
                    <p>VE<sub>prédit</sub>  =  V<sub>t</sub> + [V<sub>t</sub> / (P<sub>plat</sub> mesurée à PEP<sub>basse</sub> - PEP<sub>basse</sub>)] x ∆PEP
						</p>
                    <p>Le VE<sub>mesuré</sub>  lors de la manœuvre, est mesuré par le débitmètre du ventilateur et correspond au V<sub>t</sub> expiré. On peut ainsi calculer le volume recruté ∆V<sub>rec</sub> :
						</p>
                    <p>∆V<sub>rec</sub> = VE<sub>mesuré</sub> - VE<sub>prédit</sub>
                    </p>
                    <p>Ensuite, on peut calculer la compliance du poumon recruté (C<sub>rec</sub>) selon la formule :
						</p>
                    <p>C<sub>rec</sub> = ∆V<sub>rec</sub> / ∆PEP
						</p>
                    <p>Enfin, le R/I ratio se calcule comme le rapport entre la compliance du poumon recruté (compartiment « recrutable ») et la compliance du <i>baby-lung</i> (volume pulmonaire disponible pour la ventilation, à risque de surdistention):
						</p>
                    <p>R/I = C<sub>rec</sub> / C<sub>baby-lung</sub><br /><br />
                    </p>

                </div>
            },

            {
                label: 'En pratique',
                content: <div><p><a href="https://www.youtube.com/watch?v=SNp0iGRBPxk" rel="noreferrer" target="_blank">Comment calculer le R/I ratio ?</a></p>
                    <ul>
                        <li>Régler la PEP à la PEP<sub>haute</sub> (ex : 15 cmH<sub>2</sub>O).</li>
                        <li>Réduire la fréquence respiratoire entre 5 et 8 cycles par minutes. </li>
                        <li>Relever le volume expiré à PEP<sub>haute</sub></li>
                        <li>Réduire la PEP de 10 cmH2O (ex : de 15 à 5 cmH<sub>2</sub>O). </li>
                        <li>Relever le volume expiré exclusivement sur LE cycle qui correspond au changement de PEP. </li>
                        <li>Relever la pression de plateau à PEP<sub>basse</sub>.</li>
                        <li>Rentrer les valeurs dans l’application pour calculer le R/I ratio. </li>
                    </ul></div>
            },

            {
                label: 'Rationnel',
                content: <div><p>Le R/I ratio est le rapport entre la compliance du poumon recruté et celle du poumon ventilé à PEP basse (le baby-lung). Il quantifie en quelque sorte le rapport bénéfice/risque de la PEP. Plus le R/I ratio est élevé ({'≥'} 0,5) et plus le poumon recruté est compliant, donc plus le volume recruté est important en comparaison au volume distendu. A l’inverse, plus le R/I ratio est bas ({'<'} 0,5) et plus le risque surdistension est élevé sans bénéfice en termes de recrutement lors de l’augmentation de la PEP. <br /><br />

				Il a été montré que le potentiel de recrutement estimé par le R/I ratio ou les courbes pression-volume multiples (la méthode de référence) sont bien corrélés<sup>1</sup>.
                </p></div>
            },
            {
                label: 'R/I ratio au cours du SDRA',
                content: <div><p>Un seuil de 0,50 a jusqu’ici été choisi pour distinguer les patients avec ou sans potentiel de recrutement car il s’agissait de la valeur médiane des 45 patients inclus dans l’étude princeps<sup>1</sup>. <br /><br />

				Cependant, Stevic et al. retrouvaient que le seuil de 0,70 était mieux corrélé au score de ré-aération pulmonaire évalué par échographie<sup>2</sup>. Quoi qu’il en soit la valeur du R/I ratio peut s’intégrer dans un raisonnement multi-paramétrique de personnalisation de la PEP. Plus le R/I ratio est élevé, plus la recrutabilité est importante et donc les effets potentiellement bénéfiques d’une PEP élevée probables.
				</p></div>
            },
            {
                label: 'Références',
                content: <div>
                    <p>
                        1. 	Chen L, Del Sorbo L, Grieco DL, et al. Potential for Lung Recruitment Estimated by the Recruitment-to-Inflation Ratio in Acute Respiratory Distress Syndrome. A Clinical Trial. <i>Am J Respir Crit Care Med</i> 2020;201(2):178–187. <br /><br />
						2. 	Stevic N, Chatelain E, Dargent A, Argaud L, Cour M, Guérin C. Lung Recruitability Evaluated by Recruitment-to-inflation Ratio and Lung Ultrasound in COVID-19 Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2021;203(8) :1025-1027.
						</p>
                </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1><Link to={{ pathname: "/potentiel" }} className="back" />
                        <span className="title">R/I ratio</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres affichés</legend>
                    <CustomSlider
                        title="Vt"
                        unit={<div>mL</div>}
                        name="vt"
                        handle={(e, val) => this.handleInputChange(e, val, 'riRatio')}
                        value={this.state.riRatio.vt}
                        min="1" max="600"
                        step="10"
                    />
                    <Stepper
                        title="PEP haute"
                        unit={<div>cmH<sub>2</sub>O</div>}
                        name="pepHaute"
                        handle={(e) => this.handleInputChange(e, null, 'riRatio')}
                        value={this.state.riRatio.pepHaute}
                        min="1" max="20"
                    />
                    <Stepper
                        title="PEP basse"
                        unit={<div>cmH<sub>2</sub>O</div>}
                        name="pepBasse"
                        handle={(e) => this.handleInputChange(e, null, 'riRatio')}
                        value={this.state.riRatio.pepBasse}
                        min="1" max="20"
                    />
                </fieldset>

                <fieldset>
                    <legend className="center">Paramètres mesurés</legend>
                    <CustomSlider
                        title="Vt expiré à haute PEP"
                        unit={<div>mL</div>}
                        name="vtAHautePep"
                        handle={(e, val) => this.handleInputChange(e, val, 'riRatio')}
                        value={this.state.riRatio.vtAHautePep}
                        min="1" max="1000"
                        step="10"
                    />
                    <CustomSlider
                        title="Vt expiré à basse PEP"
                        unit={<div>mL</div>}
                        name="vtABassePep"
                        handle={(e, val) => this.handleInputChange(e, val, 'riRatio')}
                        value={this.state.riRatio.vtABassePep}
                        min="1" max="2000"
                        step="10"
                    />
                    <Stepper
                        title="Pplat à basse PEP"
                        unit={<div>cmH<sub>2</sub>O</div>}
                        name="pplatABassePep"
                        handle={(e) => this.handleInputChange(e, null, 'riRatio')}
                        value={this.state.riRatio.pplatABassePep}
                        min="1" max="50"
                    />
                    <div className="form-group type-slider">
                        <div className="specific4">
                            <div className="specific4-wrapper">
                                <div className="specific4-inner">
                                    <div className="col">
                                        {
                                            (this.getRiRatio() !== "") ?
                                                <p className="result"><strong>{this.getRiRatio()}</strong></p>
                                                :
                                                ""
                                        }

                                        {/* <p className="blue"><small>mmHg</small></p> */}
                                        <p>
                                            {
                                                (this.state.riRatio.riRatioValue !== "") ?
                                                    this.getRiRatio() < 0.5 ?
                                                        "Faible potentiel de recrutement"
                                                        :
                                                        "Haut potentiel de recrutement"
                                                    :
                                                    ""
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>

            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>

            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(RiRatio);
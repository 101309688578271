import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import { AnimatePresence } from "framer-motion";

import Home from './components/home/home';
import About from './components/about/about';
import Tools from './components/dashboardTools/dashboardTools';
import Diagnostic from './components/dashboardTools/diagnostic/diagnostic';
import VolumeCourant from './components/dashboardTools/volcourant/volcourant';
import MecaVentil from './components/dashboardTools/mecaventil/mecaventil';
import Pep from './components/dashboardTools/pep/pep';
import Fermeture from './components/dashboardTools/pep/fermeture';
import Maintien from './components/dashboardTools/pep/maintien';
import NiveauPep from './components/dashboardTools/pep/niveauPep';
import PepIntrinseque from './components/dashboardTools/pep/pepIntrinseque';
import Potentiel from './components/dashboardTools/pep/potentiel';
import PeepChallenge from './components/dashboardTools/pep/peepChallenge';
import RiRatio from './components/dashboardTools/pep/riRatio';
import StressIndex from './components/dashboardTools/pep/stressIndex';
import Curarisation from './components/dashboardTools/curarisation/curarisation';
import Decubitus from './components/dashboardTools/decubitus/decubitus';
import EspaceMort from './components/dashboardTools/espmort/espmort';
import Capnometrie from './components/dashboardTools/espmort/capnometrie';
import Ventilatory from './components/dashboardTools/espmort/ventilatory';
import Capnographie from './components/dashboardTools/espmort/capnographie';
import Shunt from './components/dashboardTools/shunt/shunt';
import ShuntSelonO2 from './components/dashboardTools/shunt/shuntSelonO2';
import ShuntSelonRatio from './components/dashboardTools/shunt/shuntSelonRatio';
import Team from './components/about/team';
import Formules from './components/about/formules';
import Export from './components/export/export';
import loadScreen from './components/loadScreen/loadScreen';
import Profile from './components/profile/profile';
import PressionOeso from './components/dashboardTools/pressionoeso/pressionoeso';

function Routes() {
    return (
        <Router>
            <AnimatePresence>
                <Switch>
                    <Route exact path="/" component={loadScreen} />
                    <Route path="/home" component={Home} />
                    <Route path="/tools" component={Tools} />
                    <Route path="/export" component={Export} />
                    <Route path="/about" component={About} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/team" component={Team} />
                    <Route path="/formules" component={Formules} />
                    <Route path="/diagnostic" component={Diagnostic} />
                    <Route path="/volcourant" component={VolumeCourant} />
                    <Route path="/mecaventil" component={MecaVentil} />
                    <Route path="/pep" component={Pep} />
                    <Route path="/fermeture" component={Fermeture} />
                    <Route path="/maintien" component={Maintien} />
                    <Route path="/niveauPep" component={NiveauPep} />
                    <Route path="/pepIntrinseque" component={PepIntrinseque} />
                    <Route path="/potentiel" component={Potentiel} />
                    <Route path="/peepChallenge" component={PeepChallenge} />
                    <Route path="/riRatio" component={RiRatio} />
                    <Route path="/stressIndex" component={StressIndex} />
                    <Route path="/curarisation" component={Curarisation} />
                    <Route path="/decubitus" component={Decubitus} />
                    <Route path="/pressionoeso" component={PressionOeso} />
                    <Route path="/espmort" component={EspaceMort} />
                    <Route path="/capnometrie" component={Capnometrie} />
                    <Route path="/ventilatory" component={Ventilatory} />
                    <Route path="/capnographie" component={Capnographie} />
                    <Route path="/shunt" component={Shunt} />
                    <Route path="/shuntSelonO2" component={ShuntSelonO2} />
                    <Route path="/shuntSelonRatio" component={ShuntSelonRatio} />
                    <Route component={loadScreen} />
                </Switch>
            </AnimatePresence>
        </Router>
    )
}

export default Routes;


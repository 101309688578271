import React from 'react';

const Stepper = ({ title, unit, name, handle, value, min, max, step }) => {


    return (
        <div className="form-group type-stepper">
            <label htmlFor={name}>{title}</label>
            <div className="wrap-stepper">
                <span className="stepper">
                    <span className="stepper-step step-down">
                        <input
                            id="step-down"
                            type="submit"
                            name={name}
                            onClick={handle}
                            {...(min && { min: min })}
                            {...(max && { max: max })}
                            {...(step && { step: step })}
                        />
                    </span>
                    <span className="stepper-step step-up">
                        <input
                            id="step-up"
                            type="submit"
                            name={name}
                            onClick={handle}
                            {...(min && { min: min })}
                            {...(max && { max: max })}
                            {...(step && { step: step })}
                        />
                    </span>
                </span>
                <div className="info-stepper">
                    <input
                        id={name}
                        name={name}
                        {...(min && { min: min })}
                        {...(max && { max: max })}
                        {...(step && { step: step })}
                        type="number"
                        className="tiny"
                        value={value}
                        onChange={handle} />
                    <div className="info">{unit}</div>
                </div>
            </div>
        </div>
    );

}

export default Stepper;
import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom';

class NavAbout extends Component {

    constructor(props){
        super(props);
        this.state = {
            //type: (this.props.location.state!==undefined)? this.props.location.state.type : "about"
        }
    }

    // onSubmit (path) {
    //     this.props.history.push({
    //         pathname: path,
    //         state: { type: this.state.type},
    //     });
    // }

    render() {
        return (
            <div className="sticky-top">
                <h1>A propos</h1>
                <nav>
                    <ul>
                        <li>
                            <Link className={(this.props.location.pathname==="/about")? 'active' : ''} to={{pathname: "/about", state: this.state}}>
                                Présentation
                            </Link>
                        </li>
                        <li>
                            <Link className={(this.props.location.pathname==="/team")? 'active' : ''} to={{pathname: "/team", state: this.state}}>
                                SDRApps Team's
                            </Link>
                        </li>
                        <li>
                            <Link className={(this.props.location.pathname==="/formules")? 'active' : ''} to={{pathname: "/formules", state: this.state}}>
                                Formules
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default withRouter(NavAbout);

import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Navigation from "../../navigation/navigation";
import CustomSlider from '../../CustomSlider';
import Switch from '../../../components/switch';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';


class Diagnostic extends CustomComponent {

    constructor(props) {
        super(props);
        this.state = {
            diagnostic: {
                moinsDuneSemaine: false,
                opacites: false,
                aspect: "lobaire",
                nonExpliquéeParDéfaillanceCardiaque: false,
                etiologie: "primaire",
                causeSDRA: "",
                pao2: "",
                fio2: "",
                pao2Fio2: "",
                sdra: "",
                mortality: ""
            },
            infos: false
        };
    }

    onSubmit(path) {
        this.props.history.push(path);
    }

    runCalculs() {
        let sdra = "";
        let mortality = "";
        let pao2Fio2 = Number(Math.round(this.state.diagnostic.pao2 / (this.state.diagnostic.fio2 / 100))) || 0;

        if (this.state.diagnostic.moinsDuneSemaine
            && this.state.diagnostic.opacites
            && this.state.diagnostic.nonExpliquéeParDéfaillanceCardiaque) {
            if ((Math.round(this.state.diagnostic.pao2 / (this.state.diagnostic.fio2 / 100))) >= 200) {
                sdra = "Léger";
                mortality = "27%";
            }
            else if ((Math.round(this.state.diagnostic.pao2 / (this.state.diagnostic.fio2 / 100))) >= 100
                && (Math.round(this.state.diagnostic.pao2 / (this.state.diagnostic.fio2 / 100))) < 200) {
                sdra = "Modéré";
                mortality = "32%";
            }
            else if ((Math.round(this.state.diagnostic.pao2 / (this.state.diagnostic.fio2 / 100))) < 100) {
                sdra = "Sévère";
                mortality = "45%";
            }
        }

        this.setState(prevState => ({
            diagnostic: {
                ...prevState.diagnostic,
                pao2Fio2,
                sdra,
                mortality
            }
        }));
    }

    componentDidMount() {
        let diagnostic = this.loadStorageValues('diagnostic', this.state.diagnostic);

        if (JSON.stringify(diagnostic) !== JSON.stringify(this.state.diagnostic))
            this.setState({
                diagnostic: diagnostic
            }, () => {
                this.runCalculs();
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('diagnostic', JSON.stringify(this.state.diagnostic));
    }

    reset() {
        this.setState({
            diagnostic: {
                moinsDuneSemaine: false,
                opacites: false,
                aspect: "lobaire",
                nonExpliquéeParDéfaillanceCardiaque: false,
                etiologie: "primaire",
                causeSDRA: "",
                pao2: "",
                fio2: "",
                pao2Fio2: "",
                sdra: "",
                mortality: ""
            }
        },
            () => {
                localStorage.removeItem('diagnostic');
            });
    }


    render() {

        const { t } = this.props;

        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>
                            <Link to={{ pathname: "/tools" }} className="back" />
                            <span className="title">Diagnostic</span>
                            <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                        </h1>
                    </div>
                    <fieldset>
                        <legend className="center">Définition de Berlin</legend>
                        <div className="form-group">
                            <label>Evolution depuis moins de 7 jours</label>
                            <Switch
                                className="switch3"
                                name="moinsDuneSemaine"
                                checked={this.state.diagnostic.moinsDuneSemaine}
                                onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                            />
                        </div>
                        <div className="form-group">
                            <label>Opacités pulmonaires bilatérales</label>
                            <Switch
                                className="switch3"
                                name="opacites"
                                checked={this.state.diagnostic.opacites}
                                onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                            />
                        </div>

                        {this.state.diagnostic.opacites &&
                            <div className="form-group form-check-inline">
                                <legend className="aspectradio">Aspect radiographique</legend>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="aspect"
                                        id="gridRadios1"
                                        value="lobaire"
                                        onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                                        checked={this.state.diagnostic.aspect === 'lobaire'}
                                    />
                                    <label className="form-check-label" htmlFor="gridRadios1">
                                        Lobaire
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="aspect"
                                        id="gridRadios2"
                                        value="patchy"
                                        onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                                        checked={this.state.diagnostic.aspect === 'patchy'}
                                    />
                                    <label className="form-check-label" htmlFor="gridRadios2">
                                        Patchy
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="aspect"
                                        id="gridRadios3"
                                        value="diffus"
                                        onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                                        checked={this.state.diagnostic.aspect === 'diffus'}
                                    />
                                    <label className="form-check-label" htmlFor="gridRadios3">
                                        Diffus
                                    </label>
                                </div>
                            </div>
                        }

                        <div className="form-group">
                            <label>Non expliquée par défaillance cardiaque</label>
                            <Switch
                                className="switch3"
                                name="nonExpliquéeParDéfaillanceCardiaque"
                                checked={this.state.diagnostic.nonExpliquéeParDéfaillanceCardiaque}
                                onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                            />
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend className="center">Etiologie</legend>
                        <div className="form-group form-check-inline">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="etiologie"
                                    id="gridRadios4"
                                    value="primaire"
                                    onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                                    checked={this.state.diagnostic.etiologie === 'primaire'}
                                />
                                <label className="form-check-label" htmlFor="gridRadios4">
                                    Primaire
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="etiologie"
                                    id="gridRadios5"
                                    value="secondaire"
                                    onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                                    checked={this.state.diagnostic.etiologie === 'secondaire'}
                                />
                                <label className="form-check-label" htmlFor="gridRadios5">
                                    Secondaire
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cause">Cause du SDRA</label>
                            <input
                                type="text"
                                id="cause"
                                name="causeSDRA"
                                value={this.state.diagnostic.causeSDRA}
                                onChange={(e) => this.handleInputChange(e, null, 'diagnostic')}
                            />
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend className="center">Gravité du SDRA</legend>
                        <CustomSlider
                            title="PaO2"
                            unit={<div>mmHg</div>}
                            name="pao2"
                            handle={(e, val) => this.handleInputChange(e, val, 'diagnostic')}
                            value={this.state.diagnostic.pao2}
                            min="1" max="300"
                        />
                        <CustomSlider
                            title="FiO2"
                            unit={<div>%</div>}
                            name="fio2"
                            handle={(e, val) => this.handleInputChange(e, val, 'diagnostic')}
                            value={this.state.diagnostic.fio2}
                            min="21" max="100"
                        />
                    </fieldset>
                    <fieldset>
                        <div className="form-group">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    {/* <p>Type de SDRA</p> */}
                                    {/* keep <p></p> (Front) */}
                                    <p></p>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p><small>Pa02/Fi02</small></p>
                                            <p className="result"><strong>{this.state.diagnostic.pao2Fio2}</strong></p>
                                        </div>
                                        <div className="col">
                                            <p><small>SDRA</small></p>
                                            <p className="result"><strong>{this.state.diagnostic.sdra}</strong></p>
                                        </div>
                                        <div className="col">
                                            <p><small>Mortalité</small></p>
                                            <p className="result"><strong>{this.state.diagnostic.mortality}</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="wrap-validation">
                        <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                        <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                    </div>
                </div>
                <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                    <div id="info-bloc-inner">
                        <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                        <h2>Définitions</h2>
                        <Accordion panels={t("panels", { returnObjects: true }).Diagnostic} />
                    </div>
                </div>
                <Navigation state={this.state} />
            </div>
        );
    }
}

export default withTranslation()(withRouter(Diagnostic));

const translationFR = {
    Diagnostic: "Diagnostic",
    "Choisissez votre profil": "Choisissez votre profil",
    Standard: "Standard",
    Avancé: "Avancé",
    panels: {
        Diagnostic:
            [{
                label: "Définition de Berlin",
                content:
                    <div>
                        <p><strong>Le syndrome de détresse respiratoire aiguë (SDRA) correspond à un œdème pulmonaire lésionnel qui se manifeste par une hypoxémie associée à des infiltrats pulmonaires bilatéraux. </strong></p>
                        <h3>La définition de Berlin proposée en 2012 comporte les critères suivants<sup>1</sup> :</h3>
                        <ul>
                            <li>Hypoxémie aiguë avec un rapport PaO2/FiO2 {'≤'} 300 mmHg chez un patient sous ventilation mécanique invasive ou non invasive avec une pression expiratoire positive (PEP) ≥ 5 cmH<sub>2</sub>O.</li>
                            <li>Opacités radiologiques bilatérales à la radiographie standard ou tomodensitométrie non complètement expliqués par des épanchements pleuraux, des atélectasies ou des nodules.</li>
                            <li>L’origine de l’œdème ne doit pas être entièrement expliquée par une insuffisance cardiaque ou une surcharge volémique. En d’autres termes, la cause de l’œdème pulmonaire doit être principalement lésionnel bien qu'une composante hydrostatique puisse exister. Si un facteur de risque de SDRA habituel n’est pas retrouvé (cf. ci-dessous), l’œdème pulmonaire cardiogénique ou de surcharge doit être éliminé par un examen complémentaire objectif (échocardiographie, thermodilution transpulmonaire...).</li>
                            <li>Ces manifestations doivent apparaître dans les 7 jours suivant facteur de risque pulmonaire ou extra-pulmonaire (cf. ci-dessous).</li>
                        </ul>
                        <p>En pratique, il existe une multitude de phénotypes de SDRA avec des mécanismes physiopathologiques distincts. L'évaluation précise de la mécanique respiratoire, du potentiel de recrutement alvéolaire et des anomalies de ventilation-perfusion, pourrait permettre de personnaliser la ventilation mécanique<sup>2–4</sup>.</p>
                    </div>
            },
            {
                label: "Aspect radiologique",
                content:
                    <div>
                        <p>L’imagerie thoracique permet de distinguer trois patterns scanographiques<sup>5</sup> :</p>
                        <ul>
                            <li>SDRA focal : perte d’aération dans les zones inférieures et postérieures du poumon alors que les zones supérieures et antérieures restent bien aérées. La réponse à la PEP et aux manœuvres de recrutement alvéolaire est en général modérée en raison du risque de surdistention des zones correctement aérées.</li>
                            <li>SDRA « patchy » : zones inférieures et postérieures non aérées accompagnées d’une perte partielle de l'aération des zones supérieures et antérieures.</li>
                            <li>SDRA diffus : perte massive et diffuse de l'aération des poumons. C'est le type de SDRA pour lequel le potentiel de recrutement alvéolaire est habituellement le meilleur.</li>
                        </ul>
                    </div>
            },
            {
                label: "Facteurs de risques",
                content: <div><p>On distingue les SDRA primaires (ou directs) pour lesquels l'agression est d'origine épithéliale pulmonaire. Les principales causes sont les pneumopathies infectieuses ou d'inhalation, la contusion pulmonaire, l'inhalation de fumée ou de liquide... La ventilation mécanique elle-même peut entrainer ou aggraver les lésions pulmonaires, c’est le concept de lésions induites par la ventilation mécanique (ventilatory-induced lung injury, VILI)<sup>6</sup>.</p>
                    <p>A l'inverse, les SDRA secondaires (ou indirects) sont liés à une agression d'origine endothéliale. Les principales causes sont les pancréatites, le sepsis (quel qu’en soit l'origine), la transfusion  (transfusion-related acute lung injury ou TRALI)<sup>6</sup>.</p>
                </div>
            },
            {
                label: "Sévérité",
                content:
                    <div>
                        <p>Le rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> (mesuré avec une PEP d'au moins 5 cmH<sub>2</sub>O)
                            permet de classer le SDRA en 3 stades de gravité croissante<sup>1,
                            7</sup>:</p>
                        <ul>
                            <li>
                                SDRA léger (mortalité hospitalière d'environ 30-35%) en cas de rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> compris entre 200 et 300 mmHg.
                        </li>
                            <li>
                                SDRA modéré (mortalité hospitalière d'environ 35-45%) en cas de rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> compris entre 100 et 200 mmHg.
                        </li>
                            <li>
                                SDRA sévère (mortalité hospitalière d'environ 40-50%) en cas de rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> inférieure ou égale à 100 mmHg.
                        </li>
                        </ul>
                    </div>
            },
            {
                label: "Références",
                content: <div><p>
                    1. ARDS Definition Task Force, Ranieri VM, Rubenfeld GD, et al. Acute respiratory distress syndrome:
                    the Berlin Definition. <i>JAMA</i> 2012;307(23): 2526–2533. <br /><br />
                    2. Menk M, Estenssoro E, Sahetya SK, et al. Current and evolving standards of care for patients with ARDS. <i>Intensive Care Med</i> 2020;46(12):2157–2167.
                    <i>Intensive Care Med</i> 2020;46(12): 2157–2167. <br /><br />
                    3. Matthay MA, Arabi YM, Siegel ER, et al. Phenotypes and personalized medicine in the acute respiratory distress syndrome. <i>Intensive Care Med</i> 2020;46(12):2136–2152.
                    <i>Intensive Care Med</i> 2020;46(12): 2136–2152. <br /><br />
                    4. Jabaudon M, Blondonnet R, Audard J, et al. Recent directions in personalised acute respiratory distress syndrome medicine. <i>Anaesth Crit Care Pain Med</i> 2018;37(3):251–258.
                    <i>Anaesth Crit Care Pain Med</i> 2018;37(3): 251–258. <br /><br />
                    5. Chiumello D, Froio S, Bouhemad B, Camporota L, Coppola S. Clinical review: Lung imaging in acute respiratory distress syndrome patients - an update. <i>Crit Care</i> 2013;17(6):243.<br /><br />
					6. Rezoagli E, Fumagalli R, Bellani G. Definition and epidemiology of acute respiratory distress syndrome. <i>Ann Transl Med</i> 2017;5(14):282.<br /><br />
					7. Bellani G, Laffey JG, Pham T, et al. Epidemiology, Patterns of Care, and Mortality for Patients With Acute Respiratory Distress Syndrome in Intensive Care Units in 50 Countries. <i>JAMA</i> 2016;315(8):788–800.<br /><br />
				</p></div>
            }],
        VolumeCourant:
            [{
                label: 'Rationnel',
                content:
                    <div>
                        <p>
                            Le SDRA est caractérisé par une diminution plus ou moins importante du volume pulmonaire aéré disponible pour la ventilation, c’est le concept de « baby lung ». Il en résulte une diminution dans la même proportion de la compliance du système respiratoire (C<sub>rs</sub>)<sup>1,2</sup>. Cette réduction du volume pulmonaire disponible pour la ventilation (mesuré comme le volume pulmonaire de fin d’expiration) entraine un risque de surdistention et de barotraumatisme lors de l’insufflation du volume courant "strain".
                            Ce risque est d’autant plus élevé que le volume pulmonaire de fin d’expiration est faible et le V<sub>t</sub> important, générant alors une déformation (généralement appelée « strain ») importante et potentiellement délétère. Les interventions susceptibles de réduire ce risque sont :
                        </p>
                        <ul>
                            <li>
                                Augmenter la taille du <i>baby-lung</i> afin de distribuer le V<sub>t</sub> dans un volume plus grand, limitant le strain et donc le risque de surdistention, mise en décubitus ventral, application d’une pression expiratoire positive…<sup>2</sup>.
                            </li>
                            <li>
                                Réduire le V<sub>t</sub>: L'essai ARMA, publié en 2000 par l'ARDS Network, a montré qu'une stratégie de réduction du V<sub>t</sub> (6 <i>vs</i>. 12 ml/kg de poids prédit par la taille [PPT]) assurant une pression de plateau inférieure à 30 cmH<sub>2</sub>O était associée à une réduction de la mortalité<sup>4</sup>. Depuis, l'application d'un V<sub>t</sub> à 6-8 mL/kg PPT (pour assurer une pression de plateau inférieure à 28-30 cmH<sub>2</sub>O) est devenue la règle dans la prise en charge du SDRA<sup>5</sup>.<br /><br />
                            </li>
                        </ul>
                    </div>

            },
            {
                label: 'Comment calculer le volume courant ?',
                content:
                    <div>
                        <p>
                            Attention : le V<sub>t</sub> doit être calculé en fonction du poids prédit
                            par la taille (PPT) pour chaque patient (et non en
                            fonction du poids mesuré). Le poids prédit par la taille se calcule de la manière suivante <sup>5</sup>:
                        </p>
                        <ul>
                            <li>
                                PPT chez l'homme = 50 + 0,91 x (taille en cm - 152,4)
                            </li>
                            <li>
                                PPT chez la femme = 45,5 + 0,91 x (taille en cm - 152,4)
                            </li>
                        </ul>
                        <p>
                            Notons qu’un faible V<sub>t</sub> impose cependant une fréquence respiratoire
                            élevée (entre 25-30 cycles/min) pour maintenir une
                            ventilation-minute suffisante et limiter le risque
                            d'acidose hypercapnique. <br />Une hypercapnie permissive
                            (P<sub>a</sub>CO<sub>2</sub> {'<'}50 mmHg et pH {'>'}7,30) est généralement acceptée<sup>6</sup>.
                        </p>
                    </div>
            },
            {
                label: 'Evaluation au cours du SDRA',
                content:
                    <div>
                        <p>
                            Avant l’essai ARMA, plusieurs études avaient déjà montré un
                        effet bénéfique à l’utilisation d’un faible V<sub>t</sub> sur l’oxygénation,
                        la mécanique ventilatoire ou la mortalité<sup>6–9</sup>. Une méta-analyse
                        de la revue Cochrane publiée en 2013 (incluant 6 essais dont ALVEOLI
                        et ARMA) a confirmé ces résultats. La mortalité hospitalière était
                        significativement plus basse chez les patients ventilés avec un faible
                        V<sub>t</sub> avec un risque relatif à 0,74 (IC 95% 0,61-0,88)<sup>10</sup>. Plus récemment,
                        la méta-analyse de Walkey et al. a confirmé qu'il existait une relation
                        entre la réduction du V<sub>t</sub> et la diminution de mortalité avec un risque
                        relatif à 0,80 (IC 95% 0,66-0,98)<sup>11</sup>.
                    </p></div>
            },
            // {
            //     label: 'Ventilation ultra-protectrice',
            //     content: <div>
            //         <p>
            //             Avant l’essai ARMA, plusieurs études avaient déjà montré un effet bénéfique à l’utilisation d’un faible V<sub>t</sub> sur l’oxygénation, la mécanique respiratoire ou la mortalité<sup>7–10</sup>. Une méta-analyse de la revue Cochrane publiée en 2013 (incluant 6 essais dont ALVEOLI et ARMA) a confirmé ces résultats. La mortalité hospitalière était significativement plus basse chez les patients ventilés avec un faible V<sub>t</sub> avec un risque relatif à 0,74 (IC 95% 0,61-0,88)<sup>11</sup>. Plus récemment, la méta-analyse de Walkey <i>et al.</i> ont confirmé qu'il existait une relation entre la réduction du V<sub>t</sub> et la diminution de mortalité<sup>12 </sup>.
            //         </p></div>
            // },
            {
                label: 'Références',
                content: <div>
                    <p>
                        1. Gattinoni L, Pesenti A. The concept of “baby lung.” <i>Intensive Care Med</i> 2005;31(6):776–784. <br /><br />
			 			2. 	Gattinoni L, Marini JJ, Pesenti A, Quintel M, Mancebo J, Brochard L. The “baby lung” became an adult. <i>Intensive Care Med</i> 2016;42(5):663–673. <br /><br />
			 			3. 	Chiumello D, Carlesso E, Cadringher P, et al. Lung stress and strain during mechanical ventilation for acute respiratory distress syndrome. <i>Am J Respir Crit Care Med</i> 2008;178(4):346–355. <br /><br />
			 			4. 	Acute Respiratory Distress Syndrome Network, Brower RG, Matthay MA, et al. Ventilation with lower tidal volumes as compared with traditional tidal volumes for acute lung injury and the acute respiratory distress syndrome. <i>N Engl J Med</i> 2000;342(18):1301–1308.<br /><br />
			 			5. 	Chiumello D, Brochard L, Marini JJ, et al. Respiratory support in patients with acute respiratory distress syndrome: an expert opinion. <i>Critical Care</i> 2017;21(1):240.<br /><br />
			 			6. 	Papazian L, Aubron C, Brochard L, et al. Formal guidelines: management of acute respiratory distress syndrome. <i>Ann Intensive Care</i> 2019;9(1):69.<br /><br />
			 			7. 	Amato MB, Barbas CS, Medeiros DM, et al. Effect of a protective-ventilation strategy on mortality in the acute respiratory distress syndrome. <i>N Engl J Med</i> 1998;338(6):347–354.<br /><br />
			 			8. 	Brochard L, Roudot-Thoraval F, Roupie E, et al. Tidal volume reduction for prevention of ventilator-induced lung injury in acute respiratory distress syndrome. The Multicenter Trail Group on Tidal Volume reduction in ARDS. <i>Am J Respir Crit Care Med</i> 1998;158(6):1831–1838. <br /><br />
			 			9. 	Brower RG, Shanholtz CB, Fessler HE, et al. Prospective, randomized, controlled clinical trial comparing traditional versus reduced tidal volume ventilation in acute respiratory distress syndrome patients. <i>Crit Care Med</i> 1999;27(8):1492–1498. <br /><br />
			 			10. 	Stewart TE, Meade MO, Cook DJ, et al. Evaluation of a ventilation strategy to prevent barotrauma in patients at high risk for acute respiratory distress syndrome. Pressure- and Volume-Limited Ventilation Strategy Group. <i>N Engl J Med</i> 1998;338(6):355–361. <br /><br />
			 			11. 	Petrucci N, De Feo C. Lung protective ventilation strategy for the acute respiratory distress syndrome. <i>Cochrane Database Syst Rev</i> 2013;(2):CD003844. <br /><br />
			 			12. 	Walkey AJ, Goligher EC, Del Sorbo L, et al. Low Tidal Volume versus Non-Volume-Limited Strategies for Patients with Acute Respiratory Distress Syndrome. A Systematic Review and Meta-Analysis. <i>Ann Am Thorac Soc</i> 2017;14(Supplement_4):S271–S279.<br /><br />

                    </p></div>
            }],
        mecaventil: [
            {
                label: 'Compliance et « baby lung »',
                content:
                    <div>
                        <p>
                            La compliance représente la capacité d’un système à se laisser distendre. En d’autres termes, la compliance du système respiratoire (C<sub>rs</sub>) est la capacité du poumon et de la paroi thoracique à se déformer au cours de l’inspiration pour accueillir le volume courant (V<sub>t</sub>). Elle dépend des propriétés mécaniques des tissus (compliance spécifique) ainsi que du volume disponible pour la ventilation.<br /><br />
						La C<sub>rs</sub> (qui s’exprime en mL/cmH<sub>2</sub>O) est définie comme le rapport entre le V<sub>t</sub> et la différence de pression dans les voies aériennes en conditions statiques entre la fin de l’inspiration (la pression de plateau, P<sub>plat</sub>) et la fin de l’expiration (la pression expiratoire positive totale, PEP<sub>tot</sub>) :<br /><br />
						C<sub>rs</sub> = V<sub>t</sub> / (P<sub>plat</sub> – PEP<sub>tot</sub>)<br /><br />
						Le SDRA est caractérisé par une diminution plus ou moins importante de la C<sub>rs</sub> secondaire à la réduction du volume pulmonaire disponible à recevoir la ventilation (aussi appelé <i>baby-lung</i>, car de dimension similaire au poumon d’un jeune enfant, soit 300-500 g de tissu aéré)<sup>1,2</sup>.

                        </p></div>
            },

            {
                label: 'Définition de la pression de plateau',
                content:
                    <div>
                        <p>
                            La pression de plateau (P<sub>plat</sub>) mesurée au cours d’une occlusion télé-inspiratoire, est le reflet de la pression qui règne dans les alvéoles en fin d'inspiration (ou d’insufflation du ventilateur)<sup>3</sup>. En conséquence, la P<sub>plat</sub> est un bon reflet du risque de surdistention alvéolaire (barotraumatisme) lorsque la compliance de la paroi thoracique n’est pas altérée. Elle doit donc être attentivement surveillée. En règle générale elle ne doit pas dépasser 30 cmH<sub>2</sub>O (voir ci-dessous). <br /><br />
						En ventilation assistée contrôlée en volume, un temps de plateau de 0,2 à 0,3 seconde suffit au monitorage de la P<sub>plat</sub>. Si besoin, une occlusion manuelle en fin d'inspiration (pause inspiratoire) peut être réalisée pour mesurer la P<sub>plat</sub><sup>4</sup>. Notons que la P<sub>plat</sub> reste mesurable lors de la ventilation en mode barométrique ou en présence d’une ventilation spontanée<sup>5–7</sup>.
                        </p></div>
            },
            {
                label: 'Mortalité et pression de plateau',
                content:
                    <div>
                        <p>
                            Dans l’étude de l’ARDS Network, les patients ventilés avec un V<sub>t</sub> bas et
                            une P<sub>plat</sub> inférieure à 30 cmH<sub>2</sub>O avaient une mortalité
                            plus basse que les patients ventilés avec un V<sub>t</sub> élevé et avec une P<sub>plat</sub> non restreinte<sup>8</sup>.
                            Depuis, des études scannographiques ont montré que la surdistention alvéolaire était plus fréquente
                            lorsque P<sub>plat</sub> était supérieure à 28-30 cmH<sub>2</sub>O<sup>9</sup>. Villar <i>et al.</i> ont montré que le risque de mortalité augmentait en cas de P<sub>plat</sub> supérieure à 29 cmH<sub>2</sub>O<sup>10</sup>. Ceci a été confirmé dans plusieurs méta-analyses associant des essais randomisés comparant une stratégie de V<sub>t</sub> et P<sub>plat</sub> faibles à une stratégie de V<sub>t</sub> et P<sub>plat</sub> élevés<sup>11,12</sup>. Une P<sub>plat</sub> élevée correspond donc à un facteur de risque de mortalité au cours du SDRA<sup>10,13,14</sup>.

							La P<sub>plat</sub> doit donc être monitorée au cours du SDRA<sup>13,15</sup>. Les sociétés savantes recommandent de ne pas dépasser à 30 cmH<sub>2</sub>O de P<sub>plat</sub><sup>16–18</sup>.<br /><br />

							Attention cependant, la P<sub>plat</sub> peut ne pas refléter le risque de surdistention pulmonaire lorsque la compliance de la paroi thoracique est altérée (faible capacité de la paroi à se laisser distendre). Dans ces cas particuliers, on peut proposer la mesure de la pression transpulmonaire (cf. pression œsophagienne) pour mieux apprécier le risque de surdistension<sup>19,20</sup>.

                        </p></div>
            },
            {
                label: 'Facteurs influençant la pression de plateau',
                content:
                    <div>
                        <p>

                            Rappelons l'équation du mouvement respiratoire en conditions passives: 	<br /><br />
							P<sub>aw</sub>(t) = P<sub>0</sub> + Pression résistive + Pression élastique<br />
							P<sub>aw</sub>(t) = P<sub>0</sub> + (R<sub>rs</sub> x Q<sub>i(t)</sub>) + (V<sub>t</sub>/C<sub>rs</sub>)<br /><br />
							Où P<sub>aw</sub>(t) est la pression des voies aériennes à un instant t, P<sub>0</sub> la pression initiale (c’est-à-dire la pression intra-alvéolaire de fin d’expiration), Q<sub>i(t)</sub> le débit d’insufflation à l’instant t, et V<sub>(t)</sub> le volume insufflé à l’instant t. R<sub>rs</sub> et C<sub>rs</sub> correspondent respectivement à la résistance et compliance du système respiratoire.<br /><br />

							La mesure de la P<sub>plat</sub> est réalisée au cours d’une pause inspiratoire (le débit d’insufflation devient donc nul) en fin d’inspiration. On peut ainsi écrire :

							P<sub>plat</sub> = PEP + (V<sub>t</sub> / C<sub>rs</sub>)<br /><br />

							Où V<sub>t</sub> est le volume courant (en fin d’insufflation, lorsque l’ensemble du volume a été insufflé). <br /><br />

							Les déterminants de la P<sub>plat</sub> sont donc la pression intra-alvéolaire de fin d’expiration, qui correspond à la PEP<sub>tot</sub>, le V<sub>t</sub> et la C<sub>rs</sub>. Puisque le SDRA est caractérisé par une diminution plus ou moins importante de la C<sub>rs</sub>, on comprend que, à PEP et V<sub>t</sub> identiques, la P<sub>plat</sub> augmente. Par ailleurs la réduction du V<sub>t</sub> est le plus souvent associée à une réduction de la P<sub>plat</sub> (cf. "Volume courant"). Dans l’étude ARMA de l’ARDS Network, en cas de P<sub>plat</sub> élevée, le V<sub>t</sub> était réduit par palier de 1 ml/kg de poids prédit par la taille (minimum 4 ml/kg) jusqu’à atteindre 30 cmH2O<sup>8</sup>.
                        </p></div>
            },
            {
                label: 'Définition de la pression motrice',
                content:
                    <div>
                        <p>
                            La pression motrice (DP, pour driving pressure) du système respiratoire correspond
                        	à la différence entre la P<sub>plat</sub> et la PEP<sub>tot</sub> (bien que certains auteurs utilisent la PEP réglée) :<br />
							DP = P<sub>plat</sub> - PEP<br /><br />
							Comme:<br />
                        	C<sub>rs</sub> = V<sub>t</sub> / (P<sub>plat</sub> - PEP) = V<sub>t</sub> / DP<br /><br />
							On peut donc écrire:<br />
                        	DP = V<sub>t</sub> / C<sub>rs</sub><br />
							L’intérêt de la DP est donc de rapporter le volume courant à la C<sub>rs</sub>. Or la C<sub>rs</sub> est elle-même corrélée au volume pulmonaire disponible pour recevoir la ventilation. La DP rapporte donc le volume insufflé à un reflet du volume disponible. Elle correspond donc à une estimation de la déformation (« strain ») et, plus elle est élevée, plus le risque de surdistension est important<sup>21</sup>.
                        </p></div>
            },
            {
                label: 'Mortalité et pression motrice',
                content:
                    <div>
                        <p>
                            En 2015, Amato et al. ont publié une étude qui rassemblait 3562 patients provenant de 9 essais randomisés portant sur l’évaluation de la PEP et du Vt dans le SDRA<sup>21</sup>. A l'aide d'un modèle statistique complexe (analyse de médiation), les auteurs ont montré que la DP (définie comme la différence entre la Pplat et la PEP réglée) était un facteur indépendant de mortalité au cours du SDRA. Cet effet était constaté même chez des patients bénéficiant d'une ventilation protectrice<sup>22</sup>. Depuis, plusieurs études et méta-analyses ont confirmé ces résultats<sup>5,15,23–30</sup>. <br /><br />

						Une valeur de DP supérieure à 14 cmH<sub>2</sub>O est généralement considérée comme le seuil au-delà duquel la mortalité augmente proportionnellement à la DP. Ce résultat a été confirmé par l'étude LUNG SAFE qui montrait qu'une DP inférieure à 14 cmH<sub>2</sub>O était associée à une mortalité moindre dans les SDRA modérés à sévères<sup>13</sup>. En l'état actuel des connaissances cependant, il n'est pas recommandé d'ajuster les réglages du ventilateur (V<sub>t</sub> et/ou PEP) uniquement en fonction de la DP<sup>16,31–33</sup>.<br /><br />

						Plus récemment, le concept de DP transpulmonaire, utilisant la pression œsophagienne, a été proposé pour optimiser la ventilation des patients présentant un SDRA afin de mieux évaluer le risque de surdistension indépendamment des propriétés mécaniques de la paroi thoracique (cf. "Pression œsophagienne")<sup>34,35</sup>.

                        </p></div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. 	Gattinoni L, Pesenti A. The concept of “baby lung.” <i>Intensive Care Med</i> 2005;31(6):776–784. <br /><br />
						2. 	Gattinoni L, Marini JJ, Pesenti A, Quintel M, Mancebo J, Brochard L. The “baby lung” became an adult. <i>Intensive Care Med</i> 2016;42(5):663–673.<br /><br />
						3. 	Silva PL, Rocco PRM. The basics of respiratory mechanics: ventilator-derived parameters. <i>Ann Transl Med</i> 2018;6(19):376. <br /><br />
						4. 	Barberis L, Manno E, Guérin C. Effect of end-inspiratory pause duration on plateau pressure in mechanically ventilated patients. <i>Intensive Care Med</i> 2003;29(1):130–134. <br /><br />
						5. 	Bellani G, Grassi A, Sosio S, Foti G. Plateau and driving pressure in the presence of spontaneous breathing. <i>Intensive Care Med</i> 2019;45(1):97–98. <br /><br />
						6. 	Sajjad H, Schmidt GA, Brower RG, Eberlein M. Can the Plateau Be Higher Than the Peak Pressure? <i>Ann Am Thorac Soc</i> 2018;15(6):754–759. <br /><br />
						7. 	Sosio S, Bellani G. Plateau Pressure during Pressure Control Ventilation. <i>AboutOpen</i> 2019;6(1):76–77. <br /><br />
						8. 	Acute Respiratory Distress Syndrome Network, Brower RG, Matthay MA, et al. Ventilation with lower tidal volumes as compared with traditional tidal volumes for acute lung injury and the acute respiratory distress syndrome. <i>N Engl J Med</i> 2000;342(18):1301–1308. <br /><br />
						9. 	Terragni PP, Rosboch G, Tealdi A, et al. Tidal hyperinflation during low tidal volume ventilation in acute respiratory distress syndrome. <i>Am J Respir Crit Care Med</i> 2007;175(2):160–166. <br /><br />
						10. 	Villar J, Pérez-Méndez L, Basaldúa S, et al. A Risk Tertiles Model for Predicting Mortality in Patients With Acute Respiratory Distress Syndrome: Age, Plateau Pressure, and PaO2/FIO2 at ARDS Onset Can Predict Mortality. <i>Respir Care</i> 2011;56(4):420–428. <br /><br />
						11. 	Hager DN, Krishnan JA, Hayden DL, Brower RG. Tidal Volume Reduction in Patients with Acute Lung Injury When Plateau Pressures Are Not High. <i>Am J Respir Crit Care Med</i> 2005;172(10):1241–1245. <br /><br />
						12. 	Walkey AJ, Goligher EC, Del Sorbo L, et al. Low Tidal Volume versus Non-Volume-Limited Strategies for Patients with Acute Respiratory Distress Syndrome. A Systematic Review and Meta-Analysis. <i>Ann Am Thorac Soc</i> 2017;14(Supplement_4):S271–S279. <br /><br />
						13. 	Laffey JG, Bellani G, Pham T, et al. Potentially modifiable factors contributing to outcome from acute respiratory distress syndrome: the LUNG SAFE study. <i>Intensive Care Med</i> 2016;42(12):1865–1876. <br /><br />
						14. 	Yasuda H, Sanui M, Nishimura T, et al. Optimal Upper Limits of Plateau Pressure for Patients With Acute Respiratory Distress Syndrome During the First Seven Days: A Meta-Regression Analysis. <i>J Clin Med Res</i> 2021;13(1):48–63. <br /><br />
						15. 	Villar J, Martín-Rodríguez C, Domínguez-Berrot AM, et al. A Quantile Analysis of Plateau and Driving Pressures: Effects on Mortality in Patients With Acute Respiratory Distress Syndrome Receiving Lung-Protective Ventilation. <i>Crit Care Med</i> 2017;45(5):843–850.<br /><br />
						16. 	Papazian L, Aubron C, Brochard L, et al. Formal guidelines: management of acute respiratory distress syndrome. <i>Ann Intensive Care</i> 2019;9(1):69. <br /><br />
						17. 	Fan E, Del Sorbo L, Goligher EC, et al. An Official American Thoracic Society/European Society of Intensive Care Medicine/Society of Critical Care Medicine Clinical Practice Guideline: Mechanical Ventilation in Adult Patients with Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2017;195(9):1253–1263.<br /><br />
						18. 	Griffiths MJD, McAuley DF, Perkins GD, et al. Guidelines on the management of acute respiratory distress syndrome. <i>BMJ Open Respir Res</i> 2019;6(1):e000420. <br /><br />
						19. 	Williams EC, Motta-Ribeiro GC, Melo MFV. Driving Pressure and Transpulmonary Pressure: How do we guide safe mechanical ventilation? <i>Anesthesiology</i> 2019;131(1):155–163. <br /><br />
						20. 	Sahetya S, Brower R. The Promises and Problems of Transpulmonary Pressure Measurements in ARDS. <i>Curr Opin Crit Care</i> 2016;22(1):7–13. <br /><br />
						21. 	Amato MBP, Meade MO, Slutsky AS, et al. Driving pressure and survival in the acute respiratory distress syndrome. <i>N Engl J Med</i> 2015;372(8):747–755. <br /><br />
						22. 	Sell RE, Malhotra A. Acute respiratory distress syndrome and the promise of driving pressure. <i>Respirol Carlton Vic</i> 2019;24(2):95–96. <br /><br />
						23. 	Aoyama H, Pettenuzzo T, Aoyama K, Pinto R, Englesakis M, Fan E. Association of Driving Pressure With Mortality Among Ventilated Patients With Acute Respiratory Distress Syndrome: A Systematic Review and Meta-Analysis. <i>Crit Care Med</i> 2018;46(2):300–306.<br /><br />
						24. 	Chen Z, Wei X, Liu G, et al. Higher vs. Lower DP for Ventilated Patients with Acute Respiratory Distress Syndrome: A Systematic Review and Meta-Analysis. <i>Emerg Med Int</i> 2019;4654705.<br /><br />
						25. 	Chiu L-C, Hu H-C, Hung C-Y, et al. Dynamic driving pressure associated mortality in acute respiratory distress syndrome with extracorporeal membrane oxygenation. <i>Ann Intensive Care</i> 2017;7(1):12.<br /><br />
						26. 	Magunia H, Haeberle HA, Henn P, et al. Early Driving Pressure Changes Predict Outcomes during Venovenous Extracorporeal Membrane Oxygenation for Acute Respiratory Distress Syndrome. <i>Crit Care Res Pract</i> 2020;2020:6958152. <br /><br />
						27. 	Gupta E, Awsare B, Hirose H, Cavarocchi N, Baram M. Don’t Drive Blind: Driving Pressure to Optimize Ventilator Management in ECMO. <i>Lung</i> 2020;198(5):785–792. <br /><br />
						28. 	Raymondos K, Dirks T, Quintel M, et al. Outcome of acute respiratory distress syndrome in university and non-university hospitals in Germany. Crit Care Lond Engl 2017;21(1):122. <br /><br />
						29. 	Guérin C, Papazian L, Reignier J, et al. Effect of driving pressure on mortality in ARDS patients during lung protective mechanical ventilation in two randomized controlled trials. <i>Crit Care Lond Engl</i> 2016;20(1):384.<br /><br />
						30. 	Bellani G, Grassi A, Sosio S, et al. Driving Pressure Is Associated with Outcome during Assisted Ventilation in Acute Respiratory Distress Syndrome. <i>Anesthesiology</i> 2019;131(3):594–604.<br /><br />
						31. 	Pelosi P, Ball L. Should we titrate ventilation based on driving pressure? Maybe not in the way we would expect. <i>Ann Transl Med</i> 2018;6(19):389.<br /><br />
						32. 	Pereira Romano ML, Maia IS, Laranjeira LN, et al. Driving Pressure-limited Strategy for Patients with Acute Respiratory Distress Syndrome. A Pilot Randomized Clinical Trial. <i>Ann Am Thorac Soc</i> 2020;17(5):596–604. <br /><br />
						33. 	Spinelli E, Grieco DL, Mauri T. A personalized approach to the acute respiratory distress syndrome: recent advances and future challenges. <i>J Thorac Dis</i> 2019;11(12):5619–5625. <br /><br />
						34. 	Barbas CSV, Palazzo RF. Should we titrate mechanical ventilation based on driving pressure?—yes. <i>Ann Transl Med</i> 2018;6(19):393.<br /><br />
						35. 	Sklar MC, Patel BK, Beitler JR, Piraino T, Goligher EC. Optimal Ventilator Strategies in Acute Respiratory Distress Syndrome. <i>Semin Respir Crit Care Med</i> 2019;40(1):81–93.
<br /><br />
                        </p></div>
            }
        ],
        Curarisation: [
            {
                label: 'Rationnel',
                content:
                    <div>
                        <p>Au cours du SDRA, l’hypoxie et l’hypercapnie sont responsables d’une augmentation de la commande ventilatoire (« respiratory drive ») elle-même responsable d’une augmentation du travail des muscles respiratoires. Les efforts ventilatoires importants peuvent s’accompagner de mécanismes physiopathologiques susceptibles d’aggraver les lésions pulmonaires au cours du SDRA. Ces mécanismes ont été rassemblés sous le terme de « <i>Patient-Self Inflicted Lung Injury</i> » (P-SILI). Ils correspondent principalement à :<sup>1,2</sup>.
                    </p>
                        <ul>
                            <li>L’augmentation de la pression transpulmonaire au cours des modes en pression exposant au risque de surdistension, </li>
                            <li>La survenue de double déclenchement délivrant le double du volume courant, </li>
                            <li>L’inhomogénéité de distribution de la pression transpulmonaire exposant les zones dépendantes à des phénomènes d’ouverture/fermeture cyclique des voies aériennes et au phénomène de « Pendelluft » (transfert intrapulmonaire de gaz depuis les zones non dépendantes vers les zones dépendantes).</li>
                            <li>L’augmentation de la pression transmurale vasculaire favorisant l’œdème.</li>
                        </ul>
                        <p>Ainsi, la prise en charge initiale du SDRA nécessite le plus souvent une sédation profonde et une curarisation transitoire.</p>
                        <p>Les curares permettent un meilleur contrôle des cycles et des volumes en réduisant les efforts inspiratoires et l’expiration active<sup>3</sup>. Ils pourraient ainsi permettre de limiter les risques de P-SILI<sup>4,5</sup>. L’administration de curares au cours du SDRA s’intègre comme une composante de la stratégie de ventilation pulmonaire protectrice au même titre que la limitation de la pression plateau, la réduction du volume courant et le recours au décubitus ventral.<br /><br />
				Néanmoins, la curarisation s’accompagne aussi d’une atrophie et d’une dysfonction diaphragmatique. Elle doit donc être de courte durée et réévaluée quotidiennement.

				</p></div>
            },
            {
                label: 'Etude Acurasys',
                content:

                    <div>
                        <p>Avant ACURASYS, deux études randomisées avaient déjà montré un bénéfice à la curarisation.
                        Les patients bénéficiant d’une curarisation précoce et transitoire (pour une durée de 48 heures)
                    avaient une augmentation significative du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub><sup>5</sup>
                    et une diminution de la réponse inflammatoire pulmonaire et systémique<sup>6,7</sup>. <br /><br />
                    Cette étude prospective, randomisée, multicentrique française est la principale à avoir montré un intérêt à la curarisation précoce et transitoire au cours du SDRA. Les patients inclus avaient un rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> {'<'}150 et une PEP {'>'}5 cmH<sub>2</sub>O<sup>8</sup>.<br /><br />
                    Il existait une amélioration du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> ainsi
                    qu'une diminution significative de la durée de ventilation mécanique et des lésions de barotraumatisme
                    chez les patients curarisés. Le Hazard Ratio pour la mortalité à J90 après ajustement sur divers
                    facteurs était de 0,68 (IC 95% 0,48-0,98), en faveur de la curarisation. Ce résultat s'expliquait notamment
                    par une nette diminution de la mortalité à J90 chez les patients avec un rapport
                    P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> {'<'} 120 (31 vs 45%)<sup>8</sup>.
                </p>
                    </div>
            },
            {
                label: 'Etude ROSE et méta-analyses',
                content:

                    <div>
                        <p>Il s'agit de la dernière étude en date à évaluer l’intérêt d'une curarisation dans le SDRA<sup>9</sup>. ROSE est une étude nord-américaine, randomisée, contrôlée en ouvert ayant inclus 501 patients dans le groupe cisatracurium et 505 dans le groupe contrôle. Il n'existait pas de différence concernant la mortalité à J90 (43 <i>vs</i>. 43%), la durée de ventilation ou la survenue de barotraumatisme. L'étude a été arrêtée précocement pour futilité. Cependant un biais d’inclusion a été discuté car 655 patients n’étaient pas inclus car le clinicien en charge avait déjà choisi d’introduire des curares. <br /><br />

				Plusieurs méta-analyses sont parues depuis 2019, incluant jusqu'à 7 études (dont ACURASYS et ROSE) et 1598 patients, avec des résultats hétérogènes concernant la mortalité<sup>10–16</sup>. . En revanche, toutes s'accordaient sur une amélioration du rapport
                P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> ainsi qu’une diminution du risque de barotraumatismes chez les patients
                traités par curare.
                </p></div>
            },
            {
                label: 'Recommandations actuelles',
                content:

                    <div>
                        <p>Les recommandations actuelles, publiées avant les résultats de
                        l'étude ROSE, proposent d'utiliser les curares en cas de SDRA avec un
                rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> {'<'}150 cmH<sub>2</sub>O.
                Les curares doivent être administrés de manière précoce, en perfusion continue, et pour une durée maximale de 48 heures avec une réévaluation quotidienne<sup>17–19</sup>. Dans l’étude ACURASYS, la proportion de patients avec neuromyopathie de réanimation n’était pas différente entre les deux groupes. Le nombre de jours sans ventilation mécanique était plus grand dans le groupe curares suggérant l’absence de dysfonction diaphragmatique avec un impact sur le sevrage lorsque les curares sont correctement utilisés. En revanche, les bénéfices et inconvénients à une curarisation plus longue n’ont pas été évalués.<br /><br />

				Le curare utilisé dans ACURASYS était le cisatracurium, à la dose de 37,5 mg/h après un bolus de 20mg. Cependant, une réduction de la posologie semble possible en adaptant la dose à l’aide d’un TOF (<i>train of four</i>)<sup>20</sup>. Il semble aussi possible d’utiliser l’atracurium plutôt que le cisatracurium, sans qu’il n’y ait de différence concernant l’oxygénation, la durée de ventilation ou la mortalité.
				<sup>21,22</sup>.
                </p></div>
            },
            {
                label: 'Références',
                content:

                    <div>
                        <p>1. Hraiech S, Yoshida T, Annane D, et al. Myorelaxants in ARDS patients. <i>Intensive Care Med</i> 2020;1–16. <br /><br />

                2. Carteaux G, Perier F, Maraffi T, et al. Patient self-inflicted lung injury : ce que le réanimateur doit connaître. <i>Médecine Intensive Réanimation</i> 2019;28(1):11–20. <br /><br />

                3. Guervilly C, Bisbal M, Forel JM, et al. Effects of neuromuscular blockers on transpulmonary pressures
                in moderate to severe acute respiratory distress syndrome. <i>Intensive Care Med</i> 2017;43(3):408–418. <br /><br />

                4. Bourenne J, Carvelli J, Coiffard B, et al. Comment j’utilise les curares dans le SDRA - Quels sont
                les modes d’action supposés des curares au cours du SDRA ? <i>Médecine Intensive Réanimation</i> 2019;28(1):32–37. <br /><br />

                5. Gainnier M, Roch A, Forel J-M, et al. Effect of neuromuscular blocking agents on gas exchange
                in patients presenting with acute respiratory distress syndrome. <i>Crit Care Med</i> 2004;32(1):113–119. <br /><br />

                6. Forel J-M, Roch A, Marin V, et al. Neuromuscular blocking agents decrease inflammatory response
                in patients presenting with acute respiratory distress syndrome. <i>Crit Care Med</i> 2006;34(11):2749–2757.  <br /><br />

                7. Papazian L, Forel J-M, Gacouin A, et al. Neuromuscular blockers in early acute respiratory distress
                syndrome. <i>N Engl J Med</i> 2010;363(12):1107–1116.  <br /><br />

                8. Hraiech S, Forel J-M, Guervilly C, et al. How to reduce cisatracurium consumption in ARDS patients:
                the TOF-ARDS study. <i>Ann Intensive Care</i> 2017;7(1):79.  <br /><br />

                9. Moore L, Kramer CJ, Delcoix-Lopes S, Modrykamien AM. Comparison of Cisatracurium Versus Atracurium
                in Early ARDS. <i>Respir Care</i> 2017;62(7):947–952.  <br /><br />

                10. Bouju P, Tadié J-M, Barbarot N, et al. Clinical assessment and train-of-four measurements in critically
                ill patients treated with recommended doses of cisatracurium or atracurium for neuromuscular blockade:
                a prospective descriptive study. <i>Ann Intensive Care</i> 2017;7(1):10.  <br /><br />

                11. National Heart, Lung, and Blood Institute PETAL Clinical Trials Network, Moss M, Huang DT, et al.
                Early Neuromuscular Blockade in the Acute Respiratory Distress Syndrome. <i>N Engl J Med</i> 2019;380(21):1997–2008.  <br /><br />

                12. Zheng Z, Jiang L, Zhang S, et al. Neuromuscular blocking agents for acute respiratory distress
                syndrome: an updated meta-analysis of randomized controlled trials. <i>Respir Res</i> 2020;21(1):23.  <br /><br />

                13. Tarazan N, Alshehri M, Sharif S, et al. Neuromuscular blocking agents in acute respiratory distress
                syndrome: updated systematic review and meta-analysis of randomized trials. <i>Intensive Care Med Exp</i> 2020;8(1):61.  <br /><br />

                14. Chang W, Sun Q, Peng F, Xie J, Qiu H, Yang Y. Validation of neuromuscular blocking agent use in acute
                respiratory distress syndrome: a meta-analysis of randomized trials. <i>Crit Care Lond Engl</i> 2020;24(1):54.  <br /><br />

                15. Hua Y, Ou X, Li Q, Zhu T. Neuromuscular blockers in the acute respiratory distress syndrome:
                A meta-analysis. <i>PloS One</i> 2020;15(1):e0227664.  <br /><br />

                16. Shao S, Kang H, Tong Z. Early neuromuscular blocking agents for adults with acute respiratory distress
                syndrome: a systematic review, meta-analysis and meta-regression. <i>BMJ Open</i> 2020;10(11):e037737.  <br /><br />

                17. Wei X-B, Wang Z-H, Liao X-L, Guo W-X, Qin T-H, Wang S-H. Role of Neuromuscular Blocking Agents in
                Acute Respiratory Distress Syndrome: An Updated Meta-Analysis of Randomized Controlled Trials. <i>Front Pharmacol</i> 2019;10:1637.  <br /><br />

                18. Ho ATN, Patolia S, Guervilly C. Neuromuscular blockade in acute respiratory distress syndrome:
                a systematic review and meta-analysis of randomized controlled trials. <i>J Intensive Care</i> 2020;8(1):12. <br /><br />

                19. Murray MJ, DeBlock H, Erstad B, et al. Clinical Practice Guidelines for Sustained Neuromuscular Blockade in
                the Adult Critically Ill Patient. <i>Crit Care Med</i> 2016;44(11):2079–2103. <br /><br />

                20. Papazian L, Aubron C, Brochard L, et al. Formal guidelines: management of acute respiratory distress syndrome.
                <i>Ann Intensive Care</i> 2019;9(1):69. <br /><br />

                21. Chiumello D, Brochard L, Marini JJ, et al. Respiratory support in patients with acute respiratory distress syndrome:
                an expert opinion. <i>Crit Care</i> 2017;21(1):240.  <br /><br />

				22. Bourenne J, Hraiech S, Roch A, Gainnier M, Papazian L, Forel J-M. Sedation and neuromuscular
                blocking agents in acute respiratory distress syndrome. <i>Ann Transl Med</i> 2017;5(14):291.<br /><br />
                        </p></div>
            }
        ],
        Decubitus: [
            {
                label: 'Effets physiopathologiques',
                content:
                    <div>
                        <p>La réponse au décubitus ventral (DV)  peut s'expliquer par différents phénomènes<sup>1</sup>:</p>
                        <ul>
                            <li>
                                En DV, la compliance de la paroi thoracique antérieure diminue ce qui limite le risque de surdistension des territoires antérieurs et favorise la redistribution de la ventilation vers les territoires postérieurs. De plus, le poids du cœur et des parties antérieures des poumons ne s’appliquent plus sur ces zones. En redistribuant le volume courant, le DV permet de réduire le risque de lésion induite par la ventilation mécanique.<sup>2</sup>
                            </li>
                            <li>
                                Le DV favorise le drainage des sécrétions bronchiques. Il diminue ainsi la survenue d’atélectasie.
                            </li>
                            <li>
                                A l’inverse, la perfusion pulmonaire est peu modifiée par le DV puisqu’elle reste prédominante dans les zones postérieures. Ainsi, il permet une homogénéisation de la ventilation et de la perfusion pulmonaire et donc une diminution du shunt intrapulmonaire<sup>2</sup>. Ceci peut s’accompagner d’une amélioration des échanges gazeux (qui est toutefois inconstante).
                            </li>
                            <li>
                                Au cours du SDRA, l’hypertension artérielle pulmonaire peut être responsable de la survenue d’un cœur pulmonaire aigue (prévalence {'≈'} 20%)<sup>3</sup>. Le DV améliore la fonction systolique du ventricule droit et réduit le risque de cœur pulmonaire aigue<sup>4</sup>. En effet le DV améliore les conditions de charge du ventricule droit, notamment par le biais d’une diminution de la pression des voies aériennes ainsi qu’une diminution de la P<sub>a</sub>CO<sub>2</sub>. En cas de cœur pulmonaire aigue préexistant, il a été observé une diminution de la dilatation du VD et une régression significative du septum paradoxal au cours du DV. A noter, le débit cardiaque est souvent préservé au cours du DV et le risque d’instabilité hémodynamique semble faible. Ainsi, une instabilité hémodynamique modérée ne doit pas constituer une contre-indication à la réalisation d’une séance de DV. Au contraire, en cas de précharge dépendance, le DV a un effet bénéfique sur l’hémodynamique<sup>5</sup>.
                            </li>
                        </ul>
                    </div>
            },
            {
                label: 'Indications',
                content:
                    <div>
                        <p>
                            Les dernières recommandations françaises recommandent l’utilisation du DV par séances d’au minimum 16 heures chez les patients présentant un SDRA modéré avec un rapport
                    P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub>&nbsp;{'≤'}&nbsp;150 malgré une F<sub>i</sub>O<sub>2</sub>&nbsp;{'≥'}&nbsp;60% et une PEP&nbsp;{'≥'}&nbsp;5 cmH<sub>2</sub>O<sup>6</sup>. En moyenne, dans l’étude PROSEVA, 4 séances étaient réalisées. Les séances de DV étaient arrêtées en cas de P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> ratio {'>'}
                    150 avec une F<sub>i</sub>O<sub>2</sub>&nbsp;{'≥'}&nbsp;60% et une PEP&nbsp;{'≤'}&nbsp;10 cmH<sub>2</sub>O<sup>7</sup>. Cependant, d’autres recommandations réservent la réalisation du DV au SDRA sévère et pour des séances d’au moins 12 heures<sup>8</sup>. <br /><br />

					L’existence d’un cœur pulmonaire aigue associé au SDRA peut aussi faire discuter la réalisation d’une séance de DV puisque le DV améliore les conditions de charge du ventricule droit<sup>4</sup>. <br /><br />

					Pour certains auteurs, la réponse au DV est évaluée sur l’oxygénation. Les patients sont dits répondeurs si la P<sub>a</sub>O<sub>2</sub> augmente d'au moins 20 mmHg ou si le rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> augmente d'au moins 20%<sup>9</sup>. On parle de réponse persistante si elle demeure après la remise en décubitus dorsal. Cependant, il convient de rappeler que :
				</p>

                        <ul>
                            <li>Le DV doit être réalisé dès que les critères sont réunis sans qu’il ne soit utile de prédire la réponse clinique au DV.
						</li>

                            <li>L’absence de réponse (en termes d’oxygénation ou d’amélioration de la compliance pulmonaire) ne doit pas faire arrêter la réalisation de séances de DV.
						</li>
                        </ul>

                        <p>En effet, l’objectif du DV est de minimiser les effets délétères de la ventilation mécanique (« <i>ventilator-induiced lung injury</i> », VILI) au même titre que limiter la pression de plateau et le volume courant. Ainsi, même en l’absence d’amélioration des échanges gazeux, le DV a un effet bénéfique sur la survie<sup>10,11</sup>.<br />
                        </p></div>
            },
            {
                label: 'Evaluation au cours du SDRA',
                content:

                    <div>
                        <p>
                            Les premiers essais randomisés évaluant l’effet du DV sur la mortalité étaient négatifs.<sup>11–14</sup> Ceci peut s’expliquer en partie par des effectifs faibles, l’absence de ventilation protectrice associée ou une durée de DV trop courte<sup>2</sup>. A l’inverse, l’étude PROSEVA a montré une réduction significative de la mortalité à J28 chez les patients bénéficiant de séances de DV compaté aux patients laissés en décubitus dorsal (16 vs 33%). Elle incluait des patients sévères (P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> {'≤'} 150) et la durée des DV
                    était prolongée (au moins 16 heures)<sup>7</sup>. Depuis, plusieurs
                    méta-analyses ont confirmé ces résultats<sup>15–19</sup>. <br /><br />

                    La plus récente des méta-analyses, parue en 2017, a inclus
                    8 essais et 2129 patients. Il existait une diminution
                    significative de la mortalité chez les patients en SDRA
                    modérée à sévère (RR 0,74; IC 95% 0,65-0,99) et ceux avec
                    une durée de DV supérieure à 12 heures (RR 0,74; IC 95% 0,56-0,99)<sup>20</sup>.<br /><br />

                    Concernant les effets indésirables, les escarres et l’obstruction de la sonde d’intubation sont plus fréquentes en DV. A ce titre, le DV doit faire l’objet d’une procédure opérationnelle de soins et d’une formation des équipes. En revanche, le DV ne semble pas associé à un risque d’extubation non programmée<sup>21</sup>. Lorsque la mise en proclive est bien conduite, le risque de retrait accidentel de voies veineuses ou artérielles est rare. Enfin, l’alimentation entérale doit être maintenue si elle est bien tolérée.
                    </p></div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Guérin C, Albert RK, Beitler J, et al. Prone position in ARDS patients:
                    why, when, how and for whom. <i>Intensive Care Med</i> 2020;1–12.<br /><br />
                    2. Guérin C. Prone positioning acute respiratory distress syndrome patients. <i>Ann Transl Med</i> 2017;5(14):289.<br /><br />
                    3. Boissier F, Katsahian S, Razazi K, et al. Prevalence and prognosis of cor
                    pulmonale during protective ventilation for acute respiratory distress syndrome.
                    <i>Intensive Care Med</i> 2013;39(10):1725–1733.<br /><br />
                    4. Vieillard-Baron A, Charron C, Caille V, Belliard G, Page B, Jardin F.
                    Prone positioning unloads the right ventricle
                    in severe ARDS. <i>Chest</i> 2007;132(5):1440–1446. <br /><br />
                    5. Jozwiak M, Teboul J-L, Anguel N, et al. Beneficial hemodynamic
                    effects of prone positioning in patients with acute respiratory
                    distress syndrome. <i>Am J Respir Crit Care Med</i> 2013;188(12):1428–1433. <br /><br />
                    6. Papazian L, Aubron C, Brochard L, et al. Formal guidelines: management
                    of acute respiratory distress syndrome. <i>Ann Intensive Care</i> 2019;9(1):69. <br /><br />
                    7. Guérin C, Reignier J, Richard J-C, et al. Prone positioning in severe
                    acute respiratory distress syndrome. <i>N Engl J Med</i> 2013;368(23):2159–2168. <br /><br />
                    8. Fan E, Del Sorbo L, Goligher EC, et al. An Official American Thoracic
                    Society/European Society of Intensive Care Medicine/Society of Critical
                    Care Medicine Clinical Practice Guideline: Mechanical Ventilation in Adult
                    Patients with Acute Respiratory Distress Syndrome.
                    <i>Am J Respir Crit Care Med</i> 2017;195(9):1253–1263. <br /><br />
                    9. Kallet RH. A Comprehensive Review of Prone Position in ARDS.
                    <i>Respir Care</i> 2015;60(11):1660–1687. <br /><br />
                    10. Scaramuzzo G, Ball L, Pino F, et al. Influence of Positive End-Expiratory Pressure Titration on the Effects of Pronation in Acute Respiratory Distress Syndrome: A Comprehensive Experimental Study. <i>Front Physiol</i> 2020;11:179.<br /><br />
                    11. Guerin C, Gaillard S, Lemasson S, et al. Effects of systematic
                    prone positioning in hypoxemic acute respiratory failure:
                    a randomized controlled trial. <i>JAMA</i> 2004;292(19):2379–2387. <br /><br />
                    12. Gattinoni L, Tognoni G, Pesenti A, et al.
                    Effect of prone positioning on the survival of patients
                    with acute respiratory failure. <i>N Engl J Med</i> 2001;345(8):568–573. <br /><br />
                    13. Mancebo J, Fernández R, Blanch L, et al. A multicenter trial
                    of prolonged prone ventilation in severe acute respiratory
                    distress syndrome. <i>Am J Respir Crit Care Med</i> 2006;173(11):1233–1239.<br /><br />
                    14. Taccone P, Pesenti A, Latini R, et al. Prone positioning
                    in patients with moderate and severe acute respiratory distress
                    syndrome: a randomized controlled trial. <i>JAMA</i> 2009;302(18):1977–1984. <br /><br />
                    15. Beitler JR, Shaefi S, Montesi SB, et al. Prone positioning reduces
                    mortality from acute respiratory distress syndrome in the low tidal
                    volume era: a meta-analysis. <i>Intensive Care Med</i> 2014;40(3):332–341.<br /><br />
                    16. Bloomfield R, Noble DW, Sudlow A. Prone position for acute respiratory failure in adults. <i>Cochrane Database Syst Rev</i> 2015;2015(11):CD008095.<br /><br />
                    17. Lee JM, Bae W, Lee YJ, Cho Y-J. The efficacy and safety of prone positional
                    ventilation in acute respiratory distress syndrome: updated study-level
                    meta-analysis of 11 randomized controlled trials.
                    <i>Crit Care Med</i> 2014;42(5):1252–1262. <br /><br />
                    18. Sud S, Friedrich JO, Adhikari NKJ, et al. Effect of prone positioning
                    during mechanical ventilation on mortality among patients with acute respiratory
                    distress syndrome: a systematic review and meta-analysis. <i>CMAJ</i> 2014;186(10):E381-390.<br /><br />
                    19. Abroug F, Ouanes-Besbes L, Dachraoui F, Ouanes I, Brochard L. An
                    updated study-level meta-analysis of randomised controlled trials
                    on proning in ARDS and acute lung injury. <i>Crit Care</i> 2011;15(1):R6. <br /><br />
                    20. Munshi L, Del Sorbo L, Adhikari NKJ, et al. Prone Position
                    for Acute Respiratory Distress Syndrome. A Systematic Review
                    and Meta-Analysis. <i>Ann Am Thorac Soc</i> 2017;14(Supplement_4):S280–S288.<br /><br />
                    21. Bringer M, Gay L, Gorun C, et al. Le décubitus ventral : de
                    la théorie à la pratique. <i>Méd Intensive Réa</i> 2019;28(1):52–59.<br /><br />
                </p></div>
            }
        ],
        PressionOeso: [
            {
                label: 'Rationnel',
                content:
                    <div>
                        <p>
                            La titration de la PEP selon le niveau de F<sub>i</sub>O<sub>2</sub> (protocole de l'ARDS Network) ou selon
                    la pression de plateau (protocole EXPRESS) ne
                    permet pas de tenir compte des propriétés mécaniques
                    de la paroi thoracique. De même, la pression
                    motrice (DP) reflète la mécanique ventilatoire du système respiratoire dans son ensemble mais ne permet pas d’individualiser la partie liée au poumon de celle liée à  la paroi thoracique. Pourtant dans certaines situations (patient obèse, œdème pariétal, augmentation de la pression intra-abdominale...), une diminution de la compliance de la paroi thoracique (C<sub>cw</sub> pour « chest wall ») peut conduire à une réduction de la compliance globale du système respiratoire (C<sub>rs</sub> pour « Respiratory System ») qui n’est pas forcément en rapport avec une baisse de la compliance pulmonaire (C<sub>L</sub> pour « Lung »)<sup>1,2</sup>. <br /><br />

                    La pression œsophagienne (P<sub>es</sub>) peut être utilisée comme un substitut de la pression pleurale  (P<sub>pl</sub>), elle-même équivalente à la pression de distension de la paroi thoracique (P<sub>cw</sub>). La pression transpulmonaire (P<sub>L</sub>), qui correspond à la pression qui distend les alvéoles, peut alors être calculée comme étant la différence entre la pression dans les voies aériennes (P<sub>aw</sub>)  et la P<sub>pl</sub><sup>1,2</sup>.<br /><br />

                    La mesure de la P<sub>es</sub> permet donc de distinguer la quantité de pression délivrée
                    au parenchyme pulmonaire (P<sub>L</sub>) de celle dissipée dans la paroi thoracique (P<sub>cw</sub>).
                    On comprend alors que la P<sub>L</sub> est un reflet direct du "stress pulmonaire"<sup>3</sup>.
                    Si P<sub>atm</sub> correspond à la pression atmosphérique, et si on considère que<br /> P<sub>es</sub> ≈ P<sub>pl</sub>, <br />on peut écrire<sup>1</sup>:<br />
                    P<sub>L</sub> = P<sub>aw</sub> - P<sub>pl</sub> = P<sub>aw</sub> - P<sub>es</sub><br />
                    P<sub>cw</sub> = P<sub>es</sub> - P<sub>atm</sub><br />
                    P<sub>rs</sub> = P<sub>aw</sub> - P<sub>atm</sub><br /><br />
                        </p></div>
            },
            {
                label: 'Procédure',
                content:
                    <div>
                        <p>
                            La procédure pour la pose d'une sonde œsophagienne est la suivante<sup>4</sup>:
                        </p>
                        <ul>
                            <li>
                                Introduire la sonde par le nez ou la bouche et la pousser jusque dans l'estomac (situé en moyenne à 50-60 cm).
                            </li>
                            <li>
                                Gonfler le ballon (volume déterminé par les recommandations du constructeur) et comprimer la région sous-xiphoïdienne pour vérifier sa position dans l'estomac (transmission de la pression).
                            </li>
                            <li>
                                Remonter progressivement le ballon jusqu'au tiers inférieur de l’œsophage (situé en moyenne à 35-45 cm), où apparaissent les artefacts cardiaques. Il s'agit de la position où l'œsophage est le plus proche de la plèvre.
                            </li>
                        </ul>
                        <p>
                            On comprend que la P<sub>es</sub> mesurée dépend de la
                            bonne position du ballon dans l’œsophage, du volume
                            d'air pour gonfler le ballon, de la présence d'un
                            épanchement pleural, de la position du malade (le
                            décubitus dorsal semble surestimer la P<sub>es</sub> d'environ 3 cmH<sub>2</sub>O)
                            ou de l'existence de spasmes œsophagiens<sup>3</sup>. <br /><br />

                            Pour s’assurer que la sonde est correctement positionnée dans l’œsophage, on peut réaliser une manoeuvre d’occlusion télé-expiratoire comme décrite par Baydur et al<sup>5</sup>. Au cours d’une pause
                            expiratoire, on mesure la variation de pression
                            enregistrée dans les voies aériennes
                            (ΔP<sub>aw</sub> = P<sub>aw</sub> max - P<sub>aw</sub> min) et dans l’œsophage
                            (ΔP<sub>oe</sub> = P<sub>oe</sub> max - P<sub>oe</sub> min) lors d’un effort
                            inspiratoire du patient. La sonde œsophagienne
                            est correctement positionnée (donc la P<sub>es</sub> peut
                            être considérée comme un substitut correcte
                            à la P<sub>pl</sub>) lorsque le ratio ΔP<sub>aw</sub>/ΔP<sub>es</sub> est proche de 1. <br /><br />

                            Chez le patient sans respiration spontanée, on réalise une manœuvre de compressions sternales au cours d'une pause expiratoire ou inspiratoire. De la même manière, on calcule ensuite le ratio ΔP<sub>aw</sub>/ΔP<sub>es</sub>qui doit être compris entre 0,8 et 1,2<sup>6</sup>.<br /><br />
                        </p>
                    </div>
            },
            {
                label: 'Mesure et interprétation de la pression transpulmonaire',
                content:
                    <div>
                        <p>
                            Deux méthodes existent pour calculer la P<sub>L</sub>.
                        La méthode directe (P<sub>L</sub> direct) correspond à la
                        différence entre la P<sub>aw</sub> et la P<sub>pl</sub>. La méthode alternative dérivée du ratio des élastances (P<sub>L</sub> el-related) repose sur le rapport des élastances du poumon et du système respiratoire<sup>7,8</sup>. Ainsi:<br /><br />

                        P<sub>L</sub> direct = P<sub>aw</sub> – P<sub>es</sub><br />
                        P<sub>L</sub> el-related = P<sub>aw</sub> x (E<sub>L</sub>/E<sub>rs</sub>) = P<sub>aw</sub> – [P<sub>aw</sub> x (E<sub>cw</sub>/E<sub>rs</sub>)]<br /><br />

                        Au cours du SDRA, la P<sub>L</sub> n’est pas répartie de manière homogène sur l’ensemble de la surface du poumon. Il existe en réalité un gradient de P<sub>L</sub> depuis les zones postérieures dépendantes vers les zones antérieures non dépendantes. La P<sub>L</sub> direct est une bonne approximation de la P<sub>L</sub> à hauteur de l’œsophage, c’est-à-dire en regard des zones dépendantes à risque de dérecrutement et d’atélectrauma. La P<sub>L</sub> el-related est une bonne approximation de la P<sub>L</sub> au niveau des zones antérieures non dépendantes, à risque de surdistension.<br /><br />

                        Ainsi, la P<sub>L</sub> expiratoire mesurée au cours d’une occlusion télé-expiratoire par la méthode directe (P<sub>L,e</sub> direct) renseigne sur le risque de dérecrutement et d’atelectrauma des zones dépendantes. Lorsque la P<sub>pl</sub> en fin d'expiration est supérieure à la PEP, il existe un risque de fermeture des petites voies aériennes et les alvéoles se collabent. Autrement dit, une P<sub>L,e</sub> {'<'} 0 cmH<sub>2</sub>O expose au risque de dérecrutement des zones dépendantes<sup>7</sup>.<br /><br />

						La P<sub>L</sub> inspiratoire mesurée au cours d’une occlusion télé-inspiratoire par la méthode du ratio des élastances (P<sub>L,i</sub> el-derived) renseigne sur le risque de surdisention des zones indépendantes. Lorsqu'elle est supérieure à 25 cmH<sub>2</sub>O (limite mesurée chez le sujet sain), le risque de surdistention alvéolaire dans ces zones augmente<sup>7</sup>.<br /><br />

                            La pression motrice transpulmonaire (DP<sub>L</sub>) représente le stress dynamique induit par le volume courant (qui prend en compte la compliance de la paroi thoracique). Chez les patients en SDRA, certains auteurs proposent de maintenir la DP<sub>L</sub> en dessous de 10-12 cmH<sub>2</sub>O<sup>9</sup>. On note :<br />
                        DP<sub>L</sub> = DP - (P<sub>es,i</sub> - P<sub>es,e</sub>)<br />
                        DP<sub>L</sub> = (P<sub>plat</sub> - PEP) - (P<sub>es,i</sub> - P<sub>es,e</sub>)<br /><br />
                    </p></div>
            },
            {
                label: 'Pression œsophagienne et réglage de la PEP',
                content:
                    <div>
                        <p>
                            Les essais EPVent 1 et 2 ont évalué une stratégie de PEP optimale basée sur la P<sub>es</sub> (avec pour objectif de maintenir la P<sub>L,e</sub> direct positive) ou sur le niveau de F<sub>i</sub>O<sub>2</sub> selon l'ARDS Network. EPVent 1 a montré une amélioration significative de l'oxygénation et de la compliance pulmonaire dans le groupe utilisant la P<sub>es</sub>. La mortalité était significativement réduite après ajustement sur le score APACHE II avec un risque relatif à 0,46 (IC 95% 0,19-1,00; p=0,049)<sup>10</sup>.  <br />

						Cependant, EPVent 2 n'a pas montré de bénéfice à l'utilisation de la P<sub>es</sub> pour guider le niveau de PEP puisque ni la mortalité ni la durée sans ventilation n'étaient différentes entre les deux groupes. Ces résultats peuvent en partie être expliqués par le fait que les niveaux de PEP utilisés dans le groupe contrôle étaient supérieurs à ceux de l'essai EPVent 1, conduisant à des P<sub>L,e</sub> plus fréquemment positives dans ce groupe<sup>11</sup>.

                    </p></div>
            },
            {
                label: 'Synthèse',
                content:
                    <div>
                        <p>
                            La mesure de la P<sub>es</sub> est simple et peu invasive.
                        Elle permet de séparer les composantes de la mécanique ventilatoire en séparant les pressions du poumon et de celles de la paroi thoracique. Au cours du SDRA, elle pourrait aider le clinicien à optimiser la ventilatoire et individualiser le choix de la PEP. La P<sub>es</sub> a aussi son intérêt chez le patient
                        présentant des cycles spontanés en permettant le
                        monitorage des efforts inspiratoires du patient
                        (<i>inspiratory muscle pressure</i>, P<sub>mus</sub>) et des
                        asynchronies patient-ventilateur<sup>3,4</sup>.
                    </p></div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Umbrello M, Chiumello D. Interpretation of the transpulmonary pressure in the critically ill patient. <i>Ann Transl Med</i> 2018;6(19):383. <br /><br />
                        2. Grieco DL, Chen L, Brochard L. Transpulmonary pressure: importance and limits. <i>Ann Transl Med</i> 2017;5(14):285. <br /><br />
                        3. Pham T, Telias I, Beitler JR. Esophageal Manometry. <i>Respir Care</i> 2020;65(6):772–792. <br /><br />
                        4. Mauri T, Yoshida T, Bellani G, et al. Esophageal and transpulmonary pressure in the clinical setting: meaning, usefulness and perspectives. <i>Intensive Care Med</i> 2016;42(9):1360–1373. <br /><br />
                        5. Baydur A, Behrakis PK, Zin WA, Jaeger M, Milic-Emili J. A simple method for assessing the validity of the esophageal balloon technique. <i>Am Rev Respir Dis</i> 1982;126(5):788–791. <br /><br />
                        6. Chiumello D, Consonni D, Coppola S, Froio S, Crimella F, Colombo A. The occlusion tests and end-expiratory esophageal pressure: measurements and comparison in controlled and assisted ventilation. <i>Ann Intensive Care</i> 2016;6(1):13. <br /><br />
                        7. Yoshida T, Amato MBP, Grieco DL, et al. Esophageal Manometry and Regional Transpulmonary Pressure in Lung Injury. <i>Am J Respir Crit Care Med</i> 2018;197(8):1018–1026. <br /><br />
                        8. Baedorf Kassis E, Loring SH, Talmor D. Should we titrate peep based on end-expiratory transpulmonary pressure?—yes. <i>Ann Transl Med</i> 2018;6(19):390.<br /><br />
                        9. Williams EC, Motta-Ribeiro GC, Melo MFV. Driving Pressure and Transpulmonary Pressure: How do we guide safe mechanical ventilation? <i>Anesthesiology</i> 2019;131(1):155–163. <br /><br />
                        10. Talmor D, Sarge T, Malhotra A, et al. Mechanical ventilation guided by esophageal pressure in acute lung injury. <i>N Engl J Med</i> 2008;359(20):2095–2104. <br /><br />
                        11. Beitler JR, Sarge T, Banner-Goodspeed VM, et al. Effect of Titrating Positive End-Expiratory Pressure (PEEP) With an Esophageal Pressure-Guided Strategy vs an Empirical High PEEP-Fio2 Strategy on Death and Days Free From Mechanical Ventilation Among Patients With Acute Respiratory Distress Syndrome: A Randomized Clinical Trial. <i>JAMA</i> 2019;321(9):846–857.<br /><br />
                    </p>
                    </div>
            }
        ]
    }
};

export default translationFR;
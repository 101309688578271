import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Navigation from "../../navigation/navigation";
import CustomSlider from '../../CustomSlider';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';

class Curarisation extends CustomComponent {

    constructor(props) {
        super(props);
        this.state = {
            curarisation: {
                pao2: "",
                fio2: "",
                pao2Fio2: "",
                //avantCurare: false,
                fio2AvantCurare: "",
                pao2AvantCurare: "",
                vtAvantCurare: "",
                pplatAvantCurare: "",
                peptAvantCurare: "",
                //pendantCurare: false,
                fio2PendantCurare: "",
                pao2PendantCurare: "",
                vtPendantCurare: "",
                pplatPendantCurare: "",
                peptPendantCurare: "",
                DPAvantCurare: "",
                DPPendantCurare: "",
                complianceAvantCurare: "",
                compliancePendantCurare: "",
                quotientAvantCurare: "",
                quotientPendantCurare: "",
                gainEnDP: "",
                gainEnCompliance: "",
                gainEnPao2Fio2: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let pao2Fio2 = Math.round(this.state.curarisation.pao2 / (this.state.curarisation.fio2 / 100));
        let DPAvantCurare = this.getDPAvantCurare();
        let DPPendantCurare = this.getDPPendantCurare();
        let complianceAvantCurare = this.getComplianceAvantCurare();
        let compliancePendantCurare = this.getCompliancePendantCurare();
        let quotientAvantCurare = this.getQuotientAvantCurare();
        let quotientPendantCurare = this.getQuotientPendantCurare();
        let gainEnDP = this.getDPPendantCurare() - this.getDPAvantCurare();
        let gainEnCompliance = this.getCompliancePendantCurare() - this.getComplianceAvantCurare();
        let gainEnPao2Fio2 = this.getQuotientPendantCurare() - this.getQuotientAvantCurare();

        this.setState(prevState => ({
            curarisation: {
                ...prevState.curarisation,
                pao2Fio2,
                DPAvantCurare,
                DPPendantCurare,
                complianceAvantCurare,
                compliancePendantCurare,
                quotientAvantCurare,
                quotientPendantCurare,
                gainEnDP,
                gainEnCompliance,
                gainEnPao2Fio2

            }
        }));
    }

    componentDidMount() {
        let curarisation = this.loadStorageValues('curarisation', this.state.curarisation);

        if (JSON.stringify(curarisation) !== JSON.stringify(this.state.curarisation))
            this.setState({
                curarisation: curarisation
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('curarisation', JSON.stringify(this.state.curarisation));
    }

    reset() {
        this.setState({
            curarisation: {
                pao2: "",
                fio2: "",
                pao2Fio2: "",
                //avantCurare: false,
                fio2AvantCurare: "",
                pao2AvantCurare: "",
                vtAvantCurare: "",
                pplatAvantCurare: "",
                peptAvantCurare: "",
                //pendantCurare: false,
                fio2PendantCurare: "",
                pao2PendantCurare: "",
                vtPendantCurare: "",
                pplatPendantCurare: "",
                peptPendantCurare: "",
                DPAvantCurare: "",
                DPPendantCurare: "",
                complianceAvantCurare: "",
                compliancePendantCurare: "",
                quotientAvantCurare: "",
                quotientPendantCurare: "",
                gainEnDP: "",
                gainEnCompliance: "",
                gainEnPao2Fio2: ""
            }
        },
            () => {
                localStorage.removeItem('curarisation');
            });
    }

    getDPAvantCurare = () => {
        return Math.round(this.state.curarisation.pplatAvantCurare - this.state.curarisation.peptAvantCurare);
    }

    getDPPendantCurare = () => {
        return Math.round(this.state.curarisation.pplatPendantCurare - this.state.curarisation.peptPendantCurare);
    }

    getComplianceAvantCurare = () => {
        return Math.round(this.state.curarisation.vtAvantCurare / (this.state.curarisation.pplatAvantCurare - this.state.curarisation.peptAvantCurare));
    }

    getCompliancePendantCurare = () => {
        return Math.round(this.state.curarisation.vtPendantCurare / (this.state.curarisation.pplatPendantCurare - this.state.curarisation.peptPendantCurare));
    }

    getQuotientAvantCurare = () => {
        return Math.round(this.state.curarisation.pao2AvantCurare / (this.state.curarisation.fio2AvantCurare / 100));
    }

    getQuotientPendantCurare = () => {
        return Math.round(this.state.curarisation.pao2PendantCurare / (this.state.curarisation.fio2PendantCurare / 100));
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="content">
                    <div className="sticky-top">
                        <h1>
                            <Link to={{ pathname: "/tools" }} className="back" />
                            <span className="title">Curarisation</span>
                            <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                        </h1>
                    </div>
                    <fieldset>
                        <legend className="center">Indication</legend>
                        <CustomSlider
                            title="PaO2"
                            unit={<div>mmHg</div>}
                            name="pao2"
                            handle={(e, val) => this.handleInputChange(e, val, 'curarisation')}
                            value={this.state.curarisation.pao2}
                            min="1" max="300"
                        />
                        <CustomSlider
                            title="FiO2"
                            unit={<div>%</div>}
                            name="fio2"
                            handle={(e, val) => this.handleInputChange(e, val, 'curarisation')}
                            value={this.state.curarisation.fio2}
                            min="21" max="100"
                        />
                        <div className="form-group">
                            <div className="specific2">
                                <div className="specific2-wrapper">
                                    <div className="specific2-inner">
                                        <div className="col">
                                            <p>PaO2/FiO2</p>
                                            <p className="blue result"><strong>{this.state.curarisation.pao2Fio2}</strong></p>
                                        </div>
                                        <div className="col">
                                            <p className="blue">
                                                {
                                                    (this.state.curarisation.pao2Fio2 <= 150) ?
                                                        "Indication à la curarisation du patient"
                                                        :
                                                        "Pas d'indication à la curarisation"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend className="center">Avant curatisation</legend>
                        <div className="form-group specific-row">
                            <div className="form-item">
                                <label>PaO2</label>
                                <input
                                    name="pao2AvantCurare"
                                    min="1" max="300"
                                    type="number"
                                    className="tiny"
                                    value={this.state.curarisation.pao2AvantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>mmHg</p>
                            </div>
                            <div className="form-item">
                                <label>FiO2</label>
                                <input
                                    name="fio2AvantCurare"
                                    min="1" max="100"
                                    className="tiny"
                                    type="number"
                                    value={this.state.curarisation.fio2AvantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>%</p>
                            </div>
                            <div className="form-item">
                                <label>Vt</label>
                                <input
                                    name="vtAvantCurare"
                                    min="1" max="600"
                                    className="tiny"
                                    type="number"
                                    value={this.state.curarisation.vtAvantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>mL</p>
                            </div>
                            <div className="form-item">
                                <label>Pplat</label>
                                <input
                                    name="pplatAvantCurare"
                                    min="1" max="50"
                                    className="tiny"
                                    type="number"
                                    value={this.state.curarisation.pplatAvantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                            <div className="form-item">
                                <label>PEPt</label>
                                <input
                                    name="peptAvantCurare"
                                    min="1" max="30"
                                    type="number"
                                    className="tiny"
                                    value={this.state.curarisation.peptAvantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend className="center">Après curatisation</legend>
                        <div className="form-group specific-row">
                            <div className="form-item">
                                <label>PaO2</label>
                                <input
                                    name="pao2PendantCurare"
                                    min="1" max="300"
                                    type="number"
                                    className="tiny"
                                    value={this.state.curarisation.pao2PendantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>mmHg</p>
                            </div>
                            <div className="form-item">
                                <label>FiO2</label>
                                <input
                                    name="fio2PendantCurare"
                                    min="1" max="100"
                                    className="tiny"
                                    type="number"
                                    value={this.state.curarisation.fio2PendantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>%</p>
                            </div>
                            <div className="form-item">
                                <label>Vt</label>
                                <input
                                    name="vtPendantCurare"
                                    className="tiny"
                                    min="1" max="600"
                                    type="number"
                                    value={this.state.curarisation.vtPendantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>mL</p>
                            </div>
                            <div className="form-item">
                                <label>Pplat</label>
                                <input
                                    name="pplatPendantCurare"
                                    min="1" max="50"
                                    className="tiny"
                                    type="number"
                                    value={this.state.curarisation.pplatPendantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                            <div className="form-item">
                                <label>PEPt</label>
                                <input
                                    name="peptPendantCurare"
                                    min="1" max="30"
                                    type="number"
                                    className="tiny"
                                    value={this.state.curarisation.peptPendantCurare}
                                    onChange={(e) => this.handleInputChange(e, null, 'curarisation')}
                                />
                                <p>cmH<sub>2</sub>0</p>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="form-group">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>Modifications avant/après</p>
                                    <div className="specific3-inner">
                                        <div className="col">
                                            <p><small>PaO2/FiO2</small></p>
                                            <p className="result"><strong>{(this.state.curarisation.gainEnPao2Fio2 > 0) ? "+" : ""}{this.state.curarisation.gainEnPao2Fio2}</strong></p>
                                        </div>
                                        <div className="col">
                                            <p><small>DP</small></p>
                                            <p className="result"><strong>{(this.state.curarisation.gainEnDP > 0) ? "+" : ""}{this.state.curarisation.gainEnDP}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>0</small></p>
                                        </div>
                                        <div className="col">
                                            <p><small>Crs</small></p>
                                            <p className="result"><strong>{(this.state.curarisation.gainEnCompliance > 0) ? "+" : ""}{this.state.curarisation.gainEnCompliance}</strong></p>
                                            <p className="blue"><small>mL/cmH<sub>2</sub>0</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div className="wrap-validation">
                        <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                        <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                    </div>
                </div>
                <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                    <div id="info-bloc-inner">
                        <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                        <h2>Définitions</h2>
                        <Accordion panels={t("panels", { returnObjects: true }).Curarisation} />
                    </div>
                </div>
                <Navigation />
            </div>
        );
    }
}

export default withTranslation()(withRouter(Curarisation));

import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

// Double Barre (Avant / Après)
export const DoubleBarChart = ({ barclass, legend, color, before, after }) => {
  const data_bar = [
    {
      "info": legend,
      "Avant": Number(before) || 0,
      "Après": Number(after) || 0
    }
  ]

  const percent = Number(Math.round(100 * (after - before) / before)) || 0;
  const evolution = (percent >=0 ? "+ " : "") + percent + "%"

  return (
    <div className={barclass}>
      <p className="percent">{evolution}</p>
      <ResponsiveBar
        data={data_bar}
        keys={['Avant', 'Après']}
        labelFormat = { d => <tspan className="singlebar_chart__value" y={-20}>{d}</tspan>}
        theme={{
          fontFamily: 'robotobold',
        }
        }
        indexBy="Temps"
        margin={{ top: 0, right: 0, bottom: 10, left: 0 }}
        padding={0.15}
        innerPadding={35}
        minValue={0}
        maxValue={200}
        groupMode="grouped"
        colors={[color]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisBottom={{
          tickSize: 0
        }}
        isInteractive={false}
        axisLeft={null}
        enableGridY={false}
        labelSkipWidth={36}
        labelSkipHeight={36}
        animate={false}
      />
    <p className="before-after">Avant | Après</p>
    <p className="doublebar_chart__label" style={{color: color}} dangerouslySetInnerHTML={{__html: legend}}/>
    </div>
  );
};

// Simple Barre avec Graduation
export const SingleChartBar = props => {
  let divider = 5
  if (props.maxValue % 4 === 0) divider = 4
  if (props.maxValue % 3 === 0) divider = 3
  const step = props.maxValue / divider
  const gridYValues = [...Array(divider + 1).keys()].map(n => n * step)

  return (
    <div className="singlebar_chart">
      <ResponsiveBar
        data = {[props]}
        labelFormat = { d => <tspan className="singlebar_chart__value" y={-20}>{d}</tspan>}
        margin = {{ top: 50, right: 0, bottom: 10, left: 50 }}
        colors = {[props.color]}
        minValue={props.value >= 0 ? 0 : props.value }
        maxValue={props.value > props.maxValue ? props.value : props.maxValue}
        padding = {0.5}
        axisBottom={{
          tickSize: 0,
          legend: null,
        }}
        isInteractive={false}
        axisLeft = {{
          tickSize: 0,
          format: value => (value % step === 0 ? <tspan x={6} y={-1} className="singlebar_chart__step">{value + " " + String.fromCharCode(8212) }</tspan> : "")
        }}
        gridYValues= {gridYValues}
        theme= {{
          fontSize: 14,
          textColor: "#606060",
          axis: {
            domain: {
              line: {
                strokeWidth: 1,
                stroke: '#606060'
              }
            }
          },
          grid: {
            line: {
              strokeWidth: 0
            }
          }
        }}
      />
      <p className="singlebar_chart__label" dangerouslySetInnerHTML={{__html: props.label}}/>
      {/* <p className="singlebar_chart__label">{props.label}</p> */}
    </div>
  )
}
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import Stepper from '../../Stepper';
import CustomComponent from '../../../customComponent';


class Capnometrie extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            capnometrie: {
                paco2: "",
                etco2: "",
                avdsfResult: "",
                paMoinsEtco2: "",
                petSurAco2: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let avdsfResult = this.getAvdsf();
        let paMoinsEtco2 = this.getPaMoinsEtco2();
        let petSurAco2 = this.getPetSuraco2();

        this.setState(prevState => ({
            capnometrie: {
                ...prevState.capnometrie,
                avdsfResult,
                paMoinsEtco2,
                petSurAco2
            }
        }));
    }

    componentDidMount() {
        let capnometrie = this.loadStorageValues('capnometrie', this.state.capnometrie);

        if (JSON.stringify(capnometrie) !== JSON.stringify(this.state.capnometrie))
            this.setState({
                capnometrie: capnometrie
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('capnometrie', JSON.stringify(this.state.capnometrie));
    }

    reset() {
        this.setState({
            capnometrie: {
                paco2: "",
                etco2: "",
                avdsfResult: "",
                paMoinsEtco2: "",
                petSurAco2: ""
            }
        },
            () => {
                localStorage.removeItem('capnometrie');
            });
    }

    getAvdsf = () => {
        const { paco2, etco2 } = this.state.capnometrie;
        return Math.round((paco2 - etco2) / paco2 * 100);
    }

    getPaMoinsEtco2 = () => {
        const { paco2, etco2 } = this.state.capnometrie;
        return (Math.round((paco2 - etco2) * 100) / 100).toFixed(1);
    }

    getPetSuraco2 = () => {
        const { paco2, etco2 } = this.state.capnometrie;
        return (Math.round(etco2 / paco2 * 100) / 100).toFixed(1);
    }

    render() {

        const panels = [
            {
                label: 'Définition de l\'AVDSf',
                content:
                    <div>
                        <p>
                            L'<i>alveolar dead space fraction</i> (AVDSf) est un reflet de l'espace mort (V<sub>d</sub>/V<sub>t</sub>) et plus globalement des anomalies de ventilation - perfusion. Il relie la pression partielle de CO<sub>2</sub> dans le sang artériel (P<sub>a</sub>CO<sub>2</sub>) avec la pression partielle de CO<sub>2</sub> en fin d'expiration (EtCO<sub>2</sub>) selon la formule suivante:
                        </p>
                        <p>
                            AVDSf = (P<sub>a</sub>CO<sub>2</sub>  - EtCO<sub>2</sub>) / P<sub>a</sub>CO<sub>2</sub><br /><br />
                            Qui peut aussi s'écrire:<br />
                            AVDSf = 1 - (EtCO<sub>2</sub> / P<sub>a</sub>CO<sub>2</sub>)
                        </p>
                        <p>
                            Son calcul est simple puisqu'il ne nécessite pas de connaitre la pression partielle moyenne en CO<sub>2</sub> dans les gaz expirés (P<sub>E</sub>CO<sub>2</sub>, obtenue grâce à une capnographie volumétrique). En effet, cette dernière est remplacée par la pression partielle en CO<sub>2</sub> en fin d'expiration (EtCO<sub>2</sub>, obtenue grâce à une capnométrie standard).
                        </p>
                    </div>
            },
            {
                label: 'AVDSf et mortalité',
                content:
                    <div>
                        <p>
                            Les principales données concernant l’AVDSf proviennent d’études pédiatriques. La médiane d’AVDSf chez les patients survivants au SDRA est comprise entre 0,12 et 0,18 alors qu’elle est comprise entre 0,21 et 0,31 chez les patients décédés<sup>1–4</sup>.
                        </p>
                    </div>
            },
            {
                label: <div>Ratio alvéolo-artériel en CO<sub>2</sub></div>,
                content:
                    <div>
                        <p>
                            Le ratio alvéolo-artériel en CO<sub>2</sub>, aussi écrit P(et/a)CO<sub>2</sub>, est un autre indice facile à calculer au lit du malade pour estimer l’importance de l'espace mort. En situation physiologique, sa valeur normale tend vers 1. Dans le SDRA, plus le P(et/a)CO<sub>2</sub> est bas et plus l'espace mort et la mortalité sont importants<sup>5</sup>.
                        </p>
                    </div>
            },
            {
                label: <div>Gradient alvéolo-artériel en CO<sub>2</sub></div>,
                content:
                    <div>
                        <p>
                            La différence - ou gradient - alvéolo-artérielle en CO<sub>2</sub>, aussi écrit P(a-et)CO<sub>2</sub>, est un autre indice facile à calculer au lit du malade pour estimer l'espace mort<sup>6,7</sup>. Chez le sujet sain la différence entre la P<sub>a</sub>CO<sub>2</sub> et l’EtCO<sub>2</sub> est négligeable et la P(a-et)CO<sub>2</sub> est de l’ordre de 3-5 mmHg.
                        </p>
                        <p>
                            A l’inverse, dans le SDRA où il existe d’importantes anomalies de la ventilation et de la perfusion pulmonaire, la P(a-et)CO<sub>2</sub>  peut augmenter à plus de 30 mmHg. Elle est plus élevée dans les formes modérées à sévères en comparaison aux SDRA légers<sup>8,9</sup>. C’est aussi un facteur prédictif de mortalité (15 mmHg chez les patients survivants <i>vs.</i> 20mmHg chez les patients décédés; p{'<'}0,05)<sup>10</sup>.<br />
                        </p>
                    </div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. 	Bhalla AK, Dong J, Klein MJ, Khemani RG, Newth CJ. The Association Between Ventilatory Ratio and Mortality in Children and Young Adults. <i>Respir Care</i> 2021;66(2):205–212. <br /><br />
                            2. 	Yehya N, Bhalla AK, Thomas NJ, Khemani RG. Alveolar Dead Space Fraction Discriminates Mortality in Pediatric Acute Respiratory Distress Syndrome. <i>Pediatr Crit Care Med</i> 2016;17(2):101–109. <br /><br />
                            3. 	Charron C, Repesse X, Bouferrache K, et al. PaCO2 and alveolar dead space are more relevant than PaO2/FiO2 ratio in monitoring the respiratory response to prone position in ARDS patients: a physiological study. <i>Crit Care</i> 2011;15(4):R175.<br /><br />
                            4. 	Ghuman AK, Newth CJL, Khemani RG. The association between the end tidal alveolar dead space fraction and mortality in pediatric acute hypoxemic respiratory failure. <i>Pediatr Crit Care Med</i> 2012;13(1):11–15. <br /><br />
                            5. 	Kallet RH, Lipnick MS. End-Tidal-to-Arterial Pco2 Ratio as Signifier for Physiologic Dead-Space Ratio and Oxygenation Dysfunction in Acute Respiratory Distress Syndrome. <i>Respiratory Care</i> 2020;66(2):263-268.<br /><br />
                            6. 	Kallet RH, Siobal MS. Measuring dead space: does it really matter? or, What are we waiting for? <i>Respir Care</i> 2010;55(3):350–352. <br /><br />
                            7. 	McSwain SD, Hamel DS, Smith PB, et al. End-tidal and arterial carbon dioxide measurements correlate across all levels of physiologic dead space. <i>Respir Care</i> 2010;55(3):288–293. <br /><br />
                            8. 	Brinton T, Yousuf T, Iskandar J, Nepal R, Mehta R, Hanif T. End-Tidal CO2 Correlation with PaCO2 in Acute Respiratory Distress Syndrome [Internet]. In: C49. RESPIRATORY FAILURE: CLINICAL AND TRANSLATIONAL ASPECTS OF VILI AND LUNG PROTECTIVE MV. American Thoracic Society; 2016 [cited 2021 Feb 24]. p. A5252–A5252.Available from: <a href="https://www.atsjournals.org/doi/abs/10.1164/ajrccm-conference.2016.193.1_MeetingAbstracts.A5252" target="_blank" rel="noreferrer">https://www.atsjournals.org/doi/abs/10.1164/ajrccm-conference.2016.193.1_MeetingAbstracts.A5252</a><br /><br />
                            9. 	Yousuf T, Brinton T, Murtaza G, et al. Establishing a gradient between partial pressure of arterial carbon dioxide and end-tidal carbon dioxide in patients with acute respiratory distress syndrome. <i>J Investig Med</i> 2017;65(2):338–341. <br /><br />
                            10. Abdalrazik FS, Elghonemi MO. Assessment of gradient between partial pressure of arterial carbon dioxide and end-tidal carbon dioxide in acute respiratory distress syndrome. <i>Egypt J Bronchol</i> 2019;13:170-175<br /><br />
                        </p>
                    </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/espmort" }} className="back" />
                        <span className="title">Capnométrie standard</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres</legend>
                    <Stepper
                        title="PaCO2"
                        unit={<div>mmHg</div>}
                        name="paco2"
                        handle={(e) => this.handleInputChange(e, null, 'capnometrie')}
                        value={this.state.capnometrie.paco2}
                        min="0" max="150"
                    />
                    <Stepper
                        title="EtCO2"
                        unit={<div>mmHg</div>}
                        name="etco2"
                        handle={(e) => this.handleInputChange(e, null, 'capnometrie')}
                        value={this.state.capnometrie.etco2}
                        min="0" max="100"
                    />
                    <div className="form-group">
                        <div className="specific3">
                            <div className="specific3-wrapper">
                                {/* keep <p></p> (Front) */}
                                <p></p>
                                <div className="specific3-inner">
                                    <div className="col">
                                        <p>AVDSf</p>
                                        <p className="result"><strong>
                                            {this.state.capnometrie.paco2 !== "" && this.state.capnometrie.etco2 !== "" ? <div>{this.getAvdsf()}%</div> : ""}</strong></p>
                                    </div>
                                    <div className="col">
                                        <p>P(a-et)CO2</p>
                                        <p className="result"><strong>{this.getPaMoinsEtco2()}</strong></p>
                                        <p className="blue">mmHg</p>
                                    </div>
                                    <div className="col">
                                        <p>P(et/a)CO2</p>
                                        <p className="result"><strong>
                                            {this.state.capnometrie.paco2 !== "" && this.state.capnometrie.etco2 !== "" ? <div>{this.getPetSuraco2()}</div> : ""}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(Capnometrie);

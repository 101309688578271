import React, { Component } from 'react';
import { motion } from "framer-motion";
import logo from '../../css/img/svg/logo.svg'

class loadScreen extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.props.history.push('/home');
        }, 2000);
    }

    render() {

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                <div className="loadAnimation">

                    <img src={logo} alt="logo sdrapps" />

                    <svg version="1.1" id="Calque_1" x="0px" y="0px"
                        viewBox="0 0 198.7 44.3">
                        <g>
                            <g transform="translate(-347 -550)">
                                <path className="st0" d="M351.2,587.6c0,0,5,0,12.7,0c3.5-27.9,21.9-30.6,21.9-30.6v11c0,0,3.4-0.1,11,0
    c-0.8,20.8,14.8,19.5,14.8,19.5s11.1,0,18,0c0,0,2.1-27.3,21.7-30.6v11h11.1c0,0-0.9,7.5,2.2,13.2c1.9,3.5,5.4,6.4,11.6,6.4
    c16.2,0,19.1,0,19.1,0s3.1-28,21.3-30.6c0.1,8.2,0,11,0,11h11.5c0,0-0.9,19.1,13.2,19.5"/>
                            </g>
                        </g>
                    </svg>

                </div>
            </motion.div>
        )
    }
}

export default loadScreen
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import CustomSlider from '../../CustomSlider';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';

class ShuntSelonO2 extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            shuntSelonO2: {
                hemoglobine: "",
                sao2: "",
                pao2: "",
                svco2: "",
                pvco2: "",
                paco2: "",
                fio2: "",
                pression: "",
                shunt: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let shunt = this.getResult();
        this.setState(prevState => ({
            shuntSelonO2: {
                ...prevState.shuntSelonO2,
                shunt
            }
        }));
    }

    componentDidMount() {
        let shuntSelonO2 = this.loadStorageValues('shuntSelonO2', this.state.shuntSelonO2);

        if (JSON.stringify(shuntSelonO2) !== JSON.stringify(this.state.shuntSelonO2))
            this.setState({
                shuntSelonO2: shuntSelonO2
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('shuntSelonO2', JSON.stringify(this.state.shuntSelonO2));
    }

    reset() {
        this.setState({
            shuntSelonO2: {
                hemoglobine: "",
                sao2: "",
                pao2: "",
                svco2: "",
                pvco2: "",
                paco2: "",
                fio2: "",
                pression: "",
                shunt: ""
            }
        },
            () => {
                localStorage.removeItem('shuntSelonO2');
            });
    }

    getResult = () => {
        const { hemoglobine, sao2, pao2, svco2, pvco2, fio2, pression, paco2 } = this.state.shuntSelonO2;
        //const cao2 = (Math.round((hemoglobine * 1.34 * sao2 + pao2 * 0.0031) * 100) / 100).toFixed(1);
        //const cvo2 = (Math.round((hemoglobine * 1.34 * svco2 + pvco2 * 0.0031) * 100) / 100).toFixed(1);
        //const autrePAO2 = (Math.round((fio2 * (pression - 47) - paco2 / 0.8) * 100) / 100).toFixed(1);
        //const cco2 = (Math.round((hemoglobine * 1.34 + autrePAO2 * 0.0031) * 100) / 100).toFixed(1);
        //return Math.round((cco2 - cao2) / (cco2 - cvo2) * 10);
        const cco2 = (Number(hemoglobine) * 1.34) + (0.003 * ((Number(pression) - 47) * (Number(fio2) / 100) - (Number(paco2) / 0.8)));
        const cao2 = (Number(hemoglobine) * 1.34 * (Number(sao2) / 100)) + (0.003 * Number(pao2));
        const cvo2 = (Number(hemoglobine) * 1.34 * (Number(svco2) / 100)) + (0.003 * Number(pvco2));
        return Math.round((cco2 - cao2) / (cco2 - cvo2) * 100);
    }

    render() {

        const panels = [
            {
                label: 'Calcul du shunt selon l’équation de Berggren',
                content:
                    <div>
                        <p>Le shunt intrapulmonaire (Q<sub>s</sub>/Q<sub>t</sub>) correspond au ratio du débit sanguin des zones shuntées (Q<sub>s</sub>) et du débit sanguin total (Q<sub>t</sub>)<sup>t</sup>:</p>
                        <p>Q<sub>s</sub>/Q<sub>t</sub> = (C<sub>c</sub>O<sub>2</sub> - C<sub>a</sub>O<sub>2</sub>) / (C<sub>c</sub>O<sub>2</sub> - C<sub>v</sub>O<sub>2</sub>)</p>
                        <br />
                        <p>Où C<sub>a</sub>O<sub>2</sub>, C<sub>v</sub>O<sub>2</sub> et C<sub>c</sub>O<sub>2</sub> correspondent respectivement aux contenus artériel, veineux mêlée et capillaire en oxygène.</p>
                        <br />
                        <p>La mesure du shunt nécessite en théorie l'utilisation d'un cathéter dans l'artère pulmonaire pour obtenir un prélèvement de sang veineux mêlé. On peut substituer ce dernier par la mesure du contenu en oxygène veineux central. L'utilisation d'une F<sub>i</sub>O<sub>2</sub> à 1 permet d'éliminer l'impact des unités alvéolaires avec V<sub>A</sub>/Q bas et ainsi, de calculer le shunt vrai<sup>1</sup>.</p>
                    </div>
            },
            {
                label: 'Déterminer les contenus en oxygène',
                content:
                    <div>
                        <p>Le contenu en oxygène est égal à la somme de l'oxygène combiné à l'hémoglobine (97%) et de l'oxygène dissous dans le plasma (3%). Il s'exprime en mL/dL et se calcule par la formule suivante<sup>2</sup>:</p>
                        <br />
                        <p>C<sub>a</sub>O<sub>2</sub> = [(Hb x 1,34) x (S<sub>a</sub>O<sub>2</sub>)] + (P<sub>a</sub>O<sub>2</sub> x 0,0031)</p>
                        <p>C<sub>v</sub>O<sub>2</sub> = [(Hb x 1,34) x (S<sub>v</sub>O<sub>2</sub>)] + (P<sub>v</sub>O<sub>2</sub> x 0,0031)</p>
                        <p>C<sub>c</sub>O<sub>2</sub> = [(Hb x 1,34) x (S<sub>c</sub>O<sub>2</sub>)] + (P<sub>c</sub>O<sub>2</sub> x 0,0031)</p>
                        <br />
                        <p>Où P<sub>a</sub>O<sub>2</sub>, P<sub>v</sub>O<sub>2</sub> et P<sub>c</sub>O<sub>2</sub> correspondent respectivement à la pression artérielle, veineuse mêlée et capillaire en oxygène. Le coefficient 1,34 correspond à la capacité de transport de l'hémoglobine (1g d'hémoglobine transporte 1,34 mL d’oxygène) et 0,0031 au coefficient de solubilité de l'oxygène (s'exprime en mL d'oxygène dissoute pour 100 mL de plasma par mmHg).</p>
                    </div>
            },
            {
                label: <div>Pression alvéolaire en O<sub>2</sub></div>,
                content:
                    <div>
                        <p>On voit que le calcul de la C<sub>c</sub>O<sub>2</sub> nécessite de connaître la P<sub>c</sub>O<sub>2</sub>. Celle-ci peut être estimée par la pression alvéolaire en oxygène (P<sub>A</sub>O<sub>2</sub>) puisque le gradient d'oxygène entre l'alvéole et un capillaire "idéal" est négligeable ({'<'}1 mmHg). La saturation capillaire en oxygène (S<sub>c</sub>O<sub>2</sub>) est égale à 1 (100%) puisqu'on suppose qu'elle est égale à la saturation alvéolaire en oxygène<sup>3</sup>.</p>
                        <br />
                        <p>On obtient la P<sub>A</sub>O2 en résolvant l'équation des gaz alvéolaires&nbsp;:<br />
                    P<sub>A</sub>O<sub>2</sub> = P<sub>i</sub>O<sub>2</sub> - (P<sub>a</sub>CO<sub>2</sub> / RQ)</p>
                        <br />
                        <p>Où P<sub>i</sub>O<sub>2</sub> est la pression partielle en O<sub>2</sub> dans les voies aériennes centrales et RQ est le quotient respiratoire qui correspond au ratio de la production de CO<sub>2</sub> et de la consommation d'O<sub>2</sub> (V<sub>CO2</sub>/V<sub>O2</sub>, par convention fixé à 0,8)<sup>3</sup>.</p>
                        <br />
                        <p>Avec P<sub>i</sub>O<sub>2</sub> = F<sub>i</sub>O<sub>2</sub> x (Pb - PH<sub>2</sub>O)</p>
                        <br />
                        <p>Où Pb et PH<sub>2</sub>O correspondent à la pression barométrique (760 mmHg au niveau de la mer) et à la pression partielle en vapeur d'eau (47 mmHg à 37°c).</p>
                    </div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Radermacher P, Maggiore SM, Mercat A. Fifty Years of Research in ARDS. Gas Exchange in Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2017;196(8):964–984. <br /><br />
                        2. Bigeleisen PE. Models of venous admixture. <i>Adv Physiol Educ</i> 2001;25(1–4):159–166. <br /><br />
                        3. Petersson J, Glenny RW. Gas exchange and ventilation–perfusion relationships in the lung. <i>European Respiratory Journal</i> 2014;44(4):1023–1041.
                    </p>
                    </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/shunt" }} className="back" />
                        <span className="title">Q<sub>s</sub>/Q<sub>t</sub> selon le contenu en o2</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres</legend>
                    <CustomSlider
                        title="Hémoglobine"
                        unit={<div>g/dL</div>}
                        name="hemoglobine"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.hemoglobine}
                        min="0" max="20"
                        step="0.1"
                    />
                    <CustomSlider
                        title="SaO2"
                        unit={<div>%</div>}
                        name="sao2"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.sao2}
                        min="0" max="100"
                    />
                    <CustomSlider
                        title="PaO2"
                        unit={<div>mmHg</div>}
                        name="pao2"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.pao2}
                        min="1" max="300"
                    />
                    <CustomSlider
                        title="SvcO2"
                        unit={<div>%</div>}
                        name="svco2"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.svco2}
                        min="0" max="100"
                        step="0.1"
                    />
                    <CustomSlider
                        title="PvcO2"
                        unit={<div>mmHg</div>}
                        name="pvco2"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.pvco2}
                        min="0" max="300"
                    />
                    <CustomSlider
                        title="PaCO2"
                        unit={<div>mmHg</div>}
                        name="paco2"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.paco2}
                        min="0" max="150"
                    />
                    <CustomSlider
                        title="FiO2"
                        unit={<div>%</div>}
                        name="fio2"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.fio2}
                        min="0" max="100"
                    />
                    <CustomSlider
                        title="Pression barométrique"
                        unit={<div>mmHg</div>}
                        name="pression"
                        handle={(e, val) => this.handleInputChange(e, val, 'shuntSelonO2')}
                        value={this.state.shuntSelonO2.pression}
                        min="760" max="1000"
                        step="10"
                    />
                    <div className="form-group type-slider">
                        <div className="specific3">
                            <div className="specific3-wrapper">
                                <p>Q<sub>s</sub>/Q<sub>t</sub></p>
                                <div className="specific4-inner">
                                    <div className="col">
                                        {!isNaN(this.getResult()) && <div>
                                            <p className="result"><strong>{this.getResult()}%</strong></p>
                                            <p className="blue">
                                                {this.getResult() <= 5 ?
                                                    "Shunt physiologique !"
                                                    :
                                                    "Augmentation du shunt !"}
                                            </p>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(ShuntSelonO2);
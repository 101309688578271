import React, { Component } from 'react';
import Navigation from "../navigation/navigation";
import NavAbout from "../navigation/navAbout";
import reanimation from "../../img/reanimation.png";
import chu_amiens from "../../img/chu_amienspicardie_logo.png";
import amiens_cluster from "../../img/amiens_cluster_logo.png";
import ars from "../../img/ars_hdf_logo.svg";
import talan from "../../img/talan_group.svg";
import hdf from "../../img/region_hdf_logo.svg";

class Team extends Component {
    render() {
        return (
            <div>
                <div className="content team">
                    <NavAbout />
                    <legend className="center">PORTEURS DU PROJET</legend>
                    <p><strong>Clément BRAULT</strong><br />
                    <i>Assistant spécialiste dans le service de Médecine Intensive - Réanimation du Centre Hospitalier Universitaire d’Amiens.</i>
                    </p>
                    <p><strong>Yoann ZERBIB</strong><br />
                    <i>Praticien Hospitalier universitaire dans le service de Médecine Intensive - Réanimation du Centre Hospitalier Universitaire d’Amiens et enseignant à la faculté de Médecine de l’Université de Picardie Jules Verne.</i>
                    </p>
                    <div className="wrap-img">
                        <img src={reanimation} alt="service réanimation chu amiens picardie" />
                    </div>
                    <legend className="center">COORDINATEUR D’EXPERTS</legend>
                    <p><strong>Guillaume CARTEAUX</strong><br />
                    <i>Professeur des Universités à l’UPEC – Praticien Hospitalier le service de Médecine Intensive - Réanimation de l’hôpital Henri Mondor (AP-HP, Créteil) ; responsable scientifique du département d’innovation numérique et de simulation en santé (INESS) à la faculté de santé de l’UPEC; chercheur à l’Institut Mondor de Recherche Biomédicale au sein de l’équipe 13 de l’unité Inserm U955 et du groupe de recherche clinique CARMAS ; membre de la commission de pédagogie du Collège des Enseignants de Médecine Intensive Réanimation (CEMIR).</i>
                    </p>
                    <legend className="center">GROUPE D’EXPERTS</legend>
                    <p><strong>François BELONCLE</strong><br />
                    <i>Maitre de Conférence des Universités à l’Université d'Angers – Praticien Hospitalier dans le département de Médecine Intensive - Réanimation et Médecine Hyperbare du Centre Hospitalier Universitaire d’Angers ; membre de la commission pédagogique du Collège des Enseignants de Médecine Intensive - Réanimation</i>
                    </p>
                    <p><strong>Rémi COUDROY</strong><br />
                    <i>Maitre de Conférence des Universités à l’Université de Poitiers – Praticien Hospitalier dans le service de Médecine Intensive - Réanimation du Centre Hospitalier Universitaire de Poitiers ; chercheur à l’INSERM au sein du CIC1402 groupe ALIVE à l’Université de Poitiers.</i>
                    </p>
                    <p><strong>Martin DRES</strong><br />
                    <i>Praticien Hospitalier dans le service de Médecine Intensive - Réanimation - Eole de l’Hôpital Pitié-Salpêtrière (AP-HP, Paris) ; chercheur à l’INSERM au sein de l’UMRS 1158, neurophysiologie respiratoire expérimentale et clinique de Sorbonne Université et Enseignant à la faculté de Médecine Sorbonne Université ; membre du Collège des Enseignants de Médecine Intensive Réanimation.</i>
                    </p>
                    <p><strong>Tài PHAM</strong><br />
                    <i>Praticien Hospitalier dans le service de Médecine Intensive - Réanimation du Centre Hospitalier Universitaire de Bicêtre (AP-HP, Kremlin-Bicêtre) ; chercheur au DMU 4 CORREVE Maladies du Cœur et des Vaisseaux, FHU Sepsis, Groupe de Recherche Clinique CARMAS, Le Kremlin-Bicêtre ; chercheur à l’université Paris-Saclay, UVSQ, Université Paris-Sud, Inserm U1018, Equipe d’Epidémiologie respiratoire intégrative, CESP, 94807, Villejuif.</i>
                    </p>

                    <legend className="center">Développée par</legend>
                    <div className="wrap-img">
                        <img src={talan} alt="logo talan" />
                    </div>

                    {/* <legend className="center">Remerciements</legend>

                    <p>
                        <strong>Jean-Denis Blanc</strong><br />
                    </p>
                    <p>
                        <strong>Nicolas Brun</strong><br />
                    </p>
                    <p>
                        <strong>Thibaud Cappelle</strong><br />
                    </p>
                    <p>
                        <strong>Simon Dalvy</strong><br />
                    </p>
                    <p>
                        <strong>Thomas Dazeniere</strong><br />
                    </p>
                    <p>
                        <strong>Vincent Foulon</strong><br />
                    </p>
                    <p>
                        <strong>Jean-Paul Gouello</strong><br />
                    </p>
                    <p>
                        <strong>Sarah Louvet</strong><br />
                    </p>
                    <p>
                        <strong>Magot Mauduit</strong><br />
                    </p>
                    <p>
                        <strong>Anne Paris</strong><br />
                    </p>
                    <p>
                        <strong>Antoine Schoux</strong><br />
                    </p>
                    <p>
                        <strong>Thierry Schuller</strong><br />
                    </p>
                    <p>
                        <strong>Nathanaël Sorin-Richez</strong><br />
                    </p>
                    <p>
                        <strong>Cyril Wolfangel</strong><br />
                    </p> */}

                    <legend className="center">Partenariats</legend>

                    <div className="grid-4-items">
                        <div className="wrap-img">
                            <img src={chu_amiens} alt="CHU d'Amiens" />
                        </div>
                        <div className="wrap-img">
                            <img src={ars} alt="Agence Régional de Santé" />
                        </div>
                        <div className="wrap-img">
                            <img src={amiens_cluster} alt="Amiens Cluster" />
                        </div>
                        <div className="wrap-img">
                            <img src={hdf} alt="Région Hauts-de-France" />
                        </div>
                    </div>
                </div>
                <Navigation />
            </div>
        )
    }
}

export default Team;


import Navigation from "../../navigation/navigation";
import {withRouter, Link} from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';


class Fermeture extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = { 
            fermeture: {
                pepTotale: "",
                aop: "",
                resultat: ""
            },
            infos: false
        }
    }

    runCalculs() {
        const {pepTotale, aop} = this.state.fermeture;
        let resultat = "";
        if (pepTotale> aop) {
            resultat = "Pas de risque de fermeture des voies aériennes";
        } else {
            resultat = "Attention PEPtotale < AOP. Risque d'erreur de mesure de la Pplat et de bronchiolotrauma. Discuter l'augmentation de la PEP (au-dessus de l'AOP) puis vérifier la pression de plateau.";
        }

        this.setState(prevState => ({
            fermeture: {
                ...prevState.fermeture,
                resultat
            }
        }));

    }

    componentDidMount() {
        let fermeture = this.loadStorageValues('fermeture', this.state.fermeture);

        if(JSON.stringify(fermeture) !== JSON.stringify(this.state.fermeture))
            this.setState({
                fermeture: fermeture
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.infos === this.state.infos)
            localStorage.setItem('fermeture', JSON.stringify(this.state.fermeture));
    }

    reset() {
        this.setState({            
            fermeture: {
                pepTotale: "",
                aop: "",
                resultat: ""
            }
        },
        () => {
            localStorage.removeItem('fermeture');
        });
    }

    render() { 

        const panels = [
            {
                label: 'Définition',
                content:
                    <div>
                        <p>La fermeture des voies aériennes (ou <i>airway closure</i>) est un phénomène physiologique inconstant au cours duquel les voies aériennes distales (bronchioles terminales) sont fermées quand la pression dans les voies aériennes est inférieure à un certain niveau de pression appelé pression d'ouverture des voies aériennes (ou <i>airway  opening pressure</i>, AOP). En conséquence, les alvéoles ne sont plus en continuité avec les voies aériennes<sup>1,2</sup>.</p>
                        <br/>
                        <p>La fermeture des voies aériennes peut s’étendre à l’ensemble de l’arbre bronchique, on parle de fermeture complète (ou totale) des voies aériennes. Les causes de cette fermeture des voies aériennes ne sont pas clairement définies<sup>3</sup>. Sa prévalence est variable selon les études (0 à 52%) et lorsqu’elle est présente, les niveaux d'AOP peuvent parfois être élevés (jusqu'à 20 cmH<sub>2</sub>O)<sup>1,4–8</sup>.</p>
                    </div>
            },
            {
                label: 'Conséquences probables',
                content:
                    <div>
                        <ul>
                            <li>Interférence avec la mesure des pressions en fin d'expiration (entrainant une erreur dans le calcul de la pression motrice) et du volume expiré (ce qui engendre une erreur dans le calcul du R/I ratio)<sup>9,10</sup>.</li>
                            <li>Apparition de lésions bronchiolaires induites par la ventilation mécaniques (VILI) en raison des phénomènes d'ouvertures-fermetures répétées à chaque cycle respiratoire des bronchioles terminales (bronchiolotrauma) quand la PEP est inférieure à l’AOP<sup>9–11</sup>.</li>
                            <li>La fermeture des voies aériennes favorise aussi la non-concordance de la ventilation et la perfusion pulmonaire puisque des zones (alvéoles) perfusées ne sont plus ventilées<sup>9-10</sup>.</li>
                        </ul>
                        <br/>
                        <p>Pour éviter ces effets délétères, certains auteurs préconisent d’ajuster le niveau de PEP au-dessus de l’AOP<sup>1,2</sup>. Ainsi, une AOP inférieure à 5 cmH<sub>2</sub>O est rarement problématique puisque le niveau de PEP appliqué chez le patient sous ventilation mécanique est pratiquement toujours supérieur ou égal à 5 cmH<sub>2</sub>O. </p>
                    </div>
            },
            {
                label: 'Diagnostic',
                content:
                    <div>
                        <p><a rel="noreferrer" href="https://www.youtube.com/watch?v=Ow-XHPgxpTk" target="_blank">Comment mesurer l'AOP ?</a></p>
                        <br/>
                        <p>Différentes méthodes ont été décrites pour détecter l'<i>airway closure</i> et calculer l'AOP.<sup>12-14</sup> La plus simple est d'utiliser la courbe de temps-pression au cours d'une insufflation à bas débit (5 L/min) avec une PEP à 0-5 cmH<sub>2</sub>O. Ceci nécessite le plus souvent de baisser la fréquence respiratoire (8 cycles/min) et de modifier le rapport temps inspiratoire/expiratoire pour permettre la baisse du débit inspiratoire à 5 L/min. </p>
                        <br/>
                        <p>En l’absence d’AOP, la pression des voies aériennes augmente de manière linéaire au cours du temps et sa pente est fonction de la compliance du système respiratoire. En cas d’AOP on observe une pente très élevée en début de cycle (correspondant à la compliance du circuit du ventilateur puisque les voies aériennes sont fermées), suivi d’une diminution brusque de pente qui devient fonction de la compliance du système respiratoire. Le niveau de pression des voies aériennes au point de rupture de pente correspond à l’AOP<sup>1</sup>. </p>
                        <br/>
                        <p>L’analyse de la courbe pression-volume au cours d’une insufflation à bas débit permet aussi de détecter ce phénomène. En cas d'<i>airway closure</i>, la partie initiale de la courbe a une pente très faible, correspondant à la compliance du circuit du ventilateur (2-3 mL/cmH<sub>2</sub>O). Ensuite, lorsque le niveau de pression dépasse l’AOP, la pente de la courbe augmente brutalement et le recrutement alvéolaire commence. Le niveau de pression au point d’inflexion correspond à l’AOP<sup>1</sup>.</p>
                    </div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Chen L, Del Sorbo L, Grieco DL, et al. Airway Closure in Acute Respiratory Distress Syndrome: An Underestimated and Misinterpreted Phenomenon. <i>Am J Respir Crit Care Med</i> 2018;197(1):132–136. <br/><br/>
                            2. Chen L, Del Sorbo L, Grieco DL, et al. Potential for Lung Recruitment Estimated by the Recruitment-to-Inflation Ratio in Acute Respiratory Distress Syndrome. A Clinical Trial. <i>Am J Respir Crit Care Med</i> 2020;201(2):178–187. <br/><br/>
                            3. Coudroy R, Lu C, Chen L, Demoule A, Brochard L. Mechanism of airway closure in acute respiratory distress syndrome: a possible role of surfactant depletion. <i>Intensive Care Med</i> 2019;45(2):290–291. <br/><br/>
                            4. Haudebourg A-F, Perier F, Tuffet S, et al. Respiratory Mechanics of COVID-19- versus Non-COVID-19-associated Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2020;202(2):287–290. <br/><br/>
                            5. Pan C, Chen L, Lu C, et al. Lung Recruitability in SARS-CoV-2 Associated Acute Respiratory Distress Syndrome: A Single-center, Observational Study. <i>Am J Respir Crit Care Med</i> 2020;<br/><br/>
                            6. Beloncle FM, Pavlovsky B, Desprez C, et al. Recruitability and effect of PEEP in SARS-Cov-2-associated acute respiratory distress syndrome. <i>Ann Intensive Care</i> 2020;10(1):55. <br/><br/>
                            7. Guérin C, Terzi N, Galerneau L-M, et al. Lung and chest wall mechanics in patients with acute respiratory distress syndrome, expiratory flow limitation, and airway closure. <i>J Appl Physiol (1985)</i> 2020;128(6):1594–1603. <br/><br/>
                            8. Coudroy R, Vimpere D, Aissaoui N, et al. Prevalence of Complete Airway Closure According to Body Mass Index in Acute Respiratory Distress Syndrome. <i>Anesthesiology</i> 2020;133(4):867–878. <br/><br/>
                            9. Hedenstierna G, Chen L, Brochard L. Airway closure, more harmful than atelectasis in intensive care? <i>Intensive Care Med</i> 2020;46(12):2373–2376. <br/><br/>
                            10. Chen L, Del Sorbo L, Fan E, Brochard L. Reply to Borges: The Plausibility of “Bronchiolotrauma.” <i>Am J Respir Crit Care Med</i> 2018;197(8):1087–1088. <br/><br/>
                            11. Rouby JJ, Lherm T, Martin de Lassale E, et al. Histologic aspects of pulmonary barotrauma in critically ill patients with acute respiratory failure. <i>Intensive Care Med</i> 1993;19(7):383–389. <br/><br/>
                            12. Malatesta C, Mele F, Menga LS, Bello G, Grieco DL, Antonelli M. Airway closure and fiberoptic evidence of bronchial collapse during the acute respiratory distress syndrome. <i>Intensive Care Med</i> 2019;45(12):1838–1839. <br/><br/>
                            13. Rozé H, Boisselier C, Bonnardel E, et al. Electrical Impedance Tomography to Detect Airway Closure Heterogeneity in Asymmetrical Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2021;203(4):511–515. <br/><br/>
                            14. Sun X-M, Chen G-Q, Zhou Y-M, Yang Y-L, Zhou J-X. Airway Closure Could Be Confirmed by Electrical Impedance Tomography. <i>Am J Respir Crit Care Med</i> 2018;197(1):138–141.<br /><br />
                        </p>
                    </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{pathname: "/pep"}} className="back" />
                        <span className="title">Airway closure</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos)? false : true})} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres mesurés</legend>
                    <div className="form-group">
                        <label>PEP totale</label>
                        <div className="info-input">
                            <input
                                name="pepTotale"
                                min="1" max="30"
                                type="number"
                                className="tiny"
                                value={this.state.fermeture.pepTotale}
                                onChange={(e) => this.handleInputChange(e, null, 'fermeture')} />
                            <div className="info"> cmH<sub>2</sub>O</div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Airway opening pressure</label>
                        <div className="info-input">
                            <input
                                name="aop"
                                min="1" max="30"
                                type="number"
                                className="tiny"
                                value={this.state.fermeture.aop}
                                onChange={(e) => this.handleInputChange(e, null, 'fermeture')} />
                            <div className="info"> cmH<sub>2</sub>O</div>
                        </div>
                    </div>

                </fieldset>

                <div className="form-group">
                    <div className="specific2">
                        <div className="specific2-wrapper">
                            {/* <p>Résultats</p> */}
                            {this.state.fermeture.pepTotale >= this.state.fermeture.aop ? <div>
                                    <p className="blue"><strong>Pas de risque de fermeture des voies aériennes</strong></p>
                                </div> : <div>
                                    <p className="blue"><strong>Risque de fermeture des voies aériennes.<br/>Discuter l'augmentation de la PEP (au-dessus de l'AOP) <br/>Puis vérifier la mécanique ventilatoire.</strong></p>
                                </div>}
                        </div>
                    </div>
                </div>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }}className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos)? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos)? false : true})} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={ panels } />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}
 
export default withRouter(Fermeture);
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Switch from '../../../components/switch';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';


class PepIntrinseque extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            pepIntrinseque: {
                pepTotal: "",
                pepExtern: "",
                absorptionPEP: false,
                limitationFluxExpiratoire : false,
                AutoPEP: "",
                PEEPabsorber: "NON"

            },
            infos: false
        }
    }

    reset() {
        this.setState({
            pepIntrinseque: {
                pepTotal: "",
                pepExtern: "",
                absorptionPEP: false,
                limitationFluxExpiratoire : false,
                AutoPEP: "",
                PEEPabsorber: "NON"
            }
        },
            () => {
                localStorage.removeItem('pepIntrinseque');
            });
    }

    componentDidMount() {
        let pepIntrinseque = this.loadStorageValues('pepIntrinseque', this.state.pepIntrinseque);

        if (JSON.stringify(pepIntrinseque) !== JSON.stringify(this.state.pepIntrinseque))
            this.setState({
                pepIntrinseque: pepIntrinseque
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('pepIntrinseque', JSON.stringify(this.state.pepIntrinseque));
    }

    runCalculs() {
        let AutoPEP = this.state.pepIntrinseque.pepTotal - this.state.pepIntrinseque.pepExtern;
        let PEEPabsorber = this.state.pepIntrinseque.absorptionPEP? "OUI" : "NON";

        this.setState(prevState => ({
            pepIntrinseque: {
                ...prevState.pepIntrinseque,
                AutoPEP,
                PEEPabsorber,
            }
        }));
    }

    render() {

        const panels = [
            {
                label: 'Définition',
                content: 
                    <div>
                        <p>Les modifications de la mécanique ventilatoire peuvent être responsables d’une expiration incomplète conduisant à l’hyperinflation dynamique. Le volume gazeux non expiré est progressivement piégé dans l'alvéole et la pression alvéolaire en fin d’expiration peut dépasser la PEP réglée par le clinicien<sup>1</sup>. Ainsi, la PEP totale (PEP<sub>tot</sub>) peut être décomposée en 2 composantes selon l'équation suivante:</p>
                        <br/>
                        <p>PEP<sub>tot</sub> = PEP<sub>e</sub> + PEP<sub>i</sub></p>
                        <br/>
                        <p>Où la PEP extrinsèque (PEP<sub>e</sub>, aussi appelée externe) est directement appliquée au patient par le biais du ventilateur. Et où la PEP intrinsèque (PEP<sub>i</sub>, aussi appelée auto-PEP) correspond à la différence entre la PEP<sub>t</sub> et la PEP<sub>e</sub><sup>1</sup>.</p>
                    </div>
            },
            {
                label: <div>Causes de l’hyperinflation dynamique</div>,
                content:
                    <div>
                        <p>Les facteurs favorisant l’hyperinflation dynamique (expiration incomplète) et l’apparition d’une PEP<sub>i</sub> sont :</p>
                        <br/>
                        <ul>
                            <li>Une ventilation minute élevée, en raison d'un volume courant et/ou d'une fréquence respiratoire élevée. Ainsi, il a été montré que la ventilation protectrice utilisée au cours du SDRA peut promouvoir l’auto-PEP<sup>2,3</sup>.</li>
                            <li>Une augmentation de la constante de temps du système respiratoire (qui correspond au produit de la compliance et de la résistance du système respiratoire). Plus cette constante est élevée plus le temps nécessaire à une expiration complète est élevé et le risque d’hyperinflation dynamique important<sup>4–6</sup>.</li>
                            <li>Une augmentation des résistances expiratoires liée à l’inflammation bronchique et/ou au bronchospasme, d’autant plus qu’il existe une limitation du flux expiratoire (<i>expiratory flow limitation</i>, EFL) (cf. ci-dessous)<sup>4</sup>.</li>
                            <li>Enfin, l’hyperinflation dynamique se développe préférentiellement chez les sujets obèses et/ou présentant un phénomène d’occlusion des voies aériennes (cf. outil correspondant)<sup>1</sup>. La position influence la PEP<sub>i</sub> avec une diminution de la PEP<sub>i</sub> chez le patient demi-assis en comparaison avec la position en décubitus dorsal strict<sup>7</sup>. </li>
                        </ul>
                    </div>
            },
            {
                label: <div>Comment mesurer la PEP intrinsèque ?</div>,
                content: 
                    <div>
                        <p>La présence d'une PEP<sub>i</sub> peut se détecter sur la courbe temps-débit par la persistance d'un débit expiratoire alors que débute l’insufflation suivante. Elle correspond à la pression expiratoire mesurée à la fin d’une pause expiratoire prolongée lorsque la PEP<sub>e</sub> est réglée à 0 cmH<sub>2</sub>O. Si une PEP<sub>e</sub> est appliquée, la PEP<sub>i</sub> correspond à la différence entre la PEP<sub>tot</sub> et la PEP<sub>e</sub><sup>1</sup>.</p>
                        <br/>
                        <p>Dans une étude récente, Coppola <i>et al</i>. ont montré que 40% des patients atteint de SDRA présentaient une PEP<sub>i</sub> avec une médiane de 1,1 [1,0-2,3] cmH<sub>2</sub>O. Cependant, les patients étaient ventilés avec un volume courant supérieur à 6 ml/kg de poids prédit par la taille, ce qui peut expliquer en partie cette prévalence importante. Dans cette étude, l'impact de la PEP<sub>i</sub> sur la mécanique ventilatoire et les échanges gazeux (et notamment sur l’espace mort) semblait être minime<sup>1</sup>.<br /></p>
                    </div>
            },
            {
                label: <div>Conséquences de l'hyperinflation dynamique</div>,
                content:
                    <div>
                        <p>Les conséquences de l’hyperinflation dynamique et de la PEP<sub>i</sub> sont multiples<sup>4</sup>:</p>
                        <br/>
                        <ul>
                            <li>Altération des échanges gazeux avec aggravation de l'hypoxémie secondaire à l’augmentation des anomalies de ventilation-perfusion. </li>
                            <li>Instabilité hémodynamique surtout en cas d'hypovolémie et de PEP<sub>i</sub> élevée. En effet, l’augmentation de la pression intra-thoracique entraine une diminution de la pré-charge du ventricule gauche (par la baisse du retour veineux) et une augmentation de la post-charge du ventricule droit.</li>
                            <li>En ventilation spontanée, la PEP<sub>i</sub> entraine une augmentation du travail inspiratoire en raison d’une plus grande difficulté à déclencher l’inspiration car le patient doit vaincre la PEPi pour arriver au seuil de déclenchement du ventilateur. </li>
                            <li>Diminution de la contraction diaphragmatique et aggravation des asynchronies patient-ventilateur.</li>
                        </ul>
                        <br/>
                        <p>Il convient de rappeler que l’évaluation de la mécanique ventilatoire chez les patients en SDRA doit se faire en utilisant la PEP<sub>tot</sub>. En effet, lorsque la PEP<sub>i</sub> n’est pas pris en considération, la compliance du système respiratoire est systématiquement sous-estimée (car la pression motrice est surestimée)<sup>1</sup>.</p>
                    </div>
            },
            {
                label: <div>Comment lutter contre l'hyperinflation dynamique</div>,
                content:
                    <div>
                        <p>Lorsqu’elle est possible, la modification des réglages du ventilateur (réduction du volume courant, du temps d’insufflation ou de la fréquence respiratoire) permet de diminuer la PEP<sub>i</sub>. Cependant, chez le patient en SDRA, cette stratégie peut générer ou aggraver une acidose hypercapnique<sup>8</sup>.</p>
                        <p>La position du patient peut aussi influencer l’hyperinflation dynamique. Dans une étude chez l’obèse ventilé avec une PEP<sub>e</sub> nulle, la PEP<sub>i</sub> était significativement diminuée en position assise en comparaison avec la position allongée<sup>9</sup>.</p>
                        <br/>
                        <p>En s’opposant à la fermeture des voies aériennes et à l’EFL, l’application d’une PEP<sub>e</sub> permet chez certains malades de lutter contre l’hyperinflation dynamique et de diminuer la PEP<sub>i</sub> (voir ci-dessous)<sup>8</sup>.<br /></p>
                    </div>
            },
            {
                label: 'Le concept de PEEP-absorber',
                content:
                    <div>
                        <p>Chez près d’un tiers des patients présentant une PEPi, l'application d'une PEPe n'entraine pas ou peu de variation de la PEP<sub>tot</sub>. Chez ces patients appelés «<i>complete PEEP-absorbers</i>», la PEP<sub>e</sub> permet de diminuer le niveau de PEP<sub>i</sub>. Il est alors conseillé de régler la PEP<sub>e</sub> à 80-90% de la PEP<sub>tot</sub><sup>8</sup>. Chez les autres patients, l'application d'une PEP<sub>e</sub> expose à une augmentation de la PEP<sub>tot</sub> et à ses conséquences<sup>8,10</sup>.<br /></p>
                    </div>
            },
            {
                label: 'Le concept de limitation du flux expiratoire',
                content:
                    <div>
                        <p>L'expiratory flow limitation (EFL) est un phénomène fréquent chez les patients présentant une hyperinflation dynamique. Cependant, ce phénomène n’est ni indispensable, ni suffisant à la survenue d’une PEP<sub>i</sub><sup>8,11</sup>.</p>
                        <br/>
                        <p>L’EFL peut être suspectée en observant l’aspect de la courbe temps-débit du ventilateur. Celle-ci montre un pic de débit expiratoire suivi d’un débit très faible pour le reste de l’expiration. Plusieurs méthodes existent pour confirmer l’EFL. La plus simple est de constater l’absence d’augmentation du débit expiratoire lors d’une baisse brutale de la PEP ou d’une pression douce sur l’abdomen<sup>12</sup>. Au cours du SDRA, la prévalence de l’EFL est comprise entre 20-80% selon les études<sup>7,13,14</sup>.</p>
                        <br/>
                        <p>L’EFL et le phénomène d’occlusion des voies aériennes (cf. outil correspondant) ont probablement une physiopathologie commune. La grande majorité des patients présentant une EFL ont aussi une occlusion des voies aériennes, en revanche cette dernière peut survenir en l’absence d’EFL<sup>14</sup>.</p>
                    </div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. Coppola S, Caccioppola A, Froio S, et al. Dynamic hyperinflation and intrinsic positive end-expiratory pressure in ARDS patients. <i>Crit Care</i> 2019;23(1):375.<br/><br/>
                            2. Durante G de, Turco M del, Rustichini L, et al. ARDSNet Lower Tidal Volume Ventilatory Strategy May Generate Intrinsic Positive End-Expiratory Pressure in Patients with Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2002;165(9):1271–1274.<br/><br/>
                            3. Vieillard-Baron A, Prin S, Augarde R, et al. Increasing respiratory rate to improve CO2 clearance during mechanical ventilation is not a panacea in acute respiratory failure. <i>Crit Care Med</i> 2002;30(7):1407–1412.<br/><br/>
                            4. Vassilakopoulos T, Toumpanakis D, Mancebo J. What’s new about pulmonary hyperinflation in mechanically ventilated critical patients. <i>Intensive Care Med</i> 2020;46(12):2381–2384.<br/><br/>
                            5. Wang H, He H. Dynamic hyperinflation and intrinsic PEEP in ARDS patients: who, when, and how needs more focus? <i>Crit Care</i> 2019;23(1):422.<br/><br/>
                            6. Natalini G, Tuzzo D, Rosano A, et al. Assessment of Factors Related to Auto-PEEP. <i>Respir Care</i> 2016;61(2):134–141.<br/><br/>
                            7. Koutsoukou A, Armaganidis A, Stavrakaki-Kallergi C, et al. Expiratory flow limitation and intrinsic positive end-expiratory pressure at zero positive end-expiratory pressure in patients with adult respiratory distress syndrome. <i>Am J Respir Crit Care Med</i> 2000;161(5):1590–1596. <br/><br/>
                            8. Natalini G, Tuzzo D, Rosano A, et al. Effect of external PEEP in patients under controlled mechanical ventilation with an auto-PEEP of 5 cmH2O or higher. <i>Annals of Intensive Care</i> 2016;6(1):53.<br/><br/>
                            9. Lemyze M, Mallat J, Duhamel A, et al. Effects of sitting position and applied positive end-expiratory pressure on respiratory mechanics of critically ill obese patients receiving mechanical ventilation*. <i>Crit Care Med</i> 2013;41(11):2592–2599.<br/><br/>
                            10. Koutsoukou A, Bekos B, Sotiropoulou C, Koulouris NG, Roussos C, Milic-Emili J. Effects of positive end-expiratory pressure on gas exchange and expiratory flow limitation in adult respiratory distress syndrome. <i>Crit Care Med</i> 2002;30(9):1941–1949. <br/><br/>
                            11. Koutsoukou A, Pecchiari M. Expiratory flow-limitation in mechanically ventilated patients: A risk for ventilator-induced lung injury? <i>World J Crit Care Med</i> 2019;8(1):1–8.<br/><br/>
                            12. Junhasavasdikul D, Telias I, Grieco DL, et al. Expiratory Flow Limitation During Mechanical Ventilation. <i>Chest</i> 2018;154(4):948–962.<br/><br/>
                            13. Yonis H, Mortaza S, Baboi L, Mercat A, Guérin C. Expiratory Flow Limitation Assessment in Patients with Acute Respiratory Distress Syndrome. A Reappraisal. <i>Am J Respir Crit Care Med</i> 2018;198(1):131–134.<br/><br/>
                            14. Guérin C, Terzi N, Galerneau L-M, et al. Lung and chest wall mechanics in patients with acute respiratory distress syndrome, expiratory flow limitation, and airway closure. <i>J Appl Physiol</i> (1985) 2020;128(6):1594–1603. <br /><br />
                        </p>
                    </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/pep" }} className="back" />
                        <span className="title">PEP intrinsèque</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>
                <fieldset>
                    <legend className="center">Paramètres mesurés</legend>
                    <div className="form-group">
                        <label>PEP totale</label>
                        <div className="info-input">
                            <input className="tiny"
                                name="pepTotal"
                                min="1" max="50"
                                type="number"
                                value={this.state.pepIntrinseque.pepTotal}
                                onChange={(e) => this.handleInputChange(e, null, 'pepIntrinseque')} />
                            <div className="info"> cmH<sub>2</sub>O</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>PEP externe</label>
                        <div className="info-input">
                            <input className="tiny"
                                name="pepExtern"
                                min="1" max="50"
                                type="number"
                                value={this.state.pepIntrinseque.pepExtern}
                                onChange={(e) => this.handleInputChange(e, null, 'pepIntrinseque')} />
                            <div className="info"> cmH<sub>2</sub>O</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Absorption de PEP</label>
                        <Switch
                            name="absorptionPEP"
                            className="switch3"
                            checked={this.state.pepIntrinseque.absorptionPEP}
                            onChange={(e) => this.handleInputChange(e, null, 'pepIntrinseque')}
                        />
                    </div>
                    <div className="form-group">
                        <label>Limitation du flux expiratoire</label>
                        <Switch
                            name="limitationFluxExpiratoire"
                            className="switch3"
                            checked={this.state.pepIntrinseque.limitationFluxExpiratoire }
                            onChange={(e) => this.handleInputChange(e, null, 'pepIntrinseque')}
                        />
                    </div>
                </fieldset>

                <fieldset className="italic-style">
                    <div className="form-group type-slider">
                        <div className="specific2">
                            <div className="specific2-wrapper">
                                <div className="specific2-inner">
                                    <div className="col">
                                        <p><small>Auto-PEP</small></p>
                                        <p className="result"><strong>{this.state.pepIntrinseque.AutoPEP}</strong></p>
                                        <p className="blue"><small>cmH<sub>2</sub>O</small></p>
                                    </div>
                                    <div className="col">
                                        <p><small>PEEP-absorber</small></p>
                                        <p className="result"><strong>{this.state.pepIntrinseque.PEEPabsorber}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/pep" }} className="validation btn secondary">Terminer</Link>
                </div>
            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(PepIntrinseque);
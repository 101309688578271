import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import Stepper from '../../Stepper';
import CustomSlider from '../../CustomSlider';
import CustomComponent from '../../../customComponent';

class Ventilatory extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            ventilatory: {
                sexe: "homme",
                taille: "",
                paco2: "",
                ventilation: "",
                vr: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let vr = this.getVR();
        this.setState(prevState => ({
            ventilatory: {
                ...prevState.ventilatory,
                vr
            }
        }));
    }

    componentDidMount() {
        let ventilatory = this.loadStorageValues('ventilatory', this.state.ventilatory);

        if (JSON.stringify(ventilatory) !== JSON.stringify(this.state.ventilatory))
            this.setState({
                ventilatory: ventilatory
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('ventilatory', JSON.stringify(this.state.ventilatory));
    }

    reset() {
        this.setState({
            ventilatory: {
                sexe: "homme",
                taille: "",
                paco2: "",
                ventilation: "",
                vr: ""
            }
        },
            () => {
                localStorage.removeItem('ventilatory');
            });
    }

    getVR = () => {
        let ppt = 0;
        const { ventilation, paco2, taille, sexe } = this.state.ventilatory;
        if (sexe === "homme") {
            ppt = Math.round(0.91 * (taille - 152.4) + 50);
        } else if (sexe === "femme") {
            ppt = Math.round(0.91 * (taille - 152.4) + 45.5);
        }
        return (Math.round((ventilation * 1000 * paco2) / (ppt * 100 * 37.5) * 100) / 100).toFixed(2);
    }

    render() {

        const panels = [
            {
                label: 'Ventilation-minute corrigée',
                content:
                    <div>
                        <p>
                            La ventilation minute corrigée (VM<sub>corr</sub>) est un indice d’efficacité ventilatoire parfois utilisé comme un substitut à l'espace mort. Il a été utilisé par l'ARDS Task Force dans la définition du SDRA (un SDRA sévère était caractérisé par une compliance inférieure à 40 ml/cmH<sub>2</sub>O ou une VM<sub>corr</sub> supérieure à 10 L/min). Cependant l'absence de preuve de validité a conduit à son abandon. La VM<sub>corr</sub> est définie comme :
                        </p>
                        <p>VM<sub>corr</sub> = VM x P<sub>a</sub>CO<sub>2</sub> / 40</p>
                        <p>
                            Nin <i>et al.</i> ont montré une augmentation significative de la VM<sub>corr</sub> chez des patients présentant un SDRA avec hypercapnie (définie comme une P<sub>a</sub>CO<sub>2</sub> supérieure à 50 mmHg) comparée aux SDRA sans hypercapnie (14,2 +/- 5,9 L/min vs 9,2 +/- 3,3 L/min ; p {'<'} 0,001)<sup>1</sup>. La VM<sub>corr</sub> semble être un facteur indépendant de mortalité au cours du SDRA<sup>2</sup>.  
                        </p>
                    </div>
            },
            {
                label: 'Définition du VR',
                content:
                    <div>
                        <p>
                            Le ventilatory ratio (VR) est un indice reflétant l'efficacité globale de la ventilation et permet une bonne estimation de l’espace mort (V<sub>d</sub>/V<sub>t</sub>)<sup>3</sup>. Le VR est bien corrélé au V<sub>d</sub>/V<sub>t</sub> calculé par l’équation de Bohr<sup>4</sup>. Son calcul a l’avantage de ne pas nécessiter la mesure du CO<sub>2</sub> expiré (P<sub>E</sub>CO<sub>2</sub> ou EtCO<sub>2</sub>) :
                        </p>
                        <p>VR = (VM x P<sub>a</sub>CO<sub>2</sub>) / (VM prédite x P<sub>a</sub>CO<sub>2</sub> idéale)</p>
                        <p>
                            Où VM est la ventilation minute mesurée (en mL/min) ; P<sub>a</sub>CO<sub>2</sub> est la pression artérielle en CO<sub>2</sub> (en mm Hg) ; VM prédite est la ventilation minute prédite (en mL/min) qui correspond au poids prédit par la taille (en Kg) multiplié par 100. Enfin, la P<sub>a</sub>CO<sub>2</sub> idéale est fixée à 37,5 mmHg. Le VR correspond globalement à une VM<sub>corr</sub> normalisée sur le poids théorique.
                        </p>
                    </div>
            },
            {
                label: 'Intérêt du VR',
                content:
                    <div>
                        <p>
                            Une valeur proche de 1 représente des poumons ventilant normalement alors qu’une augmentation du VR indique une augmentation du V<sub>d</sub>/V<sub>t</sub><sup>5</sup>. Au cours du SDRA, les valeurs moyennes de VR vont de 1,6 à 2,9 selon les études<sup>3,4,6</sup>. Le VR mesuré à J1 de l’intubation a été identifié comme facteur indépendant de mortalité<sup>4,7</sup>. Dans cette étude, les patients survivants au SDRA avait un VR médian à J1 de 1,6 contre 1,8 chez les patients décédés (p{'<'}0,01)<sup>7</sup>.
                        </p>
                    </div>
            },
            {
                label: 'Références',
                content: 
                <div>
                    <p>
                        1. 	Nin N, Muriel A, Peñuelas O, et al. Severe hypercapnia and outcome of mechanically ventilated patients with moderate or severe acute respiratory distress syndrome. <i>Intensive Care Med</i> 2017;43(2):200–208. <br/><br/>
                        2. 	Fusina F, Albani F, Bertelli M, et al. Corrected Minute Ventilation Is Associated With Mortality in ARDS Caused by COVID-19. <i>Respiratory Care</i> 2020;66(4):619-625.<br/><br/>
                        3. 	Diehl J-L, Peron N, Chocron R, et al. Respiratory mechanics and gas exchanges in the early course of COVID-19 ARDS: a hypothesis-generating study. <i>Ann Intensive Care</i> 2020;10(1):95.<br/><br/>
                        4. 	Sinha P, Calfee CS, Beitler JR, et al. Physiologic Analysis and Clinical Performance of the Ventilatory Ratio in Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2019;199(3):333–341. <br/><br/>
                        5. 	Sinha P, Fauvel NJ, Singh S, Soni N. Ventilatory ratio: a simple bedside measure of ventilation. <i>Br J Anaesth</i> 2009;102(5):692–697. <br/><br/>
                        6. 	Liu X, Liu X, Xu Y, et al. Ventilatory Ratio in Hypercapnic Mechanically Ventilated Patients with COVID-19–associated Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2020;201(10):1297–1299. <br/><br/>
                        7. 	Morales-Quinteros L, Schultz MJ, Bringué J, et al. Estimated dead space fraction and the ventilatory ratio are associated with mortality in early ARDS. <i>Ann Intensive Care</i> 2019;9(1):128. 
                    </p>    
                </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/espmort" }} className="back" />
                        <span className="title">Ventilatory Ratio</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend className="center">Paramètres</legend>

                    <div className="form-group form-check-inline">
                        <legend>Sexe</legend>
                        <div className="form-check">
                            <input className="form-check-input"
                                type="radio"
                                name="sexe"
                                id="sexeRadio1"
                                value="homme"
                                onChange={(e) => this.handleInputChange(e, null, 'ventilatory')}
                                checked={this.state.ventilatory.sexe === 'homme'}
                            />
                            <label className="form-check-label" htmlFor="sexeRadio1">
                                Homme
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="sexe"
                                id="sexeRadio2"
                                value="femme"
                                onChange={(e) => this.handleInputChange(e, null, 'ventilatory')}
                                checked={this.state.ventilatory.sexe === 'femme'}
                            />
                            <label className="form-check-label" htmlFor="sexeRadio2">
                                Femme
                            </label>
                        </div>
                    </div>
                    <CustomSlider
                        title="Taille"
                        unit={<div>cm</div>}
                        name="taille"
                        handle={(e, val) => this.handleInputChange(e, val, 'ventilatory')}
                        value={this.state.ventilatory.taille}
                        min="100" max="250"
                    />
                    <Stepper
                        title="PaCO2"
                        unit={<div>mmHg</div>}
                        name="paco2"
                        handle={(e) => this.handleInputChange(e, null, 'ventilatory')}
                        value={this.state.ventilatory.paco2}
                        min="0" max="150"
                    />
                    <Stepper
                        title="Ventilation minute"
                        unit={<div>L/min</div>}
                        name="ventilation"
                        handle={(e) => this.handleInputChange(e, null, 'ventilatory')}
                        value={this.state.ventilatory.ventilation}
                        min="0" max="25"
                        step="0.1"
                    />
                    <div className="form-group">
                        <div className="specific3">
                            <div className="specific3-wrapper">
                                <p>VR</p>
                                <div className="specific4-inner">
                                    <div className="col">
                                        <p className="result"><strong>{this.getVR()}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(Ventilatory);
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';
import CustomComponent from '../../../customComponent';
import Switch from '../../../components/switch';
import niveaupep2 from '../../../img/niveaupep2.jpg';
import niveaupep3 from '../../../img/niveaupep3.jpg';
import niveaupep4 from '../../../img/niveaupep4.jpg';


class NiveauPep extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            niveauPep: {
                selonPressionPlateau: false,
                selonSI: false,
                selonCourbes: false,
                selonPressionOeso: false,
                ardsNetworkBassePEP: false,
                ardsNetworkHautePEP: false,
                fio2BassePep: '100',
                fio2HautePep: '100',
                recommandationPEPbasse: "",
                recommandationPEPhaute: ""
            },
            infos: false
        }
    }

    runCalculs() {
        let recommandationPEPbasse = "";
        let recommandationPEPhaute = "";
        const { fio2BassePep, fio2HautePep } = this.state.niveauPep;
        let fio2BassePepInt = Number(fio2BassePep);
        let fio2HautePepInt = Number(fio2HautePep);

        if (fio2BassePepInt === 100) {
            recommandationPEPbasse = "18-24";
        }
        if (fio2BassePepInt === 90) {
            recommandationPEPbasse = "14-18";
        }
        if (fio2BassePepInt === 80) {
            recommandationPEPbasse = "14";
        }
        if (fio2BassePepInt === 70) {
            recommandationPEPbasse = "10-14";
        }
        if (fio2BassePepInt === 60) {
            recommandationPEPbasse = "10";
        }
        if (fio2BassePepInt === 50) {
            recommandationPEPbasse = "8-10";
        }
        if (fio2BassePepInt === 40) {
            recommandationPEPbasse = "5-8";
        }
        if (fio2BassePepInt === 30) {
            recommandationPEPbasse = "5";
        }
        if (fio2HautePepInt === 100) {
            recommandationPEPhaute = "22-24";
        }
        if (fio2HautePepInt === 90) {
            recommandationPEPhaute = "22";
        }
        if (fio2HautePepInt === 80) {
            recommandationPEPhaute = "20-22";
        }
        if (fio2HautePepInt === 70) {
            recommandationPEPhaute = "20-22";
        }
        if (fio2HautePepInt === 60) {
            recommandationPEPhaute = "20-22";
        }
        if (fio2HautePepInt === 50) {
            recommandationPEPhaute = "16-20";
        }
        if (fio2HautePepInt === 40) {
            recommandationPEPhaute = "14-16";
        }
        if (fio2HautePepInt === 30) {
            recommandationPEPhaute = "5-14";
        }

        this.setState(prevState => ({
            niveauPep: {
                ...prevState.niveauPep,
                recommandationPEPbasse,
                recommandationPEPhaute
            }
        }));
    }

    componentDidMount() {
        let niveauPep = this.loadStorageValues('niveauPep', this.state.niveauPep);

        if (JSON.stringify(niveauPep) !== JSON.stringify(this.state.niveauPep))
            this.setState({
                niveauPep: niveauPep
            }, () => this.runCalculs());
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.infos === this.state.infos)
            localStorage.setItem('niveauPep', JSON.stringify(this.state.niveauPep));
    }

    reset() {
        this.setState({
            niveauPep: {
                selonPressionPlateau: false,
                selonSI: false,
                selonCourbes: false,
                selonPressionOeso: false,
                ardsNetworkBassePEP: false,
                ardsNetworkHautePEP: false,
                fio2BassePep: '100',
                fio2HautePep: '100',
                recommandationPEPbasse: "",
                recommandationPEPhaute: ""
            }
        },
            () => {
                localStorage.removeItem('niveauPep');
            });
    }

    render() {

        const panels = [
            {
                label: 'Généralités',
                content: <div><p>L’objectif de la pression expiratoire positive (PEP) est de maintenir le poumon ouvert et aéré (recrutement). Cependant, augmenter le niveau de PEP peut aussi s’accompagner d’un risque de distendre de manière excessive un poumon déjà aéré. La personnalisation du niveau de PEP au cours du SDRA reste ainsi débattue<sup>1,2</sup>.</p></div>
            },
            {
                label: 'Potentiel de recrutement alvéolaire',
                content: <div><p>Le recrutement alvéolaire correspond à la ré-aération, sous l’effet de la pression positive, de zones collabées. Au cours du SDRA, le potentiel de recrutement alvéolaire varie considérablement d’un patient à l’autre<sup>3</sup>. Ce potentiel est corrélé à la quantité de tissu pulmonaire atteint et au rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub>. Cependant, en raison de l’atteinte parenchymateuse hétérogène du SDRA, la PEP a des effets différents en fonction des territoires où elle s’applique.</p>
                <p>On distingue ainsi quatre grands types de territoires qui se différencient par des niveaux de pression critique d’ouverture des alvéoles (niveau de pression au-delà duquel l’insufflation des alvéoles commence):</p>
                    <ul>
                    <li>Normalement aérés : pression critique d'ouverture proche de 0 cmH<sub>2</sub>O. Ils prédominent dans les zones ventrales et apicales (aussi appelées non-dépendantes). Ces territoires sont particulièrement à risque de surdistention à haute PEP. </li>
                    <li>Faiblement aérés : pression critique d'ouverture de l'ordre de 10-15 cmH<sub>2</sub>O.</li>
                    <li>Non aérés mais recrutables : pression critique d'ouverture d'environ 30 cmH<sub>2</sub>O, Ils prédominent dans les zones dorsales et basales (aussi appelées dépendantes). L'effet positif de la PEP et des manœuvres de recrutement alvéolaire (MRA) en termes de gain d'aération repose sur ces territoires.</li>
                    <li>Consolidés et non recrutables : la pression critique d'ouverture peut atteindre 60 cmH<sub>2</sub>O.</li>
                    </ul>
                    <p>L'application d'une pression importante dans les voies aériennes entraine une ré-aération des zones dépendantes aux dépends d'une surdistention des zones non dépendantes du poumon. En raison de l’hétérogénéité de l’atteinte du SDRA, il n’y a pas de recrutement alvéolaire sans surdistention<sup>4</sup>.</p></div>
            },
            {
                label: 'Intérêts et inconvénients de la PEP',
                content: <div><p>Les avantages théoriques de la PEP sont<sup>5,6</sup>:</p>
                    <ul>
                        <li>Diminution des phénomènes d'ouverture-fermeture responsables de l'atelectrauma.</li>
                        <li>Recrutement alvéolaire et augmentation du volume pulmonaire en fin d’expiration (end-expiratory lung volume, EELV): la PEP s’oppose à la compression des alvéoles liée aux forces gravitationnelles et à l’augmentation du poids du poumon (en raison de la distribution gravito-dépendante de l’œdème tissulaire). </li>
                        <li>Diminution du shunt intrapulmonaire permettant une amélioration des rapports ventilation-perfusion par le recrutement de territoires alvéolaires bien perfusés. De plus, la PEP peut entrainer une diminution du débit cardiaque et donc une réduction du shunt (relation shunt-débit).</li>
                    </ul>
                    <p>Le principal inconvénient de la PEP est le risque de surdistention des alvéoles saines. Cette surdistention est directement responsable de lésions induites par la ventilation mécanique (ventilator-induiced lung injury, VILI). Elle peut aussi engendrer une augmentation de l'espace mort, responsable d’une altération des anomalies de ventilation-perfusion (cf. outil correspondant). Enfin, la PEP peut entrainer une instabilité hémodynamique par la diminution de pré-charge du ventricule droit et/ou l’augmentation des résistances vasculaires pulmonaires responsable d'un cœur pulmonaire aigu<sup>5,7</sup>.</p>
                </div>
            },
            {
                label: 'Etudes EXPRESS, LOVS et ALVEOLI',
                content: <div>
                    <p>Les tableaux ci-dessous résument les principaux résultats des 3 grandes études ayant comparé une stratégie de PEP hautes (environ 15 cmH<sub>2</sub>O) à un groupe contrôle ayant une PEP plus basse.</p>
                    <img src={niveaupep2} alt="" width="556" />
                    <p>Etude EXPRESS<sup>8</sup> : dans le groupe interventionnel, la PEP était basée sur un objectif de P<sub>plat</sub> compris entre 28-30 cmH<sub>2</sub>O. Dans le groupe contrôle, la PEP totale était maintenue entre 5 et 9 cmH<sub>2</sub>O.</p>
                    <img src={niveaupep3} alt="" width="556" />
                    <p>Etude ALVEOLI<sup>9</sup> : dans le groupe interventionnel, la PEP était basée sur une table « haute PEP/F<sub>i</sub>O<sub>2</sub> » avec des valeurs de PEP allant de 12 à 22-24 cmH<sub>2</sub>O après modification du protocole. Dans le groupe contrôle, la PEP était basée sur une table « basse PEP/F<sub>i</sub>O<sub>2</sub> » avec des valeurs de PEP allant de 5 à 24 cmH<sub>2</sub>O.</p>
                    <img src={niveaupep4} alt="" width="556" />
                    <p>Etude LOVS<sup>10</sup> : dans le groupe interventionnel, la PEP était basée sur une table « haute PEP/F<sub>i</sub>O<sub>2</sub> » avec des valeurs de PEP allant de 5-10 à 22-24 cmH<sub>2</sub>O après modification du protocole. Dans le groupe contrôle, la PEP était basée sur une table « basse PEP/F<sub>i</sub>O<sub>2</sub> » avec des valeurs de PEP allant de 5 à 18-24 cmH<sub>2</sub>O.</p>
                    <p>Bien que les 3 grands essais comparant une PEP élevée à une PEP basse soient négatifs (cf. tableaux ci-dessus), une méta-analyse publiée par Briel et al. en 2010 retrouvait un impact significatif sur la mortalité. Les patients avec un SDRA modéré à sévère qui bénéficiaient d'une PEP élevée avaient une mortalité plus faible avec un risque relatif de 0,90 (IC 95% 0,81-1,00)<sup>11</sup>. De même, une étude ancillaire de LUNG SAFE a montré que des niveaux élevés de PEP étaient associés à une amélioration de la survie<sup>2</sup>. Enfin, il a été montré que les patients présentant une augmentation du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> supérieure à 25 mmHg en réponse à l'augmentation du niveau de PEP avaient une mortalité plus faible<sup>12,13</sup>.</p>
                    <p>Depuis la méta-analyse de Briel et al., d’autres essais et méta-analyses ont échoué à montrer un bénéfice à l’utilisation d’une PEP élevée pour diminuer la mortalité au cours du SDRA. Cependant, ces études étaient très hétérogènes concernant le type de SDRA ou l’utilisation associée de manœuvres de recrutement alvéolaire<sup>14–17</sup>.</p>
                    <p>Ceci plaide pour une identification précise et précoce des patients ayant un potentiel de réponse à la PEP (on parle de « recrutabilité »). Dans cet objectif, différents outils ont été évalués pour discriminer les patients répondeurs ou non répondeurs à un haut niveau de PEP (recruitment-to-inflation ratio, stress index, courbes pression-volume, impédancemétrie, pression œsophagienne…). Plusieurs d’entre eux sont décrits dans la section « potentiel de recrutement » de cette application. A noter, l’évaluation de la réponse à la PEP fait débat : pour certains auteurs, elle repose sur une augmentation du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub> alors que pour d’autres elle cherche à optimiser la mécanique ventilatoire (figurée par la P<sub>plat</sub>, C<sub>rs</sub>, DP…)<sup>18,19</sup>.<br /><br /></p>
                </div>
            },
            {
                label: 'Open lung approach et étude ART',
                content: <div>
                    <p>La manœuvre de recrutement alvéolaire (MRA) et la pression expiratoire positive (PEP) s'inscrivent dans le cadre de la ventilation à poumon ouvert (open lung approach, OLA). L'objectif de l'OLA est d'ouvrir le poumon, c'est-à-dire d'augmenter le nombre d'unités alvéolaires aérées grâce à la MRA, et de les maintenir ouvertes grâce à la PEP<sup>20</sup>.</p>
                    <p>Il est important de rappeler que la pression nécessaire pour ouvrir une alvéole collabée est supérieure à la pression nécessaire pour la maintenir ouverte (phénomène d'hystérésis). Ainsi, alors que la MRA nécessite de haut niveau de pression pour ouvrir certains territoires alvéolaires, la PEP nécessaire pour maintenir ces mêmes territoires ouverts est plus faible<sup>21</sup>.</p>
                    <p>Récemment, l’étude ART a évalué les effets de l’OLA au cours du SDRA. Cette étude a montré une surmortalité à J28 dans le groupe de patients recevant une manœuvre de recrutement alvéolaire associée à une PEP haute (55% vs 49% ; p = 0,041)<sup>22</sup>.</p>
                </div>
            },
            {
                label: 'Stratégie de PEP optimale',
                content: <div>
                    <p>Les recommandations des sociétés savantes s'accordent pour affirmer qu'il faut probablement utiliser des niveaux de PEP élevés (habituellement définie comme étant une PEP supérieure ou égale à 10  cmH<sub>2</sub>O) chez les patients atteints de SDRA modéré ou sévère. En revanche, une PEP plus basse (mais toujours supérieure à 5 cmH<sub>2</sub>O) est suffisante dans les SDRA légers.</p>
                    <p>Actuellement, deux stratégies sont principalement utilisées pour régler le niveau de PEP au cours du SDRA : </p>
                    <ul>
                        <li>La stratégie ou le réglage de PEP se base sur la mécanique ventilatoire à l'aide de la pression de plateau. L'objectif est de titrer la PEP de manière à obtenir une P<sub>plat</sub> comprise entre 28 et 30 cmH<sub>2</sub>O. Ceci permettrait le recrutement alvéolaire tout en contrôlant le risque de surdistention. Cette stratégie a été évaluée dans l'étude EXPRESS<sup>8</sup>.</li>
                        <li>La stratégie ou le réglage de PEP est fondé sur la F<sub>i</sub>O<sub>2</sub> nécessaire pour obtenir une oxygénation dans les cibles. Cette stratégie a été évaluée à l’aide de tables utilisant différentes combinaisons de PEP et F<sub>i</sub>O<sub>2</sub> dans les essais ALVEOLI et LOVS<sup>8,9</sup>.</li>
                    </ul>
                    <p>D’autres stratégies existent dans la littérature basées sur l’effet de la PEP sur la compliance du système respiratoire<sup>23</sup>, la pression motrice<sup>6,17,22,24</sup>, le stress index<sup>25,26</sup>, la pression œsophagienne<sup>27–29</sup>, le scanner thoracique<sup>30</sup> ou la tomographie par impédance électrique<sup>31–33</sup>.</p>
                    <p>Chiumello et al. ont comparé trois stratégies pour déterminer la PEP optimale des patients en SDRA: [a] selon la mécanique ventilatoire évaluée par la P<sub>plat</sub> (comme dans l'étude EXPRESS) ou par stress index, [b] selon le niveau d'oxygénation comme dans les études LOVS et ALVEOLI, [c] selon la pression œsophagienne. L'adéquation entre le niveau de PEP et le potentiel de recrutement alvéolaire (évalué par le scanner thoracique) était étudiée. La stratégie visant à déterminer la PEP selon l'oxygénation était la mieux corrélée au potentiel de recrutement. A l'inverse, la stratégie basée sur la mécanique ventilatoire était associée à une hyperinflation<sup>34,35</sup>.<br /><br /></p>
                </div>
            },
            {
                label: 'Références',
                content: <div>
                    <p>
					1. 	Hess DR. Recruitment Maneuvers and PEEP Titration. Respir Care 2015;60(11):1688–1704. <br /><br />
					2. 	Bellani G, Laffey JG, Pham T, et al. Epidemiology, Patterns of Care, and Mortality for Patients With Acute Respiratory Distress Syndrome in Intensive Care Units in 50 Countries. <i>JAMA</i> 2016;315(8):788–800.<br /><br /> 
					3. 	Gattinoni L, Caironi P, Cressoni M, et al. Lung recruitment in patients with the acute respiratory distress syndrome. N Engl J Med 2006;354(17):1775–1786. <br /><br />
					4. 	Gattinoni L, Quintel M, Marini JJ. “Less is More” in mechanical ventilation. <i>Intensive Care Med</i> 2020;46(4):780–782. <br /><br />
					5. 	Gattinoni L, Quintel M. Is mechanical ventilation a cure for ARDS? <i>Intensive Care Med </i>2016;42(5):916–917. <br /><br />
					6. 	Rezoagli E, Bellani G. How I set up positive end-expiratory pressure: evidence- and physiology-based! <i>Crit Care</i> 2019;23(1):412. <br /><br />
					7. 	Chikhani M, Das A, Haque M, Wang W, Bates DG, Hardman JG. High PEEP in acute respiratory distress syndrome: quantitative evaluation between improved arterial oxygenation and decreased oxygen delivery. <i>Br J Anaesth</i> 2016;117(5):650–658.<br /><br /> 
					8. 	Mercat A, Richard J-CM, Vielle B, et al. Positive end-expiratory pressure setting in adults with acute lung injury and acute respiratory distress syndrome: a randomized controlled trial. <i>JAMA</i> 2008;299(6):646–655. <br /><br />
					9. 	Brower RG, Lanken PN, MacIntyre N, et al. Higher versus lower positive end-expiratory pressures in patients with the acute respiratory distress syndrome. <i>N Engl J Med</i> 2004;351(4):327–336.<br /><br /> 
					10. Meade MO, Cook DJ, Guyatt GH, et al. Ventilation strategy using low tidal volumes, recruitment maneuvers, and high positive end-expiratory pressure for acute lung injury and acute respiratory distress syndrome: a randomized controlled trial. <i>JAMA</i> 2008;299(6):637–645. <br /><br />
					11. Briel M, Meade M, Mercat A, et al. Higher vs lower positive end-expiratory pressure in patients with acute lung injury and acute respiratory distress syndrome: systematic review and meta-analysis. <i>JAMA</i> 2010;303(9):865–873. <br /><br />
					12. Goligher EC, Kavanagh BP, Rubenfeld GD, et al. Oxygenation response to positive end-expiratory pressure predicts mortality in acute respiratory distress syndrome. A secondary analysis of the LOVS and ExPress trials. <i>Am J Respir Crit Care Med</i> 2014;190(1):70–76.<br /><br /> 
					13. Guo L, Xie J, Huang Y, et al. Higher PEEP improves outcomes in ARDS patients with clinically objective positive oxygenation response to PEEP: a systematic review and meta-analysis. <i>BMC Anesthesiol</i> 2018;18(1):172. <br /><br />
					14. Walkey AJ, Del Sorbo L, Hodgson CL, et al. Higher PEEP versus Lower PEEP Strategies for Patients with Acute Respiratory Distress Syndrome. A Systematic Review and Meta-Analysis. <i>Ann Am Thorac Soc</i> 2017;14(Supplement_4):S297–S303. <br /><br />
					15. Ball L, Serpa Neto A, Trifiletti V, et al. Effects of higher PEEP and recruitment manoeuvres on mortality in patients with ARDS: a systematic review, meta-analysis, meta-regression and trial sequential analysis of randomized controlled trials. <i>Intensive Care Med Exp</i> 2020;8(Suppl 1):39. <br /><br />
					16. Santa Cruz R, Rojas JI, Nervi R, Heredia R, Ciapponi A. High versus low positive end‐expiratory pressure (PEEP) levels for mechanically ventilated adult patients with acute lung injury and acute respiratory distress syndrome. <i>Cochrane Database Syst Rev</i> 2013;3(3):CD009098.<br /><br />
					17. Kacmarek RM, Villar J, Sulemanji D, et al. Open Lung Approach for the Acute Respiratory Distress Syndrome: A Pilot, Randomized Controlled Trial. <i>Crit Care Med</i> 2016;44(1):32–42. <br /><br />
					18. Sahetya SK, Goligher EC, Brower RG. Fifty Years of Research in ARDS. Setting Positive End-Expiratory Pressure in Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2017;195(11):1429–1438. <br /><br />
					19. Kallet RH. Should PEEP Titration Be Based on Chest Mechanics in Patients With ARDS? <i>Respir Care</i> 2016;61(6):876–890. <br /><br />
					20. Marini JJ. Should We Embrace the “Open Lung” Approach?*. <i>Critical Care Medicine</i> 2016;44(1):237–238. <br /><br />
					21. Bein T, Grasso S, Moerer O, et al. The standard of care of patients with ARDS: ventilatory settings and rescue therapies for refractory hypoxemia. <i>Intensive Care Med</i> 2016;42(5):699–711. <br /><br />
					22. Writing Group for the Alveolar Recruitment for Acute Respiratory Distress Syndrome Trial (ART) Investigators, Cavalcanti AB, Suzumura ÉA, et al. Effect of Lung Recruitment and Titrated Positive End-Expiratory Pressure (PEEP) vs Low PEEP on Mortality in Patients With Acute Respiratory Distress Syndrome: A Randomized Clinical Trial. <i>JAMA</i> 2017;318(14):1335–1345. <br /><br />
					23. Pintado M-C, Pablo R de, Trascasa M, Milicua J-M, Sánchez-García M. Compliance-guided versus FiO2-driven positive-end expiratory pressure in patients with moderate or severe acute respiratory distress syndrome according to the Berlin definition. <i>Med Intensiva</i> 2017;41(5):277–284. <br /><br />
					24. Pelosi P, Ball L. Should we titrate ventilation based on driving pressure? Maybe not in the way we would expect. <i>Ann Transl Med</i> 2018;6(19):389.<br /><br />
					25. Ferrando C, Suárez-Sipmann F, Gutierrez A, et al. Adjusting tidal volume to stress index in an open lung condition optimizes ventilation and prevents overdistension in an experimental model of lung injury and reduced chest wall compliance. <i>Crit Care</i> 2015;19:9. <br /><br />
					26. Grasso S, Stripoli T, De Michele M, et al. ARDSnet ventilatory protocol and alveolar hyperinflation: role of positive end-expiratory pressure. <i>Am J Respir Crit Care Med</i> 2007;176(8):761–767. 
					27. Suarez-Sipmann F, Ferrando C, Villar J. PEEP titration guided by transpulmonary pressure: lessons from a negative trial. <i>J Thorac Dis</i> 2019;11(Suppl 15):S1957–S1962.<br /><br /> 
					28. Talmor D, Sarge T, Malhotra A, et al. Mechanical ventilation guided by esophageal pressure in acute lung injury. <i>N Engl J Med</i> 2008;359(20):2095–2104. <br /><br />
					29. Beitler JR, Sarge T, Banner-Goodspeed VM, et al. Effect of Titrating Positive End-Expiratory Pressure (PEEP) With an Esophageal Pressure-Guided Strategy vs an Empirical High PEEP-Fio2 Strategy on Death and Days Free From Mechanical Ventilation Among Patients With Acute Respiratory Distress Syndrome: A Randomized Clinical Trial. <i>JAMA</i> 2019;321(9):846–857. <br /><br />
					30. Constantin J-M, Monsel A, Blanchard F, Godet T. Personalised mechanical ventilation in acute respiratory distress syndrome: the right idea with the wrong tools? – Authors’ reply. <i>The Lancet Respiratory Medicine</i> 2019;7(12):e39. <br /><br />
					31. Costa ELV, Borges JB, Melo A, et al. Bedside estimation of recruitable alveolar collapse and hyperdistension by electrical impedance tomography. <i>Intensive Care Med</i> 2009;35(6):1132–1137.<br /><br /> 
					32. Rosemeier I, Reiter K, Obermeier V, Wolf GK. Mechanical Ventilation Guided by Electrical Impedance Tomography in Children With Acute Lung Injury. <i>Crit Care Explor</i> 2019;1(7):e0020.<br /><br />
					33. Scaramuzzo G, Spadaro S, Dalla Corte F, et al. Personalized Positive End-Expiratory Pressure in Acute Respiratory Distress Syndrome: Comparison Between Optimal Distribution of Regional Ventilation and Positive Transpulmonary Pressure. <i>Crit Care Med</i> 2020;48(8):1148–1156. <br /><br />
					34. Chiumello D, Cressoni M, Carlesso E, et al. Bedside selection of positive end-expiratory pressure in mild, moderate, and severe acute respiratory distress syndrome. <i>Crit Care Med</i> 2014;42(2):252–264. <br /><br />
					35. Goligher EC, Villar J, Slutsky AS. Positive end-expiratory pressure in acute respiratory distress syndrome: when should we turn up the pressure? <i>Crit Care Med</i> 2014;42(2):448–450. <br /><br /><br />

							</p>
                </div>
            }
        ];

        return (<div>
            <div className="content">
                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/pep" }} className="back" />
                        <span className="title">Quel niveau de PEP ?</span>
                        <span onClick={() => this.setState({ infos: (!this.state.infos) })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <fieldset>
                    <legend>En fonction de la mécanique ventilatoire</legend>
                    <p>Pour plus de précision concernant les outils utilisés ci-dessous, se rapporter à leurs fiches explicatives.</p>
                    <div className="form-group">
                        <label>Selon la pression du plateau</label>
                        <Switch
                            className="switch3"
                            name="selonPressionPlateau"
                            checked={this.state.niveauPep.selonPressionPlateau}
                            onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                        />
                    </div>
                    {this.state.niveauPep.selonPressionPlateau && <div>

                        <p>Dans le protocole EXPRESS, l'objectif était d'augmenter progressivement la PEP jusqu'à obtenir une pression de plateau comprise entre 28-30 cmH2O. La PEP ne devait cependant pas dépasser 20 cmH2O.</p>

                    </div>}

                    <div className="form-group">
                        <label>Selon le stress index</label>
                        <Switch
                            name="selonSI"
                            className="switch3"
                            checked={this.state.niveauPep.selonSI}
                            onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                        />
                    </div>
                    {this.state.niveauPep.selonSI && <div>

                        <p>Augmentation progressive de la PEP jusqu'à obtenir un stress index à 1 (donc une augmentation linéaire de la courbe temps-pression lors de l'insufflation). En-dessous, il existe un potentiel de recrutement. Au-dessus, il existe un risque de surdistention.</p>

                    </div>}

                    <div className="form-group">
                        <label>Selon la pression œsophagienne</label>
                        <Switch
                            name="selonPressionOeso"
                            className="switch3"
                            checked={this.state.niveauPep.selonPressionOeso}
                            onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                        />
                    </div>
                    {this.state.niveauPep.selonPressionOeso && <div>

                        <p>Augmentation progressive de la PEP jusqu'à obtenir une pression transpulmonaire expiratoire directe (PTPe) supérieure à 0 cmH2O afin d'éviter le dérecrutement. Il faut aussi s'assurer que la pression transpulmonaire inspiratoire dérivée du ratio des élastances (PTPi) soit inférieure à 25 cmH2O pour éviter la surdistention.</p>
                    </div>}

                </fieldset>

                <fieldset>
                    <legend>En fonction du niveau d'oxygénation</legend>

                    <div className="form-group">
                        <label>ARDS network (basse PEP)</label>
                        <Switch
                            name="ardsNetworkBassePEP"
                            className="switch3"
                            checked={this.state.niveauPep.ardsNetworkBassePEP}
                            onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                        />
                    </div>
                    {this.state.niveauPep.ardsNetworkBassePEP && <div>

                        <p>En raison d'une compliance souvent peu altérée, cette stratégie est à privilégier dans les SDRA légers.</p>

                        <div className="form-group form-check-inline">
                            <legend>Niveau de FiO2 (%)</legend>

                            <select
                                className="specific-select"
                                name="fio2BassePep"
                                id="fio2BassePep"
                                onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                                defaultValue={this.state.niveauPep.fio2BassePep}
                            >
                                <option value=""></option>
                                <option value="100">100</option>
                                <option value="90">90</option>
                                <option value="80">80</option>
                                <option value="70">70</option>
                                <option value="60">60</option>
                                <option value="50">50</option>
                                <option value="40">40</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                        <div className="form-group type-slider">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>PEP à appliquer</p>
                                    <div className="specific4-inner">
                                        <div className="col">
                                            <p className="result"><strong>{this.state.niveauPep.recommandationPEPbasse}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>O</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className="form-group">
                        <label>ARDS network (haute PEP)</label>
                        <Switch
                            name="ardsNetworkHautePEP"
                            className="switch3"
                            checked={this.state.niveauPep.ardsNetworkHautePEP}
                            onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                        />
                    </div>
                    {this.state.niveauPep.ardsNetworkHautePEP && <div>

                        <p>En raison d'une compliance souvent altérée, cette stratégie est à privilégier dans les SDRA modérés à sévères.</p>

                        <div className="form-group form-check-inline">
                            <legend>Niveau de FiO2 (%)</legend>
                            <select
                                className="specific-select"
                                name="fio2HautePep"
                                id="fio2HautePep"
                                onChange={(e) => this.handleInputChange(e, null, 'niveauPep')}
                                defaultValue={this.state.niveauPep.fio2HautePep}
                            >
                                <option value=""></option>
                                <option value="100">100</option>
                                <option value="90">90</option>
                                <option value="80">80</option>
                                <option value="70">70</option>
                                <option value="60">60</option>
                                <option value="50">50</option>
                                <option value="40">40</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                        <div className="form-group type-slider">
                            <div className="specific3">
                                <div className="specific3-wrapper">
                                    <p>PEP à appliquer</p>
                                    <div className="specific4-inner">
                                        <div className="col">
                                            <p className="result"><strong>{this.state.niveauPep.recommandationPEPhaute}</strong></p>
                                            <p className="blue"><small>cmH<sub>2</sub>O</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                </fieldset>

                <div className="wrap-validation">
                    <button className="reload" onClick={() => this.reset()}>Effacer les données</button>
                    <Link to={{ pathname: "/tools" }} className="validation btn secondary">Terminer</Link>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (!this.state.infos) })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>
            <Navigation state={this.state} />
        </div>);
    }
}

export default withRouter(NiveauPep);
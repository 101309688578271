import { Component } from 'react';

class CustomComponent extends Component {

    handleInputChange(event, val, lsName) {
        const target = event.target;
        let name = event.target.parentNode.classList.contains('MuiSlider-root') ? event.target.parentNode.querySelector('input').name : event.target.name;
        let value = "";
        if (target.type !== undefined) {
            if (target.type === 'checkbox')
                value = target.checked;
            else if (target.type === 'submit') {
                if (target.id === 'step-up')
                    value = Number(this.state[lsName][name]) + (target.step ? Number(target.step) : 1);
                if (target.id === 'step-down')
                    value = Number(this.state[lsName][name]) - (target.step ? Number(target.step) : 1);
            }
            else
                value = target.value;
        }
        else {
            value = val;
        }

        value = this.keepValueBetweenMaxAndMin(target, value);

        this.setState(prevState => ({
            [lsName]: {
                ...prevState[lsName],
                [name]: value
            }
        }), () => {
            this.runCalculs();
        });
    }

    checkIsJSON(text) {
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    keepValueBetweenMaxAndMin(target, value) {
        let targetInput = target;

        if (target.parentNode.classList.contains('MuiSlider-root'))
            targetInput = target.parentNode.parentNode.querySelector('.info-slider').querySelector('input');

        if (target.type === 'submit')
            targetInput = target.parentNode.parentNode.parentNode.querySelector('.info-stepper').querySelector('input');

        if (targetInput.type === 'number') {
            let min = Number(targetInput.min);
            let max = Number(targetInput.max);
            if (min !== undefined && max !== undefined && value !== "") {
                value = Number(value) > max ? max : Number(value);
                if (targetInput instanceof HTMLInputElement) {
                    if (value === undefined || value > max || value < min)
                        targetInput.className = "tiny inputError";
                    else
                        targetInput.className = "tiny";
                }
            }
        }
        return value;
    }

    loadSimilarSettings(actualStateName, actualState) {
        let dict = {};
        let d = {};
        Object.keys(actualState).forEach((setting) => {
            for (var i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i) === actualStateName)
                    continue;
                let l = localStorage.getItem(localStorage.key(i));
                if (this.checkIsJSON(l)) {
                    d = JSON.parse(l);
                    if (d !== null && d[setting] !== undefined) {
                        dict[setting] = d[setting];
                    }

                }
            }
        });
        return dict;
    }

    loadStorageValues(actualStateName, actualState) {
        let storageValue = {};
        let dict = {};
        let similarSettings = this.loadSimilarSettings(actualStateName, actualState);
        if (this.checkIsJSON(localStorage.getItem(actualStateName)))
            storageValue = JSON.parse(localStorage.getItem(actualStateName));

        Object.keys(actualState).forEach((setting) => {
            if (typeof actualState[setting] == 'number' || !isNaN(actualState[setting]))
                dict[setting] = ((storageValue !== null && storageValue[setting] !== undefined) ? Number(storageValue[setting]) : null) ||
                    ((similarSettings !== null && similarSettings[setting] !== undefined) ? Number(similarSettings[setting]) : null) || actualState[setting];
            else
                dict[setting] = ((storageValue !== null && storageValue[setting] !== undefined) ? storageValue[setting] : null) ||
                    ((similarSettings !== null && similarSettings[setting] !== undefined) ? similarSettings[setting] : null) || actualState[setting];
        });

        return dict;
    }
}

export default CustomComponent;

import React from 'react';

const Switch = ({name, checked, onChange, className}) => {


    return ( 

                <label className={className? "switch " + className : "switch"} htmlFor={name}>
                    <input 
                        type="checkbox"
                        id={name}
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        className="react-switch-checkbox"
                        />
                    <span className="slider round">
                    </span>
                </label>

    );
    
}
 
export default Switch;
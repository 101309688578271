import React, { Component } from 'react';
import Navigation from "../../navigation/navigation";
import { withRouter, Link } from 'react-router-dom';
import Accordion from '../../../components/Accordion';

class EspaceMort extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        const panels = [
            {
                label: 'Concept de ventilation/perfusion mismatch',
                content: 
                <div>
                    <p>
                        Sous le terme <i>ventilation/perfusion</i> (V<sub>A</sub>/Q) <i>mismatch</i> est regroupé de nombreuses situations où il existe une non-concordance entre la ventilation et la perfusion pulmonaire. On distingue deux situations opposées<sup>1</sup>:
                    </p>
                    <ul>
                        <li>
                            Une zone pulmonaire est ventilée mais n'est pas perfusée (le rapport V<sub>A</sub>/Q tend vers l’infini): c'est l'espace mort (V<sub>d</sub>/V<sub>t</sub>), principalement responsable de l'hypercapnie.
                        </li>
                        <li>
                            Une zone pulmonaire est perfusée mais n'est pas ventilée (le rapport V<sub>A</sub>/Q est égale à 0): c'est le shunt intrapulmonaire (Q<sub>s</sub>/Q<sub>t</sub>), principalement responsable de l'hypoxémie.
                        </li>
                    </ul>
                    <p>
                        Entre ces deux états caricaturaux, il existe une multitude de situations dans lesquelles la ventilation excède la perfusion (on parle de rapport V<sub>A</sub>/Q augmenté) ou dans lesquelles la perfusion excède la ventilation (on parle de rapport V<sub>A</sub>/Q diminué).
                    </p>
                </div>
            },
            {
                label: 'Espace mort anatomique et alvéolaire',
                content: <div>
                    <p>
                        L'espace mort physiologique (V<sub>d</sub>/V<sub>t</sub> phys) correspond à la partie de volume courant qui ne participe pas aux échanges gazeux. Il peut être séparé en deux composants<sup>2</sup>:
                    </p>
                    <ul>
                        <li>
                            L'espace mort anatomique (V<sub>d</sub>/V<sub>t</sub> aw) qui correspond au volume d'air contenu dans le nez, le pharynx, la trachée et les bronches. Physiologiquement, le V<sub>d</sub>/V<sub>t</sub> aw est de l’ordre 2 mL/kg PPT.
                        </li>
                        <li>
                            L'espace mort alvéolaire (V<sub>d</sub>/V<sub>t</sub> alv) qui correspond à des alvéoles bien ventilées mais peu ou pas perfusées (surdistention alvéolaire, vasoconstriction hypoxique, obstruction vasculaire par l'inflammation ou les thromboses). Le V<sub>d</sub>/V<sub>t</sub> alv est négligeable chez le sujet sain.
                        </li>
                    </ul>
                    <p>
                        Chez le patient intubé, on peut ajouter l’espace mort instrumental qui correspond au volume des équipements attachés entre la pièce en Y et la sonde d’intubation (par exemple : filtre humidificateur [40-80 mL], capteur de débit [10 mL], moniteur de CO2 [10 mL], raccord flexible [20-50 mL], chambre d’inhalation…).
                    </p>
                    <p>
                        Le rapport V<sub>d</sub>/V<sub>t</sub> normal est de l’ordre de 20 à 35% au cours de la ventilation de repos. La plupart des études retrouvent des valeurs moyennes de V<sub>d</sub>/V<sub>t</sub> comprises entre 50 et 60% à la phase initiale du SDRA. En d’autres termes, plus de la moitié du volume courant ne participe pas aux échanges gazeux (on parle de ventilation « gaspillée », <i>wasted ventilation</i>)<sup>3</sup>.
                    </p>
                </div>
            },
            {
                label: 'Intérêt de la mesure de l’espace mort dans le SDRA',
                content:
                <div>
                    <p>
                        Le V<sub>d</sub>/V<sub>t</sub> est un facteur indépendant de mortalité au cours du SDRA. Nuckton <i>et al</i>. ont montré qu'une augmentation de 5% du V<sub>d</sub>/V<sub>t</sub> entraine une augmentation de la mortalité avec un OR de 1.45 (IC 95%= 1.15-1.83)<sup>4</sup>. Cette augmentation existe dès J1 et persiste au cours de l'hospitalisation. Depuis, de nombreuses études ont confirmé que le V<sub>d</sub>/V<sub>t</sub> influence la durée de ventilation, la durée de séjour en réanimation et la survie<sup>4–9</sup>. On peut retenir que le risque de mortalité augmente fortement pour des valeurs de V<sub>d</sub>/V<sub>t</sub> supérieure à 60%.
                    </p>
                </div>
            },
            {
                label: 'Influence de la PEP et du décubitus ventral',
                content:
                <div>
                    <p>
                        L’effet de la PEP sur le V<sub>d</sub>/V<sub>t</sub> est encore très discuté<sup>2</sup>. En effet chez certains patients l’augmentation de la PEP peut conduire à une réduction du V<sub>d</sub>/V<sub>t</sub> par le recrutement de territoires alvéolaires préalablement collabés ; alors que chez d’autres l’augmentation de la PEP entraine une surdistention et la compression des vaisseaux alvéolaires<sup>10–12</sup>. Deux études réalisées chez des patients souffrant de SDRA ont montré que le niveau de PEP permettant d’obtenir le meilleur compromis entre le maximum de recrutement alvéolaire et le minimum de surdistention correspondait au niveau de PEP associé au plus bas V<sub>d</sub>/V<sub>t</sub><sup>13,14</sup>. A l’inverse, d’autres études ont montré que l'espace mort alvéolaire n'était pas systématiquement modifié par le niveau de PEP<sup>15,16</sup>.
                    </p>
                    <p>
                        L’effet du décubitus ventral (DV) sur le V<sub>d</sub>/V<sub>t</sub> est peu documenté. Charron <i>et al</i>. ont montré une diminution du V<sub>d</sub>/V<sub>t</sub> après la mise en DV (42% <i>vs.</i> 31% ; p{'<'}0.05). Cette diminution est précoce et prédomine à H3. Elle s'accompagne d'une amélioration de la compliance pulmonaire mais n’est pas corrélé à une amélioration du rapport P<sub>a</sub>O<sub>2</sub>/F<sub>i</sub>O<sub>2</sub><sup>17</sup>. A l'inverse, Pelosi <i>et al</i>. n'ont pas trouvé de modification du V<sub>d</sub>/V<sub>t</sub> en DV<sup>18</sup>.
                    </p>
                </div>
            },
            {
                label: 'Références',
                content:
                    <div>
                        <p>
                            1. 	Radermacher P, Maggiore SM, Mercat A. Fifty Years of Research in ARDS. Gas Exchange in Acute Respiratory Distress Syndrome. <i>Am J Respir Crit Care Med</i> 2017;196(8):964–984. <br/><br/>
                            2. 	Blanch L, López-Aguilar J, Lucangelo U. Dead space in acute respiratory distress syndrome: more than a feeling! <i>Crit Care</i> 2016;20(1):214. <br/><br/>
                            3. 	Robertson HT. Dead space: the physiology of wasted ventilation. <i>Eur Respir J</i> 2015;45(6):1704–1716. <br/><br/>
                            4. 	Nuckton TJ, Alonso JA, Kallet RH, et al. Pulmonary dead-space fraction as a risk factor for death in the acute respiratory distress syndrome. <i>N Engl J Med</i> 2002;346(17):1281–1286. <br/><br/>
                            5. 	Kallet RH, Alonso JA, Pittet J-F, Matthay MA. Prognostic value of the pulmonary dead-space fraction during the first 6 days of acute respiratory distress syndrome. <i>Respir Care</i> 2004;49(9):1008–1014. <br/><br/>
                            6. 	Kallet RH, Zhuo H, Liu KD, Calfee CS, Matthay MA, National Heart Lung and Blood Institute ARDS Network Investigators. The association between physiologic dead-space fraction and mortality in subjects with ARDS enrolled in a prospective multi-center clinical trial. <i>Respir Care</i> 2014;59(11):1611–1618. <br/><br/>
                            7. 	Cepkova M, Kapur V, Ren X, et al. Pulmonary dead space fraction and pulmonary artery systolic pressure as early predictors of clinical outcome in acute lung injury. <i>Chest</i> 2007;132(3):836–842. <br/><br/>
                            8. 	Lucangelo U, Bernabè F, Vatua S, et al. Prognostic value of different dead space indices in mechanically ventilated patients with acute lung injury and ARDS. <i>Chest</i> 2008;133(1):62–71. <br/><br/>
                            9. 	Raurich JM, Vilar M, Colomar A, et al. Prognostic value of the pulmonary dead-space fraction during the early and intermediate phases of acute respiratory distress syndrome. <i>Respir Care</i> 2010;55(3):282–287. <br/><br/>
                            10. 	Ferluga M, Lucangelo U, Blanch L. Dead space in acute respiratory distress syndrome. <i>Ann Transl Med</i> 2018;6(19):388.<br/><br/>
                            11. 	Jardin F, Genevray B, Pazin M, Margairaz A. Inability to titrate PEEP in patients with acute respiratory failure using end-tidal carbon dioxide measurements. <i>Anesthesiology</i> 1985;62(4):530–533. <br/><br/>
                            12. 	Murray IP, Modell JH, Gallagher TJ, Banner MJ. Titration of PEEP by the arterial minus end-tidal carbon dioxide gradient. <i>Chest</i> 1984;85(1):100–104. <br/><br/>
                            13. 	Blanch L, Fernández R, Benito S, Mancebo J, Net A. Effect of PEEP on the arterial minus end-tidal carbon dioxide gradient. <i>Chest</i> 1987;92(3):451–454. <br/><br/>
                            14. 	Fengmei G, Jin C, Songqiao L, Congshan Y, Yi Y. Dead space fraction changes during PEEP titration following lung recruitment in patients with ARDS. <i>Respir Care</i> 2012;57(10):1578–1585. <br/><br/>
                            15. 	Beydon L, Uttman L, Rawal R, Jonson B. Effects of positive end-expiratory pressure on dead space and its partitions in acute lung injury. <i>Intensive Care Med</i> 2002;28(9):1239–1245. <br/><br/>
                            16. 	López-Aguilar J, Magrans R, Blanch L. Dead Space in ARDS: Die Hard. <i>Respir Care</i> 2017;62(10):1372–1374. <br/><br/>
                            17. 	Charron C, Repesse X, Bouferrache K, et al. PaCO2 and alveolar dead space are more relevant than PaO2/FiO2 ratio in monitoring the respiratory response to prone position in ARDS patients: a physiological study. <i>Crit Care</i> 2011;15(4):R175. <br/><br/>
                            18. 	Pelosi P, Tubiolo D, Mascheroni D, et al. Effects of the prone position on respiratory mechanics and gas exchange during acute lung injury. <i>Am J Respir Crit Care Med</i> 1998;157(2):387–393.
                        </p>
                    </div>
            }
        ];

        return (<div>

            <div className="content">

                <div className="sticky-top">
                    <h1>
                        <Link to={{ pathname: "/tools" }} className="back" />
                        <span className="title">Espace mort</span>
                        <span onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="more" className="info">Informations</span>
                    </h1>
                </div>

                <div className="inner-content">
                    <nav id="menu">
                        <ul>
                            <li><Link to={{ pathname: "/capnographie" }}>Avec capnographie volumétrique</Link></li>
                            <li><Link to={{ pathname: "/capnometrie" }}>Avec capnométrie standard</Link></li>
                            <li><Link to={{ pathname: "/ventilatory" }}>Ventilatory ratio</Link></li>
                        </ul>
                    </nav>
                </div>

            </div>
            <div id="info-bloc" className={(this.state.infos) ? "full-height active" : "full-height"} >
                <div id="info-bloc-inner">
                    <div onClick={() => this.setState({ infos: (this.state.infos) ? false : true })} id="info-bloc-close"></div>
                    <h2>Définitions</h2>
                    <Accordion panels={panels} />
                </div>
            </div>

            <Navigation state={this.state} />
        </div>
        );
    }
}

export default withRouter(EspaceMort);
